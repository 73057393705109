﻿module Bfs.Web.Kundenportal.WebParts.User.Shared.Tile

open Fable.React
open Fable.React.Props

let navigationArrow =
    img [
        Class "navigation-arrow"
        Src "arrow_32x32.svg"
    ]

let bfsTile
    (title: ReactElement)
    (titleAction: ReactElement option)
    (icon: ReactElement option)
    (body: ReactElement)
    (footer: ReactElement option)
    =

    div [ Class "flat-card bfs-tile-card" ] [
        div [ Class "card-header" ] [
            div [ Class "bfs-tile-header-title" ] [ title ]
            div [ Class "bfs-tile-header-option" ] [
                titleAction |> Option.defaultValue (span [] [])
            ]
        ]
        div [ Class "card-body" ] [
            if icon.IsSome then
                div [ Class "bfs-tile-icon-container" ] [ icon.Value ]
            div [ Class "bfs-tile-content" ] [ body ]
        ]
        if footer.IsSome then
            div [ Class "card-footer" ] [
                div [ Class "footer-right" ] [ footer.Value ]
            ]
    ]

let headlessTile (body: ReactElement) =

    div [ Class "flat-card bfs-tile-card" ] [
        div [ Class "card-body" ] [
            div [ Class "bfs-tile-content" ] [ body ]
        ]
    ]
