namespace AdminFile

open Elmish
open Types
open Http
open Bfs.Web.Data.Service.Contracts
open Bfs.Web.Data.Service.Contracts.Kundenportal.File


module Http =
    let getFileList (page, kundennummer, kategorie) =
        let url =
            match kundennummer, kategorie with
            | None, None -> (sprintf "/api/admin/file?page=%i" page)
            | Some kundennummer, None -> (sprintf "/api/admin/file?page=%i&kdnr=%i" page kundennummer)
            | None, Some kategorie -> (sprintf "/api/admin/file?page=%i&kategorie=%s" page kategorie)
            | Some kundennummer, Some kategorie ->
                (sprintf "/api/admin/file?page=%i&kdnr=%i&kategorie=%s" page kundennummer kategorie)

        fetchAs<Paginated<AdminFileListItem>> url

    let getAllKundennummern () = fetchAs<int list> "/api/admin/kundennummern"
    let getAllKategorien () = fetchAs<string list> "/api/admin/file/kategorien"

    let postActivate id = postEmpty (sprintf "/api/admin/file/%s/active" id)
    let postDeactivate id = postEmpty (sprintf "/api/admin/file/%s/inactive" id)

    let downloadUrl (f: AdminFileListItem) = (sprintf "/api/admin/file/%O" f.Id)
    let downloadMetadataUrl (f: AdminFileListItem) = (sprintf "/api/admin/file/%O/metadata" f.Id)


module State =
    let init page user (lastModel: AdminFile.Types.Model option) =
        match page with
        | List(pagenumber, kdnr, kategorie) ->
            let alleKundennummer =
                lastModel
                |> Option.map (fun m -> m.AllKundennummern)
                |> Option.defaultValue []
            let alleKategorien =
                lastModel
                |> Option.map (fun m -> m.AllKategorien)
                |> Option.defaultValue []

            let initialModel =
                Model.Init pagenumber kdnr kategorie alleKundennummer alleKategorien

            let cmd1 = Cmd.ofMsg (LoadList(pagenumber, kdnr, kategorie))
            let cmd2 =
                if (alleKundennummer = []) then Cmd.ofMsg LoadAllKundennummern else Cmd.none
            let cmd3 = if (alleKategorien = []) then Cmd.ofMsg LoadAllKategorien else Cmd.none

            (initialModel, Cmd.batch [ cmd1; cmd2; cmd3 ])

    let update msg model =
        match msg with
        | LoadList(pagenumber, kdnr, kategorie) ->
            let cmd =
                ListLoaded
                |> request Http.getFileList (pagenumber, kdnr, kategorie)
            ({
                model with
                    Files = Loading
                    Kundennummer = kdnr
                    KundennummernFilterText =
                        kdnr
                        |> Option.map (fun kdnr -> kdnr.ToString())
                        |> Option.defaultValue ""
                    KundennummernFilterEnabeld = true
                    Kategorie = kategorie
             },
             cmd)
        | ListLoaded x -> ({ model with Files = Loaded x }, Cmd.none)

        | ActivateFile(id, filetype) -> (model, ActiveChanged |> request Http.postActivate id)
        | DeactivateFile(id, filetype) -> (model, ActiveChanged |> request Http.postDeactivate id)

        | ActiveChanged _ -> (model, Cmd.ofMsg (LoadList(model.PageNumber, model.Kundennummer, model.Kategorie)))

        | LoadAllKundennummern ->
            let cmd =
                AllKundennummernLoaded
                |> request Http.getAllKundennummern ()
            (model, cmd)
        | AllKundennummernLoaded(Ok lst) -> ({ model with AllKundennummern = lst }, Cmd.none)
        | AllKundennummernLoaded(Error exn) -> (model, exn |> errorCmd GlobalMsg "Laden aller Kundennummern")

        | LoadAllKategorien ->
            let cmd = AllKategorienLoaded |> request Http.getAllKategorien ()
            (model, cmd)
        | AllKategorienLoaded(Ok lst) -> ({ model with AllKategorien = lst }, Cmd.none)
        | AllKategorienLoaded(Error exn) -> (model, exn |> errorCmd GlobalMsg "Laden aller Kategorien")

        | FilterForKategorie kategorie ->
            let cmd = Cmd.ofMsg (LoadList(1, model.Kundennummer, kategorie))
            (model, cmd)
        | FilterForKundennummer ->
            let kdnr = tryInt model.KundennummernFilterText
            let ok =
                match kdnr with
                | Some kdnr -> model.AllKundennummern |> Seq.contains kdnr
                | None -> false
            let cmd =
                match ok with
                | true -> Cmd.ofMsg (LoadList(1, kdnr, model.Kategorie))
                | false -> Cmd.none
            (model, cmd)
        | FilterForKundennummerClear ->
            let cmd = Cmd.ofMsg (LoadList(1, None, model.Kategorie))
            (model, cmd)

        | KundennummernFilterTextChanged value ->
            let kdnr = tryInt value
            let ok =
                match kdnr with
                | Some kdnr -> model.AllKundennummern |> Seq.contains kdnr
                | None -> false
            ({
                model with
                    KundennummernFilterText = value
                    KundennummernFilterEnabeld = ok
             },
             Cmd.none)

        | GlobalMsg(_) -> (model, Cmd.none) // diese Message wird gar nicht hier behandelt, sondern im Dispatcher!
