module StaticPages.View

open Fable.React
open Fulma

open Types
open Bfs.Web.Data.Service.Contracts
open Fable.React.Props

open ViewParts

let box body =
    Columns.columns [
        Columns.CustomClass "is-product-list"
        Columns.IsMultiline
    ] [
        Column.column [
            Column.Width(Screen.All, Column.Is12)
        ] [
            div [
                Class "flat-card profile-info-card is-auto"
            ] [
                div [ Class "card-body" ] [
                    div [ Class "content" ] [ body |> ofList ]
                ]
            ]
        ]
    ]

let viewDatenschutz =
    box [
        h3 [] [ str "Verantwortlicher" ]
        p [] [
            str "Verantwortlich für die nachfolgend dargestellte Datenerhebung und Verarbeitung ist die"
        ]
        p [] [
            str "BFS Abrechnungs GmbH"
            br []
            str "Lavesstraße 8 - 12"
            br []
            str "31137 Hildesheim"
        ]
        p [] [
            str "Telefon: "
            a [ Href "tel: 0512193 56 23 0" ] [ str "05121.93 56 23 - 0" ]
            br []
            str "Telefax: 05121.93 56 23 - 99"
        ]

        h3 [] [ str "Nutzungsdaten" ]
        p [] [
            str
                "Um die Qualität unserer Webseite zu verbessern, speichern wir zu statistischen Zwecken Daten über den einzelnen Zugriff auf unsere Seite. Dieser Datensatz besteht aus:"
        ]
        ul [] [
            li [] [
                str "der Seite, von der aus die Datei angefordert wurde,"
            ]
            li [] [ str "dem Namen der Datei, " ]
            li [] [
                str "dem Datum und der Uhrzeit der Abfrage,"
            ]
            li [] [ str "der übertragenen Datenmenge,  " ]
            li [] [
                str "der Beschreibung des Typs des verwendeten Webbrowsers,   "
            ]
            li [] [
                str "der IP-Adresse des anfragenden Rechners verkürzt um die letzten drei Stellen.  "
            ]
        ]
        p [] [
            str
                "Diese Daten werden anonymisiert gespeichert. Die Erstellung von personenbezogenen Nutzerprofilen ist damit ausgeschlossen. "
        ]

        h3 [] [ str "Cookies" ]
        p [] [
            str
                "Auf unseren Webseiten nutzen wir Cookies. Cookies sind kleine Textdateien, die auf Ihrem Endgerät gespeichert und ausgelesen werden können. Man unterscheidet zwischen Session-Cookies, die wieder gelöscht werden, sobald Sie ihren Browser schließen und permanenten Cookies, die über die einzelne  Sitzung hinaus gespeichert werden. Cookies können Daten enthalten, die eine Wiedererkennung des genutzten Gerätes möglich machen. Teilweise enthalten Cookies aber auch lediglich Informationen zu bestimmten Einstellungen, die nicht personenbezogen sind."
        ]
        p [] [
            str
                "Die Verarbeitung erfolgt auf Grundlage von Art. 6 Abs. 1 S.1 lit. f DSGVO und in dem Interesse die Benutzerführung zu optimieren bzw. zu ermöglichen und die Darstellung unserer Webseite anzupassen."
        ]
        p [] [
            str
                "Sie können Ihren Browser so einstellen, dass er Sie über die Platzierung von Cookies informiert. So wird der Gebrauch von Cookies für Sie transparent. Sie können Cookies zudem jederzeit über die entsprechende Browsereinstellung löschen und das Setzen neuer Cookies verhindern. Bitte beachten Sie, dass unsere Webseiten dann ggf. nicht optimal angezeigt werden und einige Funktionen technisch nicht mehr zur Verfügung stehen."
        ]

        h3 [] [
            str "Kontaktformular und persönliche Angaben"
        ]
        p [] [
            str
                "Für die Registrierung bei unserem Online-Kundenportal müssen Sie keine personenbezogenen Daten angeben, sondern lediglich einen Benutzernamen sowie ein individuelles Passwort anlegen. Jede Person, der dieser Benutzername und das zugehörige Passwort vorliegen, hat Zugriff auf die im Kundenportal hinterlegten persönlichen Daten und kann diese verbindlich ändern. Sobald Sie den Verdacht haben, dass Ihre Zugangsdaten einem unbefugten Dritten zugänglich geworden sind, sollten Sie daher unverzüglich die Sperrung des Zugangs veranlassen. Für die Nutzung unseres Rückrufservices benötigen wir Ihren Namen und Ihre Telefonnummer. Rechtsgrundlage der Verarbeitung ist Art. 6 Abs. 1 lit. f DSGVO. Ihre Daten werden nur zur Beantwortung Ihrer Rückrufanfrage verarbeitet und nicht an Dritte weitergegeben."
        ]
        p [] [
            str "Die Datenübermittlung erfolgt über eine gesicherte https-Verbindung."
        ]

        h3 [] [ str "Chatfunktion" ]
        p [] [
            str
                "Unser Kundenportal nutzt Userlike, eine Live-Chat Software des Unternehmens Userlike UG (haftungsbeschränkt), Probsteigasse 44-46, 50670 Köln, Deutschland. Userlike verwendet Cookies. Dies sind Textdateien, die auf Ihrem Computer gespeichert werden und die eine persönliche Unterhaltung in Form eines Echtzeit-Chats auf der Website mit Ihnen ermöglichen. Um Ihre Anfragen bestmöglich beantworten zu können und Ihnen einen individuellen Service zu ermöglichen, verknüpfen wir Namen und Kundennummer des eingeloggten Nutzers auch mit dem Userlike-Account. Diese Informationen werden auch im Userlike-Cookie hinterlegt. Diese Datenverarbeitung erfolgt auf Grundlage von Art. 6 Abs. 1 lit, f DSGVO sowie dem Interesse, bestmöglichen Kundenservice zu bieten. Sie können den Live-Chat wie ein Kontaktformular nutzen, um in nahezu Echtzeit mit unseren Mitarbeitern zu chatten. Je nach Gesprächsverlauf, bestimmen mithin Sie selbst, welche Daten Sie übermitteln. Diese Daten werden für die Dauer der Vertragsdurchführung gespeichert, soweit der Chatvorgang dafür relevant ist. Anschließend werden die Daten gelöscht. Die von Ihnen freiwillig im Rahmen des Chats angegebenen Daten basieren auf Ihrer Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO. Mit der Userlike UG wurde schließlich ein Vertrag zur Auftragsverarbeitung abgeschlossen. Weitere Informationen entnehmen Sie bitte der Datenschutzerklärung der Userlike UG unter "
            a [
                Href "https://www.userlike.com/de/terms#privacy-policy"
                Class "word-break"
            ] [
                str "https://www.userlike.com/de/terms#privacy-policy"
            ]
            str "."
        ]

        h3 [] [ str "Ihre Rechte als Nutzer" ]
        p [] [
            str
                "Bei der Verarbeitung Ihrer personenbezogenen Daten gewährt die DSGVO Ihnen als Webseitennutzer bestimmte Rechte:"
        ]
        p [] [
            b [] [
                str "Auskunftsrecht (Art. 15 DSGVO): "
            ]
            str
                "Sie haben das Recht eine Bestätigung darüber zu verlangen, ob Ihre personenbezogene Daten verarbeitet werden; ist dies der Fall, so haben
                        Sie ein Recht auf Auskunft über diese personenbezogenen Daten und auf die in Art. 15 DSGVO im Einzelnen aufgeführten Informationen."
        ]
        p [] [
            b [] [
                str "Recht auf Berichtigung und Löschung (Art. 16 und 17 DSGVO):  "
            ]
            str
                "   Sie haben das Recht, unverzüglich die Berichtigung unrichtiger personenbezogener Daten und ggf. die Vervollständigung unvollständiger personenbezogener Daten zu verlangen,
                         sofern sie Ihre Person betreffen. Sie haben zudem das Recht, zu verlangen, dass Ihre personbezogenen Daten unverzüglich gelöscht werden, sofern einer der in Art. 17 DSGVO im
                         Einzelnen aufgeführten Gründe zutrifft, z. B. wenn die Daten für die verfolgten Zwecke nicht mehr benötigt werden.  "
        ]
        p [] [
            b [] [
                str "Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO): "
            ]
            str
                "   Sie haben das Recht, die Einschränkung der Verarbeitung zu verlangen, wenn eine der in Art. 18 DSGVO aufgeführten Voraussetzungen gegeben ist, z. B. wenn Sie Widerspruch
                        gegen die Verarbeitung eingelegt haben, für die Dauer einer etwaigen Prüfung.   "
        ]
        p [] [
            b [] [
                str "Recht auf Datenübertragbarkeit (Art. 20 DSGVO):  "
            ]
            str
                "    In bestimmten Fällen, die in Art. 20 DSGVO im Einzelnen aufgeführt werden, haben Sie das Recht, die Sie betreffenden personenbezogenen Daten in einem strukturierten,
                        gängigen und maschinenlesbaren Format zu erhalten bzw. die Übermittlung dieser Daten an einen Dritten zu verlangen.    "
        ]
        p [] [
            b [] [
                str "Widerspruchsrecht (Art. 21 DSGVO):  "
            ]
            str
                "    Werden Daten auf Grundlage von Art. 6 Abs. 1 lit. f erhoben (Datenverarbeitung zur Wahrung berechtigter Interessen), steht Ihnen das Recht zu, aus Gründen, die sich aus
                        Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Widerspruch einzulegen. Wir verarbeiten die personenbezogenen Daten dann nicht mehr, es sei denn, es
                        liegen nachweisbar zwingende schutzwürdige Gründe für die Verarbeitung vor, die die Interessen, Rechte und Freiheiten der betroffenen Person überwiegen, oder die Verarbeitung
                        dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.     "
        ]
        p [] [
            b [] [
                str "Beschwerderecht bei einer Aufsichtsbehörde:  "
            ]
            str
                "   Sie haben gem. Art. 77 DSGVO das Recht auf Beschwerde bei einer Aufsichtsbehörde, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden Daten gegen
                        datenschutzrechtliche Bestimmungen verstößt. Das Beschwerderecht kann insbesondere bei einer Aufsichtsbehörde in dem Mitgliedstaat Ihres Aufenthaltsorts, Ihres Arbeitsplatzes
                        oder des Ortes des mutmaßlichen Verstoßes geltend gemacht werden.     "
        ]

        h3 [] [ str "Datenschutzbeauftragter" ]
        p [] [
            str
                "Unser betrieblicher Datenschutzbeauftragter steht Ihnen gerne für Auskünfte oder Anregungen zum Thema Datenschutz zur Verfügung: "
        ]
        p [] [
            str "Dr. Uwe Schläger "
            br []
            str "datenschutz nord GmbH "
            br []
            str "Web: "
            a [
                Href "https://www.datenschutz-nord.de"
                Target "blank"
            ] [ str "www.datenschutz-nord.de" ]
            br []
            str "E-Mail: "
            a [
                Href "mailto: office@datenschutz-nord.de"
            ] [ str "office@datenschutz-nord.de" ]
            br []
            str "Telefon: "
            a [ Href "tel: 0421 69 66 32 0" ] [ str "0421 69 66 32 0 " ]
        ]

        h2 [] [
            str "Informationen gemäß Art. 14 Datenschutz-Grundverordnung (DSGVO)"
        ]
        h3 [] [ str "Datenschutzbeauftragter" ]
        p [] [
            str "Dr. Uwe Schläger "
            br []
            str "datenschutz nord GmbH "
            br []
            str "Web: "
            a [
                Href "https://www.datenschutz-nord.de"
                Target "blank"
            ] [ str "www.datenschutz-nord.de" ]
            br []
            str "E-Mail: "
            a [
                Href "mailto: office@datenschutz-nord.de"
            ] [ str "office@datenschutz-nord.de" ]
            br []
            str "Telefon: "
            a [ Href "tel: 0421 69 66 32 0" ] [ str "0421 69 66 32 0 " ]
        ]
        h3 [] [
            str "Datenkategorien und Datenherkunft"
        ]
        p [] [
            str "Die BFS Abrechnungs GmbH verarbeitet nachfolgende Kategorien von Daten: "
        ]
        ul [] [
            li [] [
                str "Stammdaten der Leistungserbringer (Name, Vorname, Telefon, E-Mail)"
            ]
            li [] [
                str
                    "Personenbezogene Gesundheitsdaten der Leistungsempfänger (Name, Vorname, Anschrift, Geburtsdatum,
                                Versichertennummer, ggf. Pflegegrad) "
            ]
            li [] [
                str "Abrechnungsdaten (Leistungsgrundlagen, erbrachte Leistungen) "
            ]
        ]
        p [] [
            str
                "Die Übermittlung der oben genannten Daten erfolgt in elektronischer sowie schriftlicher Form durch den Leistungserbringer an die BFS Abrechnungs GmbH. "
        ]
        h3 [] [ str "Dauer der Speicherung" ]
        p [] [
            str
                "Nach Zahlung der ausstehenden Forderung werden die Daten für die Dauer der gesetzlichen Aufbewahrungsfristen in gesperrter Form gespeichert und anschließend gelöscht. "
        ]
        h3 [] [ str "Rechte der betroffenen Person" ]
        p [] [
            str
                "Ihnen stehen bei Vorliegen der gesetzlichen Voraussetzungen folgende Rechte nach Art. 15 bis 22 DSGVO zu: Recht auf Auskunft, Berichtigung, Löschung, Einschränkung der Verarbeitung und Datenübertragbarkeit. "
        ]
        h3 [] [
            str "Beschwerderecht bei der Aufsichtsbehörde"
        ]
        p [] [
            str
                "Sie haben gemäß Art. 77 DSGVO das Recht, sich bei der Aufsichtsbehörde zu beschweren, wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer personenbezogenen Daten nicht rechtmäßig erfolgt. Die Anschrift der für unser Unternehmen zuständigen Aufsichtsbehörde lautet: "
        ]
        p [] [
            str "Die Landesbeauftragte für den Datenschutz Niedersachsen "
            br []
            str "Barbara Thiel "
            br []
            str "Prinzenstraße 5 "
            br []
            str "30159 Hannover "
        ]
    ]

let viewImpressum =
    box [
        div [ Class "columns" ] [
            div [ Class "column" ] [
                h3 [] [ str "Herausgeber" ]
                p [] [
                    str "BFS Abrechnungs GmbH "
                    br []
                    str "Lavesstraße 8 - 12"
                    br []
                    str "31137 Hildesheim"
                ]
                str "Telefon: "
                a [ Href "tel: 05121.93 56 23  0" ] [ str "05121.93 56 23 - 0 " ]
                br []
                str "Telefax: 05121.93 56 23 - 99 "
                br []
                a [
                    Href "mailto: info@bfs-abrechnung.de"
                ] [ str "info@bfs-abrechnung.de" ]
            ]

            div [ Class "column" ] [
                h3 [] [ str "Geschäftsführung" ]
                p [] [
                    str "Dr. Carsten Steinhoff"
                    br []
                    str "Andreas Dehlzeit"
                ]
                p [] [
                    str "Handelsregister: "
                    br []
                    str "Hildesheim HRB 201885"
                ]
                p [] [
                    str "USt-IdNr.:  "
                    br []
                    str "DE263272552"
                ]
            ]
        ]

        h3 [] [
            str "Inhaltlich Verantwortlicher gemäß § 18 Abs. 2 MStV"
        ]
        p [] [
            str "Dr. Carsten Steinhoff"
            br []
            str "Lavesstraße 8 – 12"
            br []
            str "31137 Hildesheim"
        ]

        h3 [] [ str "Finanzaufsicht" ]
        p [] [
            str "Bundesanstalt für Finanzdienstleistungsaufsicht "
            br []
            str "Graurheindorfer Straße 108"
            br []
            str "53117 Bonn"
            br []
            a [
                Href "https://www.bafin.de"
                Target "blank"
            ] [ str "www.bafin.de" ]
        ]
    ]

let view (model: Model) dispatch navigateTo =
    match model.Page with
    | Datenschutz -> viewDatenschutz
    | Impressum -> viewImpressum
