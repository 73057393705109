﻿module Bfs.Web.Kundenportal.WebParts.Notifications.View

open System
open Bfs.Web.Kundenportal.PWA.NotificationBindings
open Bfs.Web.Kundenportal.PWA.PwaBindings
open Bfs.Web.Kundenportal.WebParts.Notifications.Types
open Fable.React

open Types
open Fable.React.Props

open ViewParts

let view model dispatch navigateTo =
    div [
        Class "columns is-account-grid is-multiline"
    ] [
        div [ Class "column is-12" ] [
            div [ Class "flat-card is-auto" ] [
                div [ Class "" ] [
                    yield
                        match model.UserInfo with
                        | Empty -> div [] []
                        | Loading -> spinner
                        | LoadError s -> errorMsg "Fehler beim Laden!"
                        | Body b ->
                            section [ Class "hero" ] [
                                div [ Class "hero-body" ] [
                                    yield h1 [ Class "title" ] [ str model.Name ]
                                    if (b.Kundennummer.IsSome) then
                                        yield
                                            p [ Class "subtitle" ] [
                                                str ("Kundennummer: " + b.Kundennummer.Value.ToString())
                                            ]
                                ]
                            ]
                ]
            ]
        ]

        div [ Class "column is-12" ] [

            if pwaBindings.isRunningAsPwa () then
                div [
                    Class "flat-card is-auto  profile-info-card"
                ] [
                    div [ Class "card-title" ] [
                        h3 [] [
                            b [] [ str "Push-Benachrichtigungen" ]
                        ]
                    ]

                    div [ Class "card-body paragraphs" ] [
                        (match model.PushState with
                         | Body state ->
                             (match state with
                              | AppDenied ->
                                  [
                                      p [] [
                                          str
                                              "Leider haben Sie Push-Benachrichtungen deaktiviert. Bitte erlauben Sie zunächst der BFS Kundenportal App Ihnen Benachrichtigungen anzuzeigen."
                                      ]
                                      p [] [
                                          str
                                              "Wechseln Sie dazu in die Einstellungen Ihres Gerätes und wählen sie den Menüpunk \"Apps\". Öffnen Sie dort die Einstellungen für die BFS Kundenportal App und aktivieren Sie die Benachrichtigungen."
                                      ]
                                      p [] [
                                          str
                                              "Drücken Sie anschließend den nachfolgenden Button um die Einstellungen zu überprüfen. Starten Sie die App neu, falls die Einstellung nicht übernommen wird."
                                      ]
                                      a [
                                          Class "bfsbutton"
                                          OnClick(fun _ -> LoadPushSubscription |> dispatch)
                                      ] [
                                          icon "fas fa-sync"
                                          span [] [ str "Erneut überprüfen" ]
                                      ]
                                  ]
                                  |> ofList
                              | pushState ->
                                  [
                                      if pushState <> Enabled then
                                          p [] [
                                              str
                                                  "Aktivieren Sie zusätzlich zu den Email-Benachrichtigungen Push-Benachrichtigungen, um immer auf dem aktuellsten Stand zu sein:"
                                          ]
                                      p [] [
                                          regularSwitch
                                              (pushState = Enabled)
                                              model.SendingPushState
                                              (fun newValue -> newValue |> ChangePushSubscription |> dispatch)
                                              (match model.SendingPushState, (pushState = Enabled) with
                                               | true, true -> "Push-Benachrichtigungen werden deaktiviert ..."
                                               | true, false -> "Push-Benachrichtigungen werden aktiviert ..."
                                               | _, true -> "Push-Benachrichtigungen sind aktiviert."
                                               | _ -> "Push-Benachrichtigungen aktivieren")
                                              "enable-push"
                                      ]
                                  ]
                                  |> ofList)
                         | LoadError reason -> p [] [ str reason ]
                         | _ -> spinner)
                    ]
                ]

            div [
                Class "flat-card is-auto  profile-info-card"
            ] [
                div [ Class "card-title" ] [
                    h3 [] [ b [] [ str "Benachrichtigungen" ] ]
                ]

                div [ Class "card-body paragraphs" ] [
                    if String.IsNullOrWhiteSpace model.Email then
                        p [
                            Style [
                                FontSize "1rem"
                                FontWeight "bold"
                                Margin "0.8rem"
                            ]
                        ] [
                            icon "fas fa-exclamation-triangle"
                            str
                                "Sie haben noch keine E-Mail-Adresse hinterlegt. Bitte hinterlegen Sie eine E-Mail-Adresse, um Benachrichtigungen zu erhalten."
                        ]
                    else
                        p [ Style [ Margin "0.8rem" ] ] [
                            str
                                "Ab sofort können Sie sich per E-Mail von uns benachrichtigen lassen. Wählen Sie einfach selbst aus bei welcher Information Sie eine Benachrichtigung erhalten möchten."
                        ]
                    table [
                        Class "table table-striped responsive-table "
                    ] [
                        tr [] [
                            td [] [
                                str "Neue Auswertungen und Dokumente"
                                br []
                                span [
                                    Style [ FontSize "0.8rem"; Color "gray" ]
                                ] [
                                    str
                                        "Sie erhalten eine E-Mail-Benachrichtigung, in der alle neuen Dokumente, Absetzungen und Auswertungen angegeben werden."
                                ]
                            ]
                            td [] [
                                checkbox model.PostImPortal (fun b -> b |> PostImPortalChanged |> dispatch) "1"
                            ]
                        ]
                        if model.PreisInfosAnzeigen then
                            tr [] [
                                td [] [
                                    str "Preisinformationen erhalten"
                                    br []
                                    span [
                                        Style [ FontSize "0.8rem"; Color "gray" ]
                                    ] [
                                        str
                                            "Sobald wir Informationen über neue Preisverhandlungen erhalten, werden Sie direkt per E-Mail über diese informiert."
                                    ]
                                ]
                                td [] [
                                    checkbox model.PreisInfos (fun b -> b |> PreisInfosChanged |> dispatch) "2"
                                ]
                            ]
                        tr [] [
                            td [] [
                                str "Belegeingang"
                                br []
                                span [
                                    Style [ FontSize "0.8rem"; Color "gray" ]
                                ] [
                                    str
                                        "Sie möchten wissen wann Ihre Sendung bei uns eingegangen ist? Sobald der Sendungseingang erfasst wurde, erhalten Sie eine E-Mail-Benachrichtigung. "
                                ]
                            ]
                            td [] [
                                checkbox model.Belegeingang (fun b -> b |> BelegeingangChanged |> dispatch) "3"
                            ]
                        ]
                        tr [] [
                            td [] [
                                str "Belegprüfung abgeschlossen"
                                br []
                                span [
                                    Style [ FontSize "0.8rem"; Color "gray" ]
                                ] [
                                    str
                                        "Sobald unsere Belegprüfung die Prüfung Ihrer Sendung abgeschlossen hat, erhalten Sie eine E-Mail-Benachrichtigung. Die Sendung geht anschließend in die weitere Verarbeitung bis zum Versand an den Kostenträger."
                                ]
                            ]
                            td [] [
                                checkbox
                                    model.BelegpruefungAbgeschlossen
                                    (fun b -> b |> BelegpruefungAbgeschlossenChanged |> dispatch)
                                    "4"
                            ]
                        ]
                        tr [] [
                            td [] [
                                str "Auszahlung getätigt"
                                br []
                                span [
                                    Style [ FontSize "0.8rem"; Color "gray" ]
                                ] [
                                    str
                                        "Sie möchten direkt eine Benachrichtigung erhalten, wenn die Auszahlung getätigt wurde? Wurde Ihre Sendung geprüft, wird nach der vertraglich vereinbarten Frist die angekaufte Forderungssumme an Sie ausgezahlt. Sobald die Auszahlung getätigt wurde, erhalten Sie eine E-Mail-Benachrichtigung."
                                ]
                            ]
                            td [] [
                                checkbox
                                    model.AuszahlungGetaetigt
                                    (fun b -> b |> AuszahlungGetaetigtChanged |> dispatch)
                                    "5"
                            ]
                        ]
                        tr [] [
                            td [] [
                                str "Ticket beantwortet"
                                br []
                                span [
                                    Style [ FontSize "0.8rem"; Color "gray" ]
                                ] [
                                    str
                                        "Sobald unser Kundenservice Ihr Ticket beantwortet hat, werden Sie direkt per E-Mail über die Antwort informiert."
                                ]
                            ]
                            td [] [
                                checkbox model.TicketAntwort (fun b -> b |> TicketAntwortChanged |> dispatch) "6"
                            ]
                        ]
                    ]


                    let isLoading = (if model.Sending then " is-loading" else "")
                    p [ Class "is-pulled-right" ] [
                        button [
                            Type "submit"
                            Class("bfsbutton" + isLoading)
                            Disabled(model.Sending || String.IsNullOrWhiteSpace model.Email)
                            Props.OnClick(fun _ -> SubmitNotifications |> dispatch)
                        ] [ str "Speichern" ]
                    ]
                ]
            ]
        ]
    ]
