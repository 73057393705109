module Help.View

open Bfs.Web.Kundenportal.WebParts.User.Shared
open Fable.React
open Fulma

open Http
open Types
open Fable.React.Props

open ViewParts
open Fable.Core

type HelpBoxSize =
    | Narrow
    | Wide

type IconType =
    | Light
    | Dark
    | MediumDark
    | Orange


let private iconUrl image icontype =
    let postfix =
        match icontype with
        | MediumDark -> "_4"
        | Dark -> "_3"
        | Light -> "_2"
        | Orange -> "_1"
    sprintf "help/%s%s.png" image postfix

let private narrowhtmlBox
    ueberschrift
    image
    icontype
    html
    (additionalLink: string option)
    (onClick: (Browser.Types.MouseEvent -> unit) option)
    =
    div [ Class "column is-4" ] [
        div [
            OnClick(fun e ->
                if onClick.IsSome then
                    onClick.Value(e))
            Class "flat-card help-card upload-card is-auto"
        ] [
            div [ Class "card-body" ] [
                div [ Class "username has-text-centered" ] [
                    span [] [
                        b [] [
                            yield
                                match onClick with
                                | Some _ ->
                                    a [ Class "help-head" ] [ // kein href, weil wir das oben eh haben
                                        str ueberschrift
                                    ]
                                | None -> span [ Class "help-head" ] [ str ueberschrift ]
                        ]
                    ]
                ]
                div [ Class "image-wrapper" ] [ img [ Src(iconUrl image icontype) ] ]
                div [ Class "username has-text-centered" ] [ html ]
            ]
        ]
    ]

let private wideHtmlBox
    ueberschrift
    image
    icontype
    html
    (additionalLink: string option)
    (onClick: (Browser.Types.MouseEvent -> unit) option)
    =
    div [ Class "column is-6" ] [
        div [
            OnClick(fun e ->
                if onClick.IsSome then
                    onClick.Value(e))
            Class "flat-card help-card upload-card is-auto"
        ] [
            div [ Class "card-body card-body-wide " ] [
                div [ Class "image-wrapper" ] [ img [ Src(iconUrl image icontype) ] ]

                div [ Class "help-card-content-wide" ] [
                    div [ Class "username" ] [
                        span [] [
                            b [] [
                                yield
                                    match onClick with
                                    | Some _ ->
                                        a [ Class "help-head" ] [ // kein href, weil wir das oben eh haben
                                            str ueberschrift
                                        ]
                                    | None -> span [ Class "help-head" ] [ str ueberschrift ]
                            ]
                        ]
                    ]
                    div [ Class "username" ] [ html ]

                    if additionalLink.IsSome then
                        div [ Class "view-more" ] [
                            a [] [
                                str additionalLink.Value
                                i [ Class "fas fa-angle-right fa-lg" ] []
                            ]
                        ]
                ]
            ]
        ]
    ]

let htmlBox size =
    match size with
    | Narrow -> narrowhtmlBox
    | Wide -> wideHtmlBox

let textBox size ueberschrift image icontype text onClick =
    htmlBox size ueberschrift image icontype (span [] [ str text ]) onClick


let ticketBox size navigateTo =
    htmlBox
        size
        "BFS Ticket-System"
        "bfs_ticketsystem"
        MediumDark
        (div [] [
            str "Erstellen Sie, für Ihr individuelles Anliegen, ein Ticket an unseren Kundenservice."
        ])
        (Some "zum Ticket-System")
        (Some(fun _ -> (navigateTo (Tickets.Types.Page.Ticket None))))

let wideTicketBox = ticketBox Wide

let wideVideoBox navigateTo =
    htmlBox
        Wide
        "Lebenslauf einer Rechnung"
        "play"
        Orange
        (div [] [
            str "Jetzt den interaktiven Film anschauen und zusätzlich hilfreiche Tipps erhalten."
        ])
        (Some "zum Film")
        (Some(fun _ -> (navigateTo Videos.Types.Page.Videos)))

let private ticketTile navigateTo : ReactElement =
    let action =
        Some(
            a [
                OnClick(fun _ -> navigateTo (Tickets.Types.Page.Ticket None))
            ] [ Tile.navigationArrow ]
        )

    let icon =
        Some(
            img [
                Class "dashboard-icon"
                Src "kontakt/kontakt_ticket_96x96.svg"
            ]
        )

    let body =
        [
            div [ Class "large" ] [ str "Bequem und einfach!" ]
            div [ Class "subtle mt-1" ] [
                str
                    "Senden Sie uns unkompliziert Ihren Sachverhalt zu. Den aktuellen Bearbeitungsstand Ihrer Anfrage können Sie jederzeit einsehen. So behalten Sie immer den Überblick."
            ]
            a [
                Class "bfsbutton is-rounded mt-4 mb-4 action is-size-7"
                OnClick(fun _ -> navigateTo (Tickets.Types.Page.Ticket None))
            ] [ str "Jetzt Ticket erstellen!" ]
        ]
        |> ofList

    div [ Class "ticket-card" ] [
        Tile.bfsTile (str "BFS Ticket-System") action icon body None
    ]

let private chatTile model : ReactElement =
    let icon =
        Some(
            [
                img [
                    Class "dashboard-icon is-rounded"
                    Src(
                        match model.Ansprechpartner with
                        | Body body -> body.ImageUrl
                        | _ -> "kontakt/kontakt_chat_48x48.svg"
                    )
                ]
                div [ Class "dashboard-icon-addon" ] [
                    img [ Src "kontakt/kontakt_chat_48x48.svg" ]
                ]
            ]
            |> ofList
        )

    let body =
        [
            div [ Class "large" ] [
                str "Wir freuen uns auf Ihre Fragen!"
            ]
            div [ Class "subtle mt-1" ] [
                str
                    "Über den Chat erreichen Sie unsere Mitarbeitenden, die Ihnen gerne behilflich sind. Auch außerhalb unserer Geschäftszeiten können Sie uns eine Nachricht senden."
            ]
            a [
                Class "bfsbutton is-rounded mt-4 mb-4 action  is-size-7"
                OnClick(fun _ -> ChatWidget.openChatwidget ())
            ] [ str "Jetzt Chat starten!" ]
        ]
        |> ofList

    div [ Class "chat-card" ] [
        Tile.bfsTile (str "Chat") None icon body None
    ]

let private emailTile: ReactElement =
    let icon =
        Some(
            img [
                Class "dashboard-icon"
                Src "kontakt/kontakt_email_96x96.svg"
            ]
        )

    let body =
        [
            div [ Class "subtle mt-1" ] [
                str
                    "Sie möchten uns Ihr Anliegen per E-Mail zukommen lassen oder haben Dokumente, die Sie als Anhang versenden wollen? Wir bearbeiten Ihr Anliegen unter:"
            ]
            div [ Class "mt-4 action" ] [
                a [
                    Href "mailto:service@bfs-abrechnung.com"
                ] [ str "service@bfs-abrechnung.com" ]
            ]
        ]
        |> ofList

    div [ Class "email-card" ] [
        Tile.bfsTile (str "E-Mail") None icon body None
    ]

let private telefonTile model : ReactElement =
    let icon =
        Some(
            match model.Ansprechpartner with
            | Body body ->
                [
                    img [
                        Class "dashboard-icon is-rounded"
                        Src body.ImageUrl
                    ]
                    div [ Class "dashboard-icon-addon" ] [
                        img [
                            Src "kontakt/Kontakt_telefon_48x48.svg"
                        ]
                    ]
                ]
                |> ofList
            | _ ->
                img [
                    Class "dashboard-icon"
                    Src "kontakt/kontakt_telefon_96x96.svg"
                ]
        )

    let body =
        match model.Ansprechpartner with
        | Empty -> nothing
        | Loading -> spinner
        | LoadError _ -> errorMsg "Fehler beim Laden des Ansprechpartners"
        | Body body ->
            [
                div [ Class "subtle mt-1" ] [ str body.Text ]
                div [ Class "mt-4 action" ] [
                    a [ Href $"tel:{body.Telefon}" ] [ str body.Telefon ]
                ]
            ]
            |> ofList

    div [ Class "telefon-card" ] [
        Tile.bfsTile (str "Telefon") None icon body None
    ]

let view (model: Model) dispatch (navigateTo: AnyPage -> unit) =
    div [ Class "kontakt-grid" ] [
        (ticketTile navigateTo)
        (chatTile model)
        (emailTile)
        (telefonTile model)
    ]
