module LetzterBelegeingang.View

open Bfs.Web.Data.Service.Contracts.Kundenportal.Dashboard.Lists
open Bfs.Web.Kundenportal.WebParts.User.DashBoardPage
open Bfs.Web.Kundenportal.WebParts.User.Shared.Card
open Bfs.Web.Kundenportal.WebParts.User.Shared.DataList
open Fable.React

open Types
open Fable.React.Props

open ViewParts

open Bfs.Web.Shared.Formating

let columnMapping: (ReactElement * (SendungBelegeingang -> ReactElement)) list = [
    (str "SendungsNr", (fun row -> str (row.SendungNr.ToString())))
    (str "Belege", (fun row -> str (row.AnzahlBelege.ToString())))
    (str "Status", (fun row -> str row.Status))
    (str "Zeitpunkt",
     (fun row ->
         str (
             match row.Zeitstempel with
             | Some value -> value |> asString
             | None -> System.String.Empty
         )))
]

let mobileTable (data: SendungBelegeingang list) =
    data
    |> mobileDataList "Keine angekaufte Belege." columnMapping

let desktopTable (data: SendungBelegeingang list) =
    data
    |> desktopDataTable "Keine angekaufte Belege." columnMapping

let view (model: Model) _ (navigateTo: AnyPage -> unit) =

    match model.Loading with
    | true -> spinner
    | false ->
        bfsCard
            ([
                a [
                    OnClick(fun _ -> navigateTo DashboardPage.Dashboard)
                ] [
                    i [ Class "fas fa-angle-left mr-2" ] []
                    str "Zurück zum Dashboard"
                ]
             ]
             |> Some)
            ([
                mobileTable model.Data
                desktopTable model.Data
             ]
             |> Some)
