module Dta.Types

open Bfs.Web.Data.Service.Contracts
open Bfs.Web.Data.Service.Contracts.Kundenportal
open Bfs.Web.Data.Service.Contracts.Kundenportal.Dta
open Bfs.Web.Data.Service.Contracts.Kundenportal.Events
open Types
open System
open Fetch
open Browser

type Page = DtaList of page: int

type Msg =
    | LoadPage of page: int
    | PageLoaded of Result<Paginated<DtaListItem>, exn>
    | UploadFiles of Types.File list // the JS File object
    | FileUploaded of Result<UploadResult, exn>
    | DeleteUploadedFile of UploadResult
    | UploadedFileDeleted of Result<Guid, exn>
    | ConfirmUploadedFiles
    | UploadedFilesConfirmed of Result<Response, exn>
    | GlobalMsg of GlobalMsg
    | LoadEventSettings
    | LoadEventSettingsCompleted of Result<EventSettings, exn>

type Model = {
    PageNumber: int option
    DtaList: Remote<Paginated<DtaListItem>>
    UploadCount: int
    Sending: bool
    UploadList: UploadResult list
    OstergewinnspielActive: bool
} with
    member x.IsSending() = x.Sending || x.UploadCount > 0
    member x.CanConfirm() = not x.Sending && not x.UploadList.IsEmpty
