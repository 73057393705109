module Kundenportal.MainDispatch

open Types
open MainDispatch

open Browser.Dom
open Elmish
open Bfs.Web.Data.Service.Contracts.Kundenportal

let defaultPageForUser userRoles =
    Kundenportal.Menu.startpageByRole
    |> List.tryFind (fun (role, msg) -> userRoles |> List.contains role)
    |> Option.map (fun (role, msg) -> msg)
    |> Option.defaultValue (Profile.Types.Page.PasswordChange :> AnyPage)

let inline deleteTimerTick<'PageModel> model sendmsg =
    if model.PageModel :? 'PageModel then Some(sendmsg |> WebPartMsg) else None

let handleChatWidget (usersession: UserSession) =
    let kundennummer =
        usersession.SessionData
        |> Map.tryFind SessionDataKeys.Kundennummer

    let userId =
        usersession.SessionData
        |> Map.tryFind SessionDataKeys.UserId
        |> Option.defaultValue SessionDataDefaultValue.Default

    let firstName =
        usersession.SessionData
        |> Map.tryFind SessionDataKeys.FirstName
        |> Option.defaultValue SessionDataDefaultValue.Default
    let lastName =
        usersession.SessionData
        |> Map.tryFind SessionDataKeys.LastName
        |> Option.defaultValue SessionDataDefaultValue.Default

    let chatHash =
        usersession.SessionData
        |> Map.tryFind SessionDataKeys.SprinklrChatHash
        |> Option.defaultValue SessionDataDefaultValue.Default

    let sprinklrAppId =
        usersession.SessionData
        |> Map.tryFind SessionDataKeys.SprinklrAppId
        |> Option.defaultValue SessionDataDefaultValue.Default

    let isChatVisible =
        usersession.SessionData
        |> Map.tryFind SessionDataKeys.IsChatVisible
        |> Option.map (fun x -> x = "true")
        |> Option.defaultValue false

    if
        (usersession.Roles |> List.contains Roles.Chat)
        && isChatVisible
    then
        ChatWidget.enableChatWidget userId firstName lastName usersession.Email chatHash kundennummer sprinklrAppId
    else
        ChatWidget.disableChatWidget ()

let init () =
    let initialUrl = window.location.hash

    let initalPage =
        match parseUrl initialUrl with
        // diese Pages werden ohne Logincheck etc aufgerufen
        | Some page when (page :? Password.Types.Page) -> page
        // alle anderen gehen über die Loginmaske (dort wird auch geprüft ob wir eingeloggt sind)
        | _ -> Login.Types.Login(Some initialUrl) :> AnyPage

    MainDispatch.init initalPage

let update (msg: Types.Msg) (model: Types.Model) =
    match msg with
    | GlobalMsg(LoginValidated(usersession, url)) ->
        let model = { model with User = (Some usersession) }

        // wo soll es denn jetzt hingehen?
        let page =
            match url with
            | None -> defaultPageForUser usersession.Roles
            | Some url ->
                let page = parseUrl url
                match page with
                | Some page -> page
                | None -> defaultPageForUser usersession.Roles

        model,
        Cmd.batch [
            page |> NavigateTo |> Cmd.ofMsg
            // wir handeln das hier separat, damit wir sicher stellen können, dass in jedem Fall zur Hauptseite navigiert wird
            (HandleChatWidget usersession) |> Cmd.ofMsg
        ]

    | GlobalMsg(Logoff) ->
        let postCmd =
            Cmd.OfPromise.either Login.Http.postLogoff () (Ok >> LogoffValidated) (Error >> LogoffValidated)
        (model, Cmd.map GlobalMsg postCmd)

    | GlobalMsg(LogoffValidated _) -> // egal ob error oder ok
        let model = { model with User = None }

        ChatWidget.disableChatWidget ()

        let msg = NavigateTo(Login.Types.Page.Login None)
        (model, Cmd.ofMsg msg)

    | NavigateHome ->
        let msg =
            match model.User with
            | None -> NavigateTo(Login.Types.Page.Login None)
            | Some user -> NavigateTo(defaultPageForUser user.Roles)
        (model, Cmd.ofMsg msg)

    | HandleChatWidget userSession ->
        userSession |> handleChatWidget
        model, Cmd.none
    | GlobalMsg(EventMsg eventMsg) ->
        match eventMsg with
        | EasterEggFound(egg, onComplete) ->
            let request = Http.postRecord "api/events/egg-found"
            let param = egg
            let message response =
                (EasterEggFoundSaved(response, onComplete))
                |> GlobalMsg.EventMsg
                |> GlobalMsg
            let cmd = Http.request request param message
            model, cmd
        | EasterEggFoundSaved(Error _, _) -> failwith "Konnte Daten nicht speichern."
        | EasterEggFoundSaved(Ok _, onComplete) ->
            let cmd =
                match onComplete with
                | Some cmd -> cmd |> WebPartMsg |> Cmd.ofMsg
                | None -> Cmd.none
            model, cmd
    | _ -> MainDispatch.update msg model
