module Help.Types

open Bfs.Web.Data.Service.Contracts.Kundenportal.Help
open Types

type Page = | Kontakt

type Msg =
    | LoadAnsprechpartner
    | AnsprechpartnerLoaded of Result<Ansprechpartner, exn>

    | Toggle of i: int

type Model = {
    Ansprechpartner: Remote<Ansprechpartner>

    Touched: int list
    Expanded: int list
} with
    static member Init() = {
        Ansprechpartner = Loading
        Touched = []
        Expanded = []
    }
