module Zahlungen.View

open Bfs.Web.Data.Service.Contracts.Kundenportal.Dashboard.Lists
open Bfs.Web.Kundenportal.WebParts.User.DashBoardPage
open Bfs.Web.Kundenportal.WebParts.User.Shared.Card
open Bfs.Web.Kundenportal.WebParts.User.Shared.DataList
open Bfs.Web.Portale.Collapsable
open Fable.React

open Types
open Fable.React.Props

open ViewParts

open Bfs.Web.Shared.Formating

let columnMapping: (ReactElement * (AnstehendeZahlungDetails -> ReactElement)) list = [
    (str "RechnungsNr", (fun row -> str row.RechnungsNrExtern))
    (str "Klient/Patient", (fun row -> str row.KlientPatient))
    (str "Leistungsgrundlage", (fun row -> str row.LeistungsGrundlage))
    (str "Fälligkeitsdatum", (fun row -> str (row.Faelligkeitsdatum |> asString)))
    (str "Anstehender Betrag", (fun row -> str (row.AnstehenderBetrag |> asMoney)))
    (str "Rechnungsbetrag", (fun row -> str (row.Rechnungsbetrag |> asMoney)))
]

let mobileTable (data: AnstehendeZahlungDetails list) =
    data
    |> List.sortBy (fun row -> row.Faelligkeitsdatum)
    |> mobileDataList "Hier werden Sie zukünftig alle Ihre anstehenden Zahlungen sehen." columnMapping

let desktopTable (data: AnstehendeZahlungDetails list) =
    data
    |> List.sortBy (fun row -> row.Faelligkeitsdatum)
    |> desktopDataTable "Hier werden Sie zukünftig alle Ihre anstehenden Zahlungen sehen." columnMapping

let private SendungList
    (sendungsNr: int)
    (faelligkeitsdatum: System.DateTime)
    (summe: decimal)
    (data: AnstehendeZahlungDetails list)
    =

    let title =
        $"Sendung {sendungsNr}, Fälligkeiten ab: {faelligkeitsdatum |> asString}, Gesammtsumme: {summe |> asMoney}"
    let body = [ mobileTable data; desktopTable data ]

    bfsCardCollapsable (title, body, false)

let view (model: Model) _ (navigateTo: AnyPage -> unit) =

    match model.Loading with
    | true -> spinner
    | false ->
        [
            bfsCard
                ([
                    a [
                        OnClick(fun _ -> navigateTo DashboardPage.Dashboard)
                    ] [
                        i [ Class "fas fa-angle-left mr-2" ] []
                        str "Zurück zum Dashboard"
                    ]
                 ]
                 |> Some)
                ([
                    div [
                        Style [ Margin "0.8rem" ]
                        Class "pb-4"
                    ] [
                        span [] [
                            str
                                "Bitte beachten Sie, dass dies der voraussichtliche Zahlbetrag ist. Verrechnungen, Vorzugsgebühren o.ä. können ggf. noch in Abzug gebracht werden. Unter "
                        ]
                        a [ Href "/#verrechnungen" ] [ str "Verrechnungen" ]
                        span [] [
                            str " können Sie diese hier im Portal prüfen."
                        ]
                    ]
                 ]
                 |> Some)

            yield!
                (match model.Data.IsEmpty with
                 | true -> [
                     bfsCard
                         None
                         ([
                             str "Wir freuen uns auf Ihre erste Sendung!"
                          ]
                          |> Some)
                   ]
                 | false ->
                     model.Data
                     |> List.sortByDescending (fun x -> x.SendungNr)
                     |> List.map (fun data -> SendungList data.SendungNr data.Faelligkeit data.Summe data.Rechnungen))
        ]
        |> ofList
