module.exports = {
    // Safari stellt Notification nur als PWA zur Verfügung. Deshalb behandeln wir das im Browser so, als hätte der Benutzer Notifications deaktiviert.
    notificationPermission: () => ('Notification' in window) ? Notification.permission : "denied",

    requestNotificationPermission: () => Notification.requestPermission(),
    serviceWorkerRegistration: navigator.serviceWorker.ready,
    getSubscription: (registration) => registration.pushManager.getSubscription(),

    urlBase64ToUint8Array: (base64String) => {
        var padding = '='.repeat((4 - base64String.length % 4) % 4);
        var base64 = (base64String + padding)
            .replace(/\-/g, '+')
            .replace(/_/g, '/');

        var rawData = window.atob(base64);
        var outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }

        return outputArray;
    },

    subscribe: function (registration, vapidKey) {
        return registration
            .pushManager
            .subscribe({
                userVisibleOnly: true,
                applicationServerKey: this.urlBase64ToUint8Array(vapidKey)
            })
            .then((sub) => {
                return sub
            })
            .catch((err) => {console.error(err)});
    },

    mapSubscription: function (pushSubscription) {
        return {
            Endpoint: pushSubscription.endpoint,
            P256DH: btoa(String.fromCharCode(...new Uint8Array(pushSubscription.getKey("p256dh")))),
            Auth: btoa(String.fromCharCode(...new Uint8Array(pushSubscription.getKey("auth"))))
        }
    }
}
