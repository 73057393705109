module Shared.Banner

open Bfs.Web.Kundenportal.WebParts.User.Shared
open Fable.React
open Fable.React.Props

open Elmish

open Types

type PreviewMode =
    | NoPreview
    | Desktop
    | Mobile

type BannerProps = {
    GlobalDispatch: GlobalMsg -> unit
    NavigateTo: AnyPage -> unit
    UserSession: UserSession option
    Id: string option
    Tag: string
    Title: string
    Text: string
    HasImageDesktop: bool
    HasImageMobile: bool
    ImageOnly: bool
    BackgroundColor: string
    TextColor: string

    UpdateTrigger: int
    PreviewMode: PreviewMode
}

type Model = {
    SessionKey: string
    Visible: bool
    GlobalDispatch: GlobalMsg -> unit
    NavigateTo: AnyPage -> unit
}

type Msg =
    | CloseDialog
    | OpenNews

module State =

    let sessionKey props =
        $"banner-{props.Id
                  |> Option.defaultValue (System.Guid.NewGuid().ToString())}"

    let init (props: BannerProps) =
        let alreadySeen =
            props.UserSession
            |> Option.map (fun u -> u.SessionData |> Map.containsKey (sessionKey props))
            |> Option.defaultValue false
        {
            SessionKey = (sessionKey props)
            Visible = alreadySeen |> not
            GlobalDispatch = props.GlobalDispatch
            NavigateTo = props.NavigateTo
        },
        Cmd.none

    let update msg model : Model * Cmd<Msg> =
        match msg with
        | CloseDialog ->
            let msg = GlobalMsg.UpdateUserSessionData(model.SessionKey, "")
            model.GlobalDispatch msg
            { model with Visible = false }, Cmd.none
        | OpenNews ->
            model.NavigateTo(News.Types.Page.AllNews 1)
            model, Cmd.Empty

let formatImageUrlDesktop updateTrigger id = $"/api/banner/%s{id}/image/desktop?v{updateTrigger}"

let formatImageUrlMobile updateTrigger id = $"/api/banner/%s{id}/image/mobile?v{updateTrigger}"

let imageUrlDesktop hasImage id updateTrigger =
    match hasImage, id with
    | true, Some id -> formatImageUrlDesktop updateTrigger id
    | _ -> ""

let imageUrlMobile hasImage id updateTrigger =
    match hasImage, id with
    | true, Some id -> formatImageUrlMobile updateTrigger id
    | _ -> ""

let bfsBanner =
    FunctionComponent.Of(fun (props: BannerProps) ->

        let model, dispatch =
            React.useElmish ((State.init props), State.update, [| box props.UpdateTrigger |])

        // hier war mal der schließen button, aber den will die BFS derzet nicht
        // wahrscheinlich werden wir zukünftig eine Option für den Banner haben, die es erlaubt, den Banner zu schließen
        let action = None

        match model.Visible, props.ImageOnly with
        | false, _ -> fragment [] []
        | true, false ->
            let title =
                div [ Class "notification-title" ] [
                    (match props.Tag.Length with
                     | x when x > 0 -> div [ Class "notification-flag" ] [ str props.Tag ]
                     | _ ->
                         div [
                             Class "notification-flag-placeholder"
                         ] [])
                    div [ Class "mobile-spacer" ] []
                    div [ Class "notification-title-text" ] [ str props.Title ]
                ]

            let backgroundClassDesktop =
                if props.HasImageDesktop then "has-background-image-desktop" else ""
            let backgroundClassMobile =
                if props.HasImageMobile then "has-background-image-mobile" else ""
            let previewClass =
                if props.PreviewMode = PreviewMode.Mobile then "banner-preview-mobile" else ""

            div [
                Class $"notification-tile banner-tile {backgroundClassDesktop} {backgroundClassMobile} {previewClass}"
                Style [
                    BackgroundColor props.BackgroundColor
                    Color props.TextColor
                ]
            ] [
                div [
                    Class "banner-background-container"
                    Style [
                        BackgroundImage
                            $"url('{(imageUrlDesktop props.HasImageDesktop props.Id props.UpdateTrigger)}'), url('{(imageUrlMobile props.HasImageMobile props.Id props.UpdateTrigger)}')"
                    ]
                ] []
                Tile.bfsTile
                    title
                    action
                    None
                    (div [
                        Class "content"
                        DangerouslySetInnerHTML { __html = props.Text }
                    ] [])
                    None
            ]
        | true, true ->
            let previewClass =
                if props.PreviewMode = PreviewMode.Mobile then "banner-preview-mobile" else ""

            div [
                Class $"notification-tile banner-tile banner-image-only {previewClass}"
                Style [
                    BackgroundColor props.BackgroundColor
                    Color props.TextColor
                    Cursor "pointer"
                ]
                OnClick(fun _ -> OpenNews |> dispatch)
            ] [
                img [
                    Class "banner-desktop"
                    Src(imageUrlDesktop props.HasImageDesktop props.Id props.UpdateTrigger)
                ]
                img [
                    Class "banner-mobile"
                    Src(imageUrlMobile props.HasImageMobile props.Id props.UpdateTrigger)
                ]

                Tile.bfsTile (span [] []) action None (span [] []) None
            ])
