﻿module Bfs.Web.Kundenportal.WebParts.User.Shared.Dropdown

open Browser.Types
open Fable.Core
open Fable.React
open Fulma

open Fable.React.Props

open ViewParts

type DropdownProps<'T> = {
    Data: 'T list
    SelectedElement: 'T option
    ElementToString: 'T -> string
    ElementToKey: 'T -> string
    OnSelect: 'T -> unit
}


[<Emit("$0.focus()")>]
let forceFocus (input: Element) = jsNative

let bfsDropdown<'T> =
    FunctionComponent.Of(fun (props: DropdownProps<'T>) ->

        let isOpen = Hooks.useState false
        let buttonRef = Hooks.useRef<Element option> None

        let selectedText =
            props.SelectedElement
            |> Option.map props.ElementToString
            |> Option.defaultValue ""
        let icon =
            match isOpen.current with
            | true -> "fa-chevron-up"
            | false -> "fa-chevron-down"

        (Dropdown.dropdown [
            Dropdown.IsActive isOpen.current
            Dropdown.CustomClass "is-fullwidth"
        ] [
            Dropdown.trigger [] [
                Button.button [
                    Button.OnClick(fun _ ->
                        isOpen.update (not isOpen.current)

                        // iOS fokusiert Buttons nicht bei click, darum müssen wir das hier manuell machen
                        // ansonsten funktioniert das onBlur zum Schließen nicht
                        buttonRef.current.Value |> forceFocus)
                    Button.Props [
                        OnBlur(fun _ -> isOpen.update false)
                        RefValue buttonRef
                    ]
                ] [
                    span [] [ str selectedText ]
                    Icon.icon [ Icon.Size IsSmall ] [ linkicon $"fas {icon}" ]
                ]
            ]
            Dropdown.menu [] [
                Dropdown.content
                    []
                    (props.Data
                     |> List.map (fun item ->
                         let label = item |> props.ElementToString
                         let active = label = selectedText
                         Dropdown.Item.a [
                             Dropdown.Item.IsActive active
                             Dropdown.Item.Props [
                                 OnMouseDown(fun _ -> // Das muss onMouseDown sein, damit es vor dem onBlur des Trigger auslöst
                                     item |> props.OnSelect)
                             ]
                         ] [ str label ]))
            ]
        ]))
