module WebPartRegistry

open Bfs.Web.Data.Service.Contracts.Kundenportal.Dashboard
open WebPart

let private AllAdminParts =
    [
        Admin.WebPart.Part.Functions
        AdminFaq.WebPart.Part.Functions
        AdminNews.WebPart.Part.Functions
        AdminFile.WebPart.Part.Functions
        AdminText.WebPart.Part.Functions
        AdminBanner.WebPart.Part.Functions
    ]
    |> Merge

let private AllUserParts =
    [
        News.WebPart.Part.Functions
        Files.WebPart.Part.Functions
        Tickets.WebPart.Part.Functions
        Faq.WebPart.Part.Functions
        Help.WebPart.Part.Functions
        Dta.WebPart.Part.Functions
        Sendungen.WebPart.Part.Functions
        Verrechnungen.WebPart.Part.Functions
        Usermanagement.WebPart.Part.Functions
        Home.WebPart.Part.Functions
        Dashboard.Webpart.Part.Functions
        Zahlungen.WebPart.Part.Functions
        LetzteSendung.WebPart.Part.Functions
        LetzterBelegeingang.WebPart.Part.Functions
        DokumentenDepot.WebPart.Part.Functions
        Quickfinder.WebPart.Part.Functions
        Settings.WebPart.Part.Functions
        Videos.WebPart.Part.Functions
        ApiToken.WebPart.Part.Functions
    ]
    |> Merge

let AllParts =
    [
        StaticPages.WebPart.Part.Functions
        Login.WebPart.Part.Functions
        Profile.WebPart.Part.Functions
        Bfs.Web.Kundenportal.WebParts.Notifications.WebPart.Part.Functions
        Password.WebPart.Part.Functions

        AllUserParts
        AllAdminParts
    ]
    |> Merge
