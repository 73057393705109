module Dashboard.News

open Bfs.Web.Data.Service.Contracts.Kundenportal.News
open Bfs.Web.Kundenportal.WebParts.User.Shared.Tile

open Fable.React
open Fable.React.Props
open Elmish

open Types
open Http
open ViewParts

open Dashboard.Shared

type Model = {
    GlobalDispatch: GlobalMsg -> unit
    NavigateTo: AnyPage -> unit
    Loading: bool
    Data: LatestNewsListItem list
}

type Msg =
    | LoadData
    | LoadDataCompleted of Result<LatestNewsListItem list, exn>
    | NavigateToList
    | NavigateToNews of System.Guid

module Http =
    let loadFiles () = fetchAs<LatestNewsListItem list> "/api/news/latestNews"

module State =
    let init (props: DashboardProps) =
        {
            GlobalDispatch = props.GlobalDispatch
            NavigateTo = props.NavigateTo
            Loading = true
            Data = List.empty
        },
        Cmd.ofMsg LoadData

    let update (msg: Msg) (model: Model) : Model * Cmd<Msg> =
        match msg with
        | LoadData ->
            let cmd = LoadDataCompleted |> request Http.loadFiles ()
            { model with Loading = false }, cmd
        | LoadDataCompleted(Ok data) ->
            {
                model with
                    Loading = false
                    Data = data
            },
            Cmd.none
        | LoadDataCompleted(Error _) ->
            let msg =
                GlobalMessageBox.Error "Die News können zur Zeit leider nicht geladen werden."
                |> ShowMessageBox
            model.GlobalDispatch msg
            { model with Loading = false }, Cmd.none
        | NavigateToList ->
            model.NavigateTo(News.Types.Page.AllNews 1)
            model, Cmd.none
        | NavigateToNews id ->
            model.NavigateTo(News.Types.Page.NewsItem(id.ToString()))
            model, Cmd.none

let View =
    FunctionComponent.Of(fun (props: DashboardProps) ->
        let model, dispatch = React.useElmish ((State.init props), State.update)
        let title = str "News"
        let titleAction =
            Some(
                a [
                    OnClick(fun _ -> NavigateToList |> dispatch)
                ] [ navigationArrow ]
            )
        let body =
            match model.Loading with
            | true -> spinner
            | false ->
                let entries =
                    model.Data
                    |> List.map (fun data ->
                        li [] [
                            p [] [ b [] [ str data.Titel ] ]
                            p [ Class "subtle news-text mt-1" ] [ str data.Textanfang ]
                            a [
                                OnClick(fun _ -> data.Id |> NavigateToNews |> dispatch)
                                Class "is-size-7 news-link"
                            ] [ str "Weiterlesen" ]
                        ])
                div [ Class "news" ] [ ul [] entries ]

        bfsTile title titleAction None body None

    )
