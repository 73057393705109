module Profile.WebPart

open WebPart
open Types

let Part = {
    Init = State.init
    Update = State.update
    View = View.view

    GetGlobalMsg =
        function
        | GlobalMsg m -> Some m
        | _ -> None

    GetHeader = fun _ -> Header "Benutzerprofil"

    BuildUrl =
        function
        | PasswordChange -> [ Urls.profile ]

    ParseUrl =
        function
        | [ Urls.profile ] -> Some Page.PasswordChange
        | _ -> None
}
