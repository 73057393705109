[<RequireQualifiedAccess>]
module Template

open Bfs.Web.Portale.Collapsable
open MenuDef
open Fable.React
open Fable.React.Props
open Fulma
open Types
open ViewParts

let contentWrapper (body: ReactElement list) = div [ Class "content-wrapper" ] [ div [ Class "content-body" ] body ]

module Menu =
    let private menuCaption (tab: menuItemDefinition) =
        match tab.Icon with
        | Some element -> element
        | None -> str tab.Title

    let private simpleMainMenuTab dispatch (tab: menuItemDefinition) =
        a [
            Class "main-menu-item"
            Props.OnClick(fun _ ->
                if tab.Page <> null then
                    dispatch (NavigateTo tab.Page))
        ] [ div [] [ menuCaption tab ] ]
    let private submenuTab (model: Model) roles dispatch (tab: menuItemDefinition) =
        a [
            Class "main-menu-item dropdown is-hoverable"
        ] [
            div [ Class "dropdown-trigger" ] [ menuCaption tab ]
            div [ Class "dropdown-menu" ] [
                div [ Class "dropdown-content" ] [
                    for sub in tab.Subitems do
                        if sub.isVisible roles then
                            let isactive = if (sub.IsActive model) then "is-active" else ""
                            yield
                                a [ //TODO Wirft einen Fehler in der Konsole, weil ein a in ein a geschachtelt ist
                                    Class("dropdown-item submenu-item " + isactive)
                                    Props.OnClick(fun _ ->
                                        if sub.Page <> null then
                                            dispatch (NavigateTo sub.Page))
                                ] [ menuCaption sub ]
                ]
            ]
        ]

    let mainMenuDesktop dispatch model (menu: menuItemDefinition list) user =
        let roles = user.Roles

        Tabs.tabs [
            Tabs.CustomClass "account-tabs has-controls is-hidden-touch main-menu test"
        ] [
            for tab in menu do
                if tab.isVisible roles then
                    yield
                        Tabs.tab [
                            Tabs.Tab.IsActive(tab.IsActive model)
                        ] [
                            yield
                                match tab.Subitems with
                                | [] -> tab |> simpleMainMenuTab dispatch
                                | _ -> tab |> submenuTab model roles dispatch
                        ]
        ]

    let private dropdownMenuEntry dispatch model (item: menuItemDefinition) =
        let active = if (item.IsActive model) then "is-active" else ""

        li [ Class active ] [
            a [
                Class("dropdown-item")
                OnClick(fun _ ->
                    match item.OnClick with
                    | Some onClick -> onClick ()
                    | None -> dispatch (NavigateTo item.Page))
            ] [ str item.Title ]
        ]

    let rec private dropdownMenuGroup =
        FunctionComponent.Of(fun (dispatch, model, user, item: menuItemDefinition) ->

            let isOpen = Hooks.useState false
            let isOpenClass = Hooks.useState ""

            Hooks.useEffect (
                (fun _ ->
                    isOpenClass.update (
                        match isOpen.current with
                        | true -> "expanded"
                        | false -> ""
                    )),
                [| box isOpen.current |]
            )

            li [ Class isOpenClass.current ] [
                label [
                    OnClick(fun _ -> isOpen.update (not isOpen.current))
                ] [
                    icon "fas fa-chevron-right"
                    str item.Title
                ]
                CollapsableElement(dropdownMenuEntries (dispatch, model, user, item.Subitems), isOpen.current)
            ])
    and private dropdownMenuEntries =
        FunctionComponent.Of(fun (dispatch, model, user, items: menuItemDefinition list) ->
            ul [] [
                for item in items do
                    if item.isVisible user.Roles then
                        if (item.Page <> null) then
                            yield dropdownMenuEntry dispatch model item
                        else
                            yield dropdownMenuGroup (dispatch, model, user, item)

            ])

    let subMenuDesktop
        dispatch
        model
        (menu: menuItemDefinition list)
        (userMenu: menuItemDefinition list)
        usericonClass
        user
        =
        div [ Class "subMenu is-hidden-touch" ] [
            for tab in menu do
                if tab.isVisible user.Roles then
                    let active = if tab.IsActive model then "is-active" else ""
                    yield
                        a [
                            Class active
                            OnClick(fun _ -> tab.Page |> NavigateTo |> dispatch)
                        ] [ menuCaption tab ]

            yield
                div [
                    Class "dropdown is-hoverable is-right"
                ] [
                    div [ Class "dropdown-trigger" ] [
                        i [ Class usericonClass ] []
                        p [] [ str user.Name ]
                        i [ Class "fas fa-angle-down" ] []
                    ]
                    div [ Class "dropdown-menu" ] [
                        div [ Class "dropdown-content" ] [
                            // die mobileMenuItems passen hier auch!
                            dropdownMenuEntries (dispatch, model, model.User.Value, userMenu)
                        ]
                    ]
                ]
        ]

    let mobileMenuTrigger dispatch isOpen =
        let menuopen = if isOpen then "is-active" else ""

        a [
            Class(
                "dropdown dropdown-trigger is-right is-hidden-desktop "
                + menuopen
            )
            OnClick(fun _ -> ToggleBurgerMenu |> dispatch)
        ] [ i [ Class "fas fa-bars" ] [] ]

    let mobileMenu dispatch model (menu: menuItemDefinition list) (userMenu: menuItemDefinition list) =
        let username = model.User |> Option.map (_.Name) |> Option.defaultValue ""

        match model.User with
        | Some user ->
            let userMenuCategory = [
                menuItem<string> username "" null [ yield! userMenu ]
            ]
            div [ Class "mobile-menu-wrapper" ] [
                CollapsableElement(
                    div [
                        Class("navbar-menu is-hidden-desktop is-active mobile-menu")
                    ] [
                        div [ Class "navbar-start" ] [
                            dropdownMenuEntries (dispatch, model, model.User.Value, menu)
                            hr [ Class "dropdown-divider" ]
                            dropdownMenuEntries (dispatch, model, model.User.Value, userMenuCategory)
                        ]
                    ],
                    model.IsBurgerMenuOpen
                )
            ]
        | None -> nothing

module Footer =

    let bfsFooterContent dispatch impressumPage datenschutzPage =
        [
            div [] [
                span [] [ str "© " ]
                a [
                    Props.Href "https://www.bfs-abrechnung.de/"
                ] [ str "BFS Abrechnungs GmbH" ]
            ]

            div [] [
                a [
                    Class "space-right"
                    Props.OnClick(fun _ -> impressumPage |> NavigateTo |> dispatch)
                ] [ str "Impressum" ]
                a [
                    Props.OnClick(fun _ -> datenschutzPage |> NavigateTo |> dispatch)
                ] [ str "Datenschutz" ]
            ]
        ]
        |> ofList

    let bfsFooter dispatch impressumPage datenschutzPage =
        footer [] [
            contentWrapper [
                bfsFooterContent dispatch impressumPage datenschutzPage
            ]
        ]


module Header =
    let render
        dispatch
        (model: Model)
        (bigCoverPicture: ReactElement option)
        mainMenu
        subMenu
        mobileMenu
        userMenu
        usericonClass
        =
        let loginpage = if model.User.IsNone then " loginpage " else ""
        [
            header [] [
                contentWrapper [
                    div [ Class("logoblock" + loginpage) ] [
                        a [
                            OnClick(fun _ -> NavigateHome |> dispatch)
                            Class "logo"
                        ] []
                        if model.User.IsSome then
                            (Menu.mobileMenuTrigger dispatch model.IsBurgerMenuOpen)
                    ]

                    if model.User.IsSome then
                        Menu.mainMenuDesktop dispatch model mainMenu model.User.Value
                        Menu.subMenuDesktop dispatch model subMenu userMenu usericonClass model.User.Value
                        Menu.mobileMenu dispatch model mobileMenu userMenu
                ]
            ]
            if bigCoverPicture.IsSome then
                div [ Class "logo-container-big-cover" ] [
                    contentWrapper [ bigCoverPicture.Value ]
                ]

        ]
        |> ofList

module Main =
    let private headerText header =
        div [ Class "category-header" ] [
            match header with
            | NoHeader -> nothing
            | Header header -> h2 [] [ str header ]
            | HeaderWithSubheader(header, sub) ->
                h2 [] [
                    str header
                    p [ Class "subheader" ] [ str sub ]
                ]
        ]

    let render pageHeader body =
        main [] [
            contentWrapper [ headerText pageHeader; body ]
        ]

let buttonOnClick button dispatch =
    Props.OnClick(fun _ ->
        if button.Message.IsSome then
            WebPartMsg button.Message.Value |> dispatch
        if button.ClosesDialog then
            ClearMessageBox |> GlobalMsg |> dispatch)

let getButton buttonType buttonClass (buttonList: GlobalMessageBoxButton list) dispatch =
    let butt = buttonList |> List.tryFind (fun b -> b.Type = buttonType)
    match butt with
    | Some b ->
        button [
            buttonClass
            buttonOnClick b dispatch
        ] [ str b.Caption ]
    | None -> str ""


let messageBox dispatch (data: GlobalMessageBox) =
    let className =
        match data.Type with
        | ErrorBox -> "is-danger"
        | WarningBox -> "is-bfscolored"
        | InfoBox
        | SuccessBox -> "is-bfscolored"

    let buttonClass = Class("button " + className)

    div [ Class "modal is-active" ] [
        div [ Class "modal-background" ] []
        div [ Class "modal-content" ] [
            div [ Class("message " + className) ] [
                div [ Class "message-header" ] [
                    p [] [ str data.Title ]
                    button [
                        Class "delete"
                        OnClick(fun _ -> ClearMessageBox |> GlobalMsg |> dispatch)
                    ] []
                ]
                div [ Class "message-body" ] [
                    yield data.BodyElement

                    yield br []

                    yield
                        div [ Class "button-container" ] [
                            getButton GlobalMessageBoxButtonType.OkButton buttonClass data.Buttons dispatch
                            getButton GlobalMessageBoxButtonType.YesButton buttonClass data.Buttons dispatch
                            getButton GlobalMessageBoxButtonType.NoButton buttonClass data.Buttons dispatch
                            getButton GlobalMessageBoxButtonType.CancelButton buttonClass data.Buttons dispatch
                        ]
                ]
            ]
        ]
    ]

let messageBoxComponent boxType wider title body onClose buttons =
    let className =
        match boxType with
        | ErrorBox -> "is-danger"
        | WarningBox -> "is-bfscolored"
        | InfoBox
        | SuccessBox -> "is-bfscolored"

    div [ Class "modal is-active" ] [
        div [ Class "modal-background" ] []
        div [
            Class("modal-content" + if wider then " modal-wide" else "")
        ] [
            div [ Class("message " + className) ] [
                div [ Class "message-header" ] [
                    p [] [ str title ]
                    button [
                        Class "delete"
                        OnClick(fun _ -> onClose ())
                    ] []
                ]
                div [ Class "message-body" ] [
                    yield body

                    yield br []

                    yield div [ Class "button-container" ] buttons
                ]
            ]
        ]
    ]

let videoOverlay dispatch (video: Video) =
    let url =
        match video with
        | Adventr url -> $"https://player.adventr.io/index.html?link={url}"
        | Vimeo id -> $"https://player.vimeo.com/video/{id}?dnt=1&autoplay=1"

    div [
        Class "modal is-active video-overlay"
    ] [
        div [
            Class "modal-background"
            OnClick(fun _ -> ClearVideo |> GlobalMsg |> dispatch)
        ] []
        div [ Class "modal-content" ] [
            iframe [
                Src url
                FrameBorder "0"
                HTMLAttr.Custom("allow", "autoplay; fullscreen")
                AllowFullScreen true
                AutoPlay true
            ] []
        ]
    ]







let main
    dispatch
    (model: Model)
    (pageHeader: PageHeader)
    (bigCoverPicture: ReactElement option)
    (usericonClass: string)
    (topMenu: menuItemDefinition list)
    (userMenu: menuItemDefinition list)
    (mainMenu: menuItemDefinition list)
    (mobileMenu: menuItemDefinition list)
    impressumPage
    datenschutzPage
    backgroundClass
    body
    =
    div [ Class backgroundClass ] [

        Header.render dispatch model bigCoverPicture mainMenu topMenu mobileMenu userMenu usericonClass
        Main.render pageHeader body
        Footer.bfsFooter dispatch impressumPage datenschutzPage

        if (model.VideoOverlayVimeoId.IsSome) then
            videoOverlay dispatch model.VideoOverlayVimeoId.Value

        if (model.MessageBox.IsSome) then
            messageBox dispatch model.MessageBox.Value
    ]
