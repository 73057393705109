module Http

open Fable.Core
open Fetch
open Elmish
open Thoth.Json
open Fulma.Elmish.DatePicker
open Browser
open Types

exception UnauthorizedException of string

let mutable Encoders = Extra.empty

// Custom error message
let errorString (response: Response) =
    let text =
        sprintf "%i %s for URL %s" response.Status response.StatusText response.Url
    if (response.Status = 401) then raise (UnauthorizedException text) else text

let sendJson (url: string) (str) (init: RequestProperties list) httpMethod =
    let bodyInit = U3.Case3(str)

    let defaultProps = [
        RequestProperties.Method httpMethod
        requestHeaders [ ContentType "application/json" ]
        RequestProperties.Body bodyInit
    ]
    // Append properties after defaultProps to make sure user-defined values
    // override the default ones if necessary
    let allProperties = (List.append defaultProps init)

    GlobalFetch.fetch (RequestInfo.Url url, Fetch.requestProps allProperties)
    |> Promise.map (fun response -> if not response.Ok then errorString response |> failwith else response)

let inline sendRecord<'T> (url: string) (record: 'T) (init: RequestProperties list) httpMethod =
    // Eigentlich habe ich hier auch die extra encoders erwartet...
    // bisher klappt es ohne, aber ich glaube das ist nur zufall aber es geht
    let json = Encode.Auto.toString (0, record)

    sendJson url json init httpMethod

let inline decodeResponse<'T> (response: JS.Promise<Response>) =
    // In this example we use Thoth.Json cached auto decoders
    // More info at: https://mangelmaxime.github.io/Thoth/json/v3.html#caching
    let decoder =
        Decode.Auto.generateDecoderCached<'T> (CaseStrategy.CamelCase, Encoders)

    response
    |> Promise.bind (fun response ->
        if not response.Ok then
            errorString response |> failwith
        else
            response.text ()
            |> Promise.map (fun res ->
                match Decode.fromString decoder res with
                | Ok successValue -> successValue
                | Error error -> failwith error))

let inline decodePlainString (response: JS.Promise<Response>) =
    response
    |> Promise.bind (fun response -> if not response.Ok then errorString response |> failwith else response.text ())


let inline fetchWithDecoder<'T> (url: string) (init: RequestProperties list) =
    GlobalFetch.fetch (RequestInfo.Url url, Fetch.requestProps init)
    |> decodeResponse<'T>

let inline fetchAs2<'T> (url: string) (init: RequestProperties list) =
    let allProperties =
        List.append
            [
                Credentials RequestCredentials.Include
            ]
            init
    fetchWithDecoder<'T> url allProperties

// Inline the function so Fable can resolve the generic parameter at compile time
let inline fetchAs<'T> (url: string) = fetchAs2<'T> url []

let inline fetchAsString (url: string) =
    let allProperties = [
        Credentials RequestCredentials.Include
    ]
    GlobalFetch.fetch (RequestInfo.Url url, Fetch.requestProps allProperties)
    |> decodePlainString

let inline delete<'T> (url: string) =
    fetchAs2<'T> url [
        RequestProperties.Method HttpMethod.DELETE
    ]

let inline postRecord<'T> (url: string) (body: 'T) =
    let init = [
        Credentials RequestCredentials.Include
    ]
    sendRecord<'T> url body init HttpMethod.POST
let inline postRecord2<'T, 'TResult> (url: string) (body: 'T) =
    let init = [
        Credentials RequestCredentials.Include
    ]
    sendRecord<'T> url body init HttpMethod.POST
    |> decodeResponse<'TResult>
let inline postRecord3<'T> (url: string) (body: 'T) =
    let init = [
        Credentials RequestCredentials.Include
    ]
    sendRecord<'T> url body init HttpMethod.POST
    |> decodePlainString

let postEmpty (url: string) =
    let init = [
        Credentials RequestCredentials.Include
    ]
    sendJson url "" init HttpMethod.POST
let inline postEmpty2<'TResult> (url: string) = postEmpty url |> decodeResponse<'TResult>

let inline putRecord<'T> (url: string) (body: 'T) =
    let init = [
        Credentials RequestCredentials.Include
    ]
    sendRecord<'T> url body init HttpMethod.PUT

let inline putRecord2<'T, 'TResult> (url: string) (body: 'T) =
    let init = [
        Credentials RequestCredentials.Include
    ]
    sendRecord<'T> url body init HttpMethod.PUT
    |> decodeResponse<'TResult>
let getText url = promise {
    let init = [
        Credentials RequestCredentials.Include
    ]
    let! response = GlobalFetch.fetch (RequestInfo.Url url, Fetch.requestProps init)
    let! body = response.text ()
    return body
}

// fetch und andere Helper
let request fetch args message = Cmd.OfPromise.either fetch args (Ok >> message) (Error >> message)


let Loaded remote =
    match remote with
    | Ok data -> Types.Remote.Body data
    | Error(e: exn) ->
        Logger.debugfn "Error when loading:\n %A" exn
        if (e.Message.StartsWith "401") then
            raise (UnauthorizedException e.Message)

        LoadError(sprintf "Fehler beim Laden (%s)" e.Message)

let errorCmd globalmsgType text (exn: exn) =
    match exn with
    | UnauthorizedException _ -> Cmd.ofMsg (globalmsgType Logoff)
    | exn ->
        Cmd.ofMsg (
            globalmsgType (ShowMessageBox(GlobalMessageBox.Error(sprintf "Fehler beim %s: %s" text exn.Message)))
        )
let errorCmd2 globalmsgType text error =
    Cmd.ofMsg (globalmsgType (ShowMessageBox(GlobalMessageBox.Error(sprintf "Fehler beim %s: %s" text error))))


// upload files
let inline uploadFile<'T> (url: string) (file: Blob) =
    let formData = FormData.Create()
    formData.append ("file", file)

    let props = [
        RequestProperties.Method HttpMethod.POST
        RequestProperties.Headers(unbox ContentType "multipart/form-data")
        RequestProperties.Body(formData |> unbox)
    ]

    fetchAs2<'T> url props
