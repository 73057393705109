namespace Dta

open System
open Bfs.Web.Data.Service.Contracts.Kundenportal.Events
open Elmish
open Types
open Http
open Bfs.Web.Data.Service.Contracts
open Bfs.Web.Data.Service.Contracts.Kundenportal
open Bfs.Web.Data.Service.Contracts.Kundenportal.Dta


module Http =
    let getDtaDateien (page) = fetchAs<Paginated<DtaListItem>> (sprintf "/api/dta?page=%i" page)
    let postDta (file) = file |> uploadFile<UploadResult> "/api/dta"
    let deleteDta (id: Guid) = delete<Guid> (sprintf "/api/dta/%A" id)
    let confirmDta (id: Guid list) = postRecord "/api/dta/confirm" id


module State =
    let init page user lastModel =
        match page with
        | DtaList pagenumber ->
            let initialModel = {
                PageNumber = Some pagenumber
                DtaList = Loading
                Sending = false
                UploadCount = 0
                UploadList = []
                OstergewinnspielActive = false
            }
            (initialModel,
             [
                 pagenumber |> LoadPage |> Cmd.ofMsg
                 LoadEventSettings |> Cmd.ofMsg
             ]
             |> Cmd.batch)

    let update msg model =
        match msg with
        | LoadPage page ->
            let cmd = PageLoaded |> request Http.getDtaDateien page
            ({ model with DtaList = Loading }, cmd)
        | PageLoaded x -> ({ model with DtaList = Loaded x }, Cmd.none)

        | UploadFiles files ->
            let biggestFile = files |> List.maxBy (fun f -> int f.size)

            if ((biggestFile.size) > 4 * 1024 * 1024) then
                let cmd =
                    Cmd.ofMsg (
                        GlobalMsg(
                            ShowMessageBox(GlobalMessageBox.Error "Die DTA-Dateien dürfen maximal 4 MB groß sein.")
                        )
                    )
                (model, cmd)
            else
                let cmd =
                    files
                    |> List.map (fun f -> FileUploaded |> request Http.postDta f)
                    |> Cmd.batch
                ({
                    model with
                        UploadCount = model.UploadCount + files.Length
                 },
                 cmd)

        | FileUploaded(Ok body) ->
            ({
                model with
                    UploadCount = model.UploadCount - 1
                    UploadList = (List.append model.UploadList [ body ])
             },
             Cmd.none)
        | FileUploaded(Error exn) ->
            ({
                model with
                    UploadCount = model.UploadCount - 1
             },
             exn |> errorCmd GlobalMsg "Upload")

        | DeleteUploadedFile file ->
            let cmd = UploadedFileDeleted |> request Http.deleteDta file.Id
            ({ model with Sending = true }, cmd)
        | UploadedFileDeleted(Ok id) ->
            ({
                model with
                    Sending = false
                    UploadList = (List.filter (fun x -> not (x.Id.ToString().Equals(id.ToString()))) model.UploadList)
             },
             Cmd.none)
        | UploadedFileDeleted(Error exn) -> ({ model with Sending = false }, exn |> errorCmd GlobalMsg "Löschen")

        | ConfirmUploadedFiles ->
            let ids = List.map (fun (x: UploadResult) -> x.Id) model.UploadList
            let cmd = UploadedFilesConfirmed |> request Http.confirmDta ids
            ({ model with Sending = true }, cmd)
        | UploadedFilesConfirmed(Ok _) ->
            ({
                model with
                    Sending = false
                    UploadList = []
             },
             Cmd.ofMsg (LoadPage 1))
        | UploadedFilesConfirmed(Error exn) -> ({ model with Sending = false }, exn |> errorCmd GlobalMsg "Versenden")
        | LoadEventSettings ->
            let request () = Http.fetchAs<EventSettings> "/api/events/settings"
            model, (LoadEventSettingsCompleted |> Http.request request ())
        | LoadEventSettingsCompleted(Error ex) ->
            Logger.error "Die Einstellungen für das Dashboard konnten nicht geladen werden"
            model, Cmd.none
        | LoadEventSettingsCompleted(Ok settings) ->
            {
                model with
                    OstergewinnspielActive = settings.EasterEvent.IsSome
            },
            Cmd.none
        | GlobalMsg(_) -> (model, Cmd.none) // diese Message wird gar nicht hier behandelt, sondern im Dispatcher!
