module Videos.View

open Bfs.Web.Kundenportal.HtmlInteropt
open Fable.React

open Types
open Fable.React.Props
open Bfs.Web.Kundenportal.WebParts.User.Events.Easter

open ViewParts

let videoBox =
    div [
        Class "columns quickfinder-box video-box"
    ] [
        div [ Class "column is-12 help-center" ] [
            div [ Class "flat-card is-auto" ] [
                div [
                    Class "card-body card-body-quickfinder columns"
                ] [
                    div [
                        Class "column is-10-fullhd is-10widescreen is-10-desktop is-8-tablet is-12-mobile firstcol"
                    ] [
                        icon ("fas fa-play-circle")
                        div [] [
                            p [] [
                                b [] [
                                    str "Bilder sagen oft mehr als Worte!"
                                ]
                            ]
                            p [ Class "sub" ] [
                                str (
                                    "Daher finden Sie hier schnell verfilmte Informationen zu den Funktionen im Kundenportal "
                                    + "sowie dem Lebenslauf einer Rechnung bei der BFS Abrechnungs GmbH."
                                )
                            ]
                        ]
                    ]
                ]
            ]
        ]
    ]

let videoList (model: Model) dispatch =
    div [ Class "columns" ] [
        div [ Class "column is-12" ] [
            div [
                Class "flat-card profile-info-card is-auto overflow-initial is-relative"
            ] [
                if model.OstergewinnspielActive then
                    (Egg Color.Orange Variant.Dotted [ Top "35%" ])
                    (Egg Color.Pink Variant.Stripped [ Left "35%" ])
                    (Egg Color.Green Variant.Stripped [ Left "40%" ])

                div [ Class "card-body" ] [
                    div [
                        Style [
                            CSSProp.Width "100%"
                            CSSProp.Custom("aspect-ratio", "16/9")
                        ]
                    ] [
                        a [] [
                            video [
                                Style [ CSSProp.Width "100%" ]
                                Src "video/InteraktivesVideoPreview.mp4"
                                AutoPlay true
                                PlaysInline true
                                Loop true
                                OnClick(fun _ ->
                                    Kundenportal.Types.Video.BfsVideo
                                    |> ShowVideo
                                    |> GlobalMsg
                                    |> dispatch)
                            ] []
                        ]
                    ]
                ]
            ]
        ]
    ]


let view (model: Model) dispatch (navigateTo: AnyPage -> unit) = [ videoBox; videoList model dispatch ] |> ofList
