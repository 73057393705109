module ChatWidget

open Fable.Core
open Bfs.Web.Data.Service.Contracts.Kundenportal

[<Emit("openChat()")>]
let openChatwidget () : unit = jsNative

[<Emit("removeChatWidget()")>]
let private removeSprinklrChat () : unit = jsNative

[<Emit("configureChatWidget($0,$1,$2,$3,$4,$5,$6)")>]
let private configureChatWidget
    (id: string)
    (firstName: string)
    (lastName: string)
    (email: string)
    (hash: string)
    (kundennummer: string)
    (sprinklrAppId: string)
    : unit =
    jsNative

let private enableSprinklrChat
    (id: string)
    (firstName: string)
    (lastName: string)
    (email: string)
    (hash: string)
    (kundennummer: string)
    (sprinklrAppid: string)
    =
    configureChatWidget id firstName lastName email hash kundennummer sprinklrAppid

let enableChatWidget
    (id: string)
    (firstName: string)
    (lastName: string)
    (email: string)
    (hash: string)
    (kdnr: string option)
    (sprinklrAppId: string)
    =
    enableSprinklrChat id firstName lastName email hash (kdnr |> Option.defaultValue System.String.Empty) sprinklrAppId

let disableChatWidget () = removeSprinklrChat ()
