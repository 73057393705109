namespace Home

open Elmish
open Types
open Http
open Bfs.Web.Data.Service.Contracts


module State =
    let init page (user: UserSession option) lastModel =

        let model = { NewsList = Loading }
        (model, Cmd.ofMsg LoadNews)

    let update msg (model: Model) =
        match msg with
        | LoadNews ->
            let loadNewsPageCmd = NewsLoaded |> request News.Http.getNewsPage 1
            ({ model with NewsList = Loading }, loadNewsPageCmd)

        | NewsLoaded(Ok news) ->
            // wir wollen hier max 3 stück sehen
            let items = news.Items |> List.take (min news.Items.Length 3)

            let paginated = {
                Page = 1
                PageSize = items.Length
                PageCount = 1
                Items = items
            }

            ({ model with NewsList = Body paginated }, Cmd.none)

        | NewsLoaded(Error e) ->
            ({
                model with
                    NewsList = LoadError e.Message
             },
             Cmd.none)

        | GlobalMsg(_) -> (model, Cmd.none) // diese Message wird gar nicht hier behandelt, sondern im Dispatcher!
