module LetzteSendung.View

open Bfs.Web.Data.Service.Contracts.Kundenportal.Dashboard.Lists
open Bfs.Web.Kundenportal.WebParts.User.DashBoardPage
open Bfs.Web.Kundenportal.WebParts.User.Shared.Card
open Bfs.Web.Kundenportal.WebParts.User.Shared.DataList
open Fable.React

open Types
open Fable.React.Props

open ViewParts

open Bfs.Web.Shared.Formating

let columnMapping: (ReactElement * (SendungBelegeDetails -> ReactElement)) list = [
    (str "RechnungsNr", (fun row -> str row.RechnungsNrExtern))
    (str "Klient/Patient", (fun row -> str row.KlientPatient))
    (str "Leistungsgrundlage", (fun row -> str row.LeistungsGrundlage))
    (str "Rechnungsbetrag", (fun row -> str (row.Rechnungsbetrag |> asMoney)))
    (str "Meldung", (fun row -> str row.Meldung))
]

let mobileTable (data: SendungBelegeDetails list) =
    data
    |> List.sortBy (fun row -> row.KlientPatient, row.LeistungsGrundlage)
    |> mobileDataList "Hier werden Sie zukünftig Ihre aktuelle Sendung mit den angekauften Belegen sehen." columnMapping

let desktopTable (data: SendungBelegeDetails list) =
    data
    |> List.sortBy (fun row -> row.KlientPatient, row.LeistungsGrundlage)
    |> desktopDataTable
        "Hier werden Sie zukünftig Ihre aktuelle Sendung mit den angekauften Belegen sehen."
        columnMapping

let private SendungList (sendungsNr: int) (data: SendungBelegeDetails list) =

    let title = $"Sendung {sendungsNr}"
    let body = [ mobileTable data; desktopTable data ]

    bfsCardCollapsable (title, body, false)

let view (model: Model) _ (navigateTo: AnyPage -> unit) =

    match model.Loading with
    | true -> spinner
    | false ->
        [
            bfsCard
                ([
                    a [
                        OnClick(fun _ -> navigateTo DashboardPage.Dashboard)
                    ] [
                        i [ Class "fas fa-angle-left mr-2" ] []
                        str "Zurück zum Dashboard"
                    ]
                 ]
                 |> Some)
                None
            yield!
                (match model.Data.IsEmpty with
                 | true -> [
                     bfsCard
                         None
                         ([
                             str "Wir freuen uns auf Ihre erste Sendung!"
                          ]
                          |> Some)
                   ]
                 | false ->
                     model.Data
                     |> List.sortByDescending (fun x -> x.SendungNr)
                     |> List.map (fun data -> SendungList data.SendungNr data.Rechnungen))
        ]
        |> ofList
