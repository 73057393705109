﻿module Bfs.Web.Kundenportal.Pwa

open Bfs.Web.Data.Service.Contracts.Kundenportal
open Bfs.Web.Kundenportal.PWA
open Bfs.Web.Kundenportal.PWA.NotificationBindings
open Bfs.Web.Kundenportal.PWA.PwaBindings
open Browser.Types
open Fable.React
open Fable.React.Props
open Fable.Remoting.Client.Http
open Feliz
open Http
open Types
open ViewParts

open Bfs.Web.Data.Service.Contracts.Kundenportal.Notifications

open PwaBindings
open NotificationBindings

type PwaStatus =
    | Unknown
    | Installable of Event option
    | InstalledRecently
    | Installed

let Banner =
    FunctionComponent.Of(fun (model: Model) ->

        let pwaStatus =
            Hooks.useState (
                match pwaBindings.isRunningAsPwa (), pwaBindings.isOnIos () with
                | true, _ -> PwaStatus.Installed
                | false, false -> PwaStatus.Unknown // Wird über die Effects unten aktiviert
                | false, true -> PwaStatus.Installable None
            )

        let installerHidden = Hooks.useState false
        let showInstallHint, setShowInstallHint = React.useState false
        let installHintClass = if showInstallHint then "active" else ""

        let notificationBannerHidden = Hooks.useState false
        let notificationPermissions =
            Hooks.useState (notificationBindings.notificationPermission ())
        let subscribing = Hooks.useState false

        let subscribingClass = if subscribing.current then "is-loading" else ""

        React.useEffectOnce (fun _ ->
            Browser.Dom.window.addEventListener (
                "beforeinstallprompt",
                (fun ev ->
                    ev.preventDefault ()
                    pwaStatus.update (ev |> Some |> PwaStatus.Installable))
            ))

        React.useEffectOnce (fun _ ->
            Browser.Dom.window.addEventListener (
                "appinstalled",
                (fun _ -> pwaStatus.update PwaStatus.InstalledRecently)
            ))

        [
            (match model.User, installerHidden.current, pwaStatus.current, isTouchDevice with
             | None, _, _, _
             | _, true, _, _
             | _, _, _, false
             | _, _, Unknown, _
             | _, _, Installed, _
             | _, _, InstalledRecently, _ -> fragment [] []
             | Some user, _, _, _ when user.Roles |> List.contains Roles.KundeMitPwa |> not -> fragment [] []
             | _, _, Installable prompt, true ->
                 div [ Class "pwa-banner" ] [
                     div [ Class "pwa-installer" ] [
                         a [
                             Class "cancel-icon"
                             OnClick(fun _ -> installerHidden.update true)
                         ] [
                             icon "fal fa-times fa-2x has-text-white"
                         ]
                         img [ Class "logo"; Src "BfsLogoWhite.svg" ]
                         div [ Class "name" ] [
                             b [] [ str "BFS Kundenportal" ]
                             div [ Class "pwa-subtitle" ] [ str "Jetzt die App installieren!" ]
                         ]
                         div [ Class "install" ] [
                             button [
                                 Class "button is-white is-outlined is-rounded has-text-white"
                                 OnClick(fun _ ->
                                     match prompt with
                                     | Some event -> event |> pwaBindings.promptInstall |> ignore
                                     | None -> true |> setShowInstallHint)
                             ] [ str "Installieren" ]
                         ]
                     ]
                 ])

            if
                model.User.IsSome
                && pwaBindings.isRunningAsPwa ()
                && (notificationBindings.notificationPermission ()) = NotificationPermissionUnset
                && not notificationBannerHidden.current
            then
                div [ Class "pwa-banner" ] [
                    div [
                        Class "pwa-installer pwa-notfications"
                    ] [
                        a [
                            Class "cancel-icon"
                            OnClick(fun _ -> notificationBannerHidden.update true)
                        ] [
                            icon "fal fa-times fa-2x has-text-white"
                        ]
                        div [ Class "name" ] [
                            b [] [ str "Pushnachrichten" ]
                            div [ Class "pwa-subtitle" ] [
                                str "Aktivieren Sie jetzt Pushnachrichten um immer auf dem aktuellen Stand zu sein!"
                            ]
                        ]
                        div [ Class "install" ] [
                            button [
                                Class $"button is-white is-outlined is-rounded has-text-white {subscribingClass}"
                                OnClick(fun _ ->
                                    subscribing.update true
                                    notificationBindings.requestNotificationPermission ()
                                    |> Promise.bind (fun permissions ->
                                        if permissions = NotificationPermissionGranted then
                                            NotificationHelper.SubscribeToWebPush()
                                            |> Promise.map (fun success ->
                                                notificationPermissions.update permissions)
                                        else
                                            promise { return notificationPermissions.update permissions })
                                    |> Promise.iter (fun _ -> subscribing.update false))
                            ] [ str "Aktivieren" ]
                        ]
                    ]
                ]

            div [
                Class $"pwa-install-hint {installHintClass}"
            ] [
                div [
                    Class "pwa-install-hint-backdrop"
                    OnClick(fun _ -> false |> setShowInstallHint)
                ] []
                div [ Class "pwa-install-hint-content" ] [
                    div [ Class "pwa-install-hint-header" ] [
                        img [ Class "logo"; Src "BfsLogo.svg" ]
                        b [] [ str "BFS Kundenportal installieren" ]
                    ]
                    hr []
                    div [ Class "pwa-install-hint-body" ] [
                        p [] [
                            str "Installieren Sie die BFS Kundenportal App in zwei einfachen Schritten:"
                        ]
                        ol [] [
                            li [] [
                                str "Tippen Sie auf das Share-Icon in Ihrem Browser"
                                icon "fal fa-arrow-square-up"
                            ]

                            li [] [
                                str "Wählen Sie \"Zum Startbildschirm hinzufügen\""
                                icon "fal fa-plus-square"
                            ]
                        ]
                    ]
                ]
            ]
        ]
        |> ofList)
