namespace AdminFaq

open System
open Elmish
open Types
open Http
open Bfs.Web.Data.Service.Contracts.Kundenportal.Faq
open Validation


module Http =
    let getKategorienList () = fetchAs<AdminFaqKategorieListItem list> "/api/admin/faq/kategorien"
    let getFaqList (kategorieId) =
        fetchAs<AdminFaqListItem list> (sprintf "/api/admin/faq?kategorieId=%O" kategorieId)
    let getFaq (id) = fetchAs<AdminFaq> (sprintf "/api/admin/faq/%O" id)

    let postFaq (id, faq) = postRecord<AdminFaq> (sprintf "/api/admin/faq/%O" id) (faq)
    let inline putFaq (faq) = promise {
        let! response = putRecord "/api/admin/faq" (faq)
        let! id = response.json<Guid> ()
        return id
    }

    let postActivate (id) = postEmpty (sprintf "/api/admin/faq/%O/active" id)
    let postDeactivate (id) = postEmpty (sprintf "/api/admin/faq/%O/inactive" id)


module State =
    let init page user lastModel =
        let model = {
            Kategorien = []
            SelectedKategorie = None
            Faqs = Loading
            EditModel = None
        }
        (model, LoadKategorien |> Cmd.ofMsg)

    let update msg model =
        match msg with
        | LoadKategorien ->
            let cmd = KategorienLoaded |> request Http.getKategorienList ()
            (model, cmd)
        | KategorienLoaded(Ok kategorien) ->
            let selected = if kategorien.Length >= 1 then Some kategorien.[0].Id else None

            let cmd =
                match selected with
                | Some id -> id |> LoadFaqList |> Cmd.ofMsg
                | _ -> Cmd.none

            ({
                model with
                    Kategorien = kategorien
                    SelectedKategorie = selected
             },
             cmd)
        | KategorienLoaded(Error ex) -> (model, ex |> errorCmd GlobalMsg "Kategorien laden")

        | LoadFaqList kategorieId ->
            let cmd = FaqListLoaded |> request Http.getFaqList (kategorieId)
            ({
                model with
                    SelectedKategorie = Some kategorieId
                    Faqs = Loading
                    EditModel = None
             },
             cmd)
        | FaqListLoaded faqs -> ({ model with Faqs = Loaded faqs }, Cmd.none)


        | ActivateFaq id -> (model, ActiveChanged |> request Http.postActivate id)
        | DeactivateFaq id -> (model, ActiveChanged |> request Http.postDeactivate id)
        | ActiveChanged _ ->
            let cmd =
                match model.SelectedKategorie with
                | Some id -> id |> LoadFaqList |> Cmd.ofMsg
                | _ -> Cmd.none
            (model, cmd)

        | LoadFaq id ->
            let cmd = FaqLoaded |> request Http.getFaq id
            let editModel = EditModel.Init (Some id) Guid.Empty
            ({
                model with
                    EditModel = Some editModel
             },
             cmd)
        | FaqLoaded(Ok faq) ->
            let em =
                model.EditModel
                |> Option.map (fun e -> {
                    e with
                        Frage = update e.Frage faq.Frage
                        Antwort = update e.Antwort faq.Antwort
                        Position = update e.Position (faq.Position.ToString())
                        Aktiv = faq.Aktiv
                        TextAsHtml = Fable.Formatting.Markdown.Markdown.ToHtml faq.Antwort
                        SichtbarFuerPflege = faq.SichtbarFuerPflege
                        SichtbarFuerPflegeDtaPlus = faq.SichtbarFuerPflegeDtaPlus
                        SichtbarFuerTherapie = faq.SichtbarFuerTherapie
                        SichtbarFuerJugendhilfe = faq.SichtbarFuerJugendhilfe
                        KategorieId = faq.KundenportalFaqKategorieId
                        IsDirty = false
                })
            ({ model with EditModel = em }, Cmd.none)
        | FaqLoaded(Error exn) -> (model, exn |> errorCmd GlobalMsg "Laden")

        | NewFaq kategorieId ->
            let em = EditModel.Init None kategorieId
            let em = {
                em with
                    Frage = update em.Frage "Neue Frage"
                    Aktiv = true
                    IsDirty = true
            }
            ({ model with EditModel = Some em }, Cmd.none)

        | FrageChanged value ->
            let em =
                model.EditModel
                |> Option.map (fun e -> {
                    e with
                        Frage = update e.Frage value
                        IsDirty = true
                })
            ({ model with EditModel = em }, Cmd.none)
        | AntwortChanged value ->
            let em =
                model.EditModel
                |> Option.map (fun e -> {
                    e with
                        Antwort = update e.Antwort value
                        TextAsHtml = Fable.Formatting.Markdown.Markdown.ToHtml value
                        IsDirty = true
                })
            ({ model with EditModel = em }, Cmd.none)
        | PositionChanged value ->
            let em =
                model.EditModel
                |> Option.map (fun e -> {
                    e with
                        Position = update e.Position value
                        IsDirty = true
                })
            ({ model with EditModel = em }, Cmd.none)
        | KategorieChanged id ->
            let em =
                model.EditModel
                |> Option.map (fun e -> {
                    e with
                        KategorieId = id
                        IsDirty = true
                })
            ({ model with EditModel = em }, Cmd.none)
        | AktivChanged value ->
            let em =
                model.EditModel
                |> Option.map (fun e -> { e with Aktiv = value; IsDirty = true })
            ({ model with EditModel = em }, Cmd.none)
        | SichtbarFuerPflegeChanged value ->
            let em =
                model.EditModel
                |> Option.map (fun e -> {
                    e with
                        SichtbarFuerPflege = value
                        IsDirty = true
                })
            ({ model with EditModel = em }, Cmd.none)
        | SichtbarFuerPflegeDtaPlusChanged value ->
            let em =
                model.EditModel
                |> Option.map (fun e -> {
                    e with
                        SichtbarFuerPflegeDtaPlus = value
                        IsDirty = true
                })
            ({ model with EditModel = em }, Cmd.none)
        | SichtbarFuerTherapieChanged value ->
            let em =
                model.EditModel
                |> Option.map (fun e -> {
                    e with
                        SichtbarFuerTherapie = value
                        IsDirty = true
                })
            ({ model with EditModel = em }, Cmd.none)
        | SichtbarFuerJugendhilfeChanged value ->
            let em =
                model.EditModel
                |> Option.map (fun e -> {
                    e with
                        SichtbarFuerJugendhilfe = value
                        IsDirty = true
                })
            ({ model with EditModel = em }, Cmd.none)


        | Save when model.EditModel.IsSome ->
            let faq = {
                Frage = model.EditModel.Value.Frage.Value
                Antwort = model.EditModel.Value.Antwort.Value
                Position = int model.EditModel.Value.Position.Value
                Aktiv = model.EditModel.Value.Aktiv
                SichtbarFuerPflege = model.EditModel.Value.SichtbarFuerPflege
                SichtbarFuerPflegeDtaPlus = model.EditModel.Value.SichtbarFuerPflegeDtaPlus
                SichtbarFuerTherapie = model.EditModel.Value.SichtbarFuerTherapie
                SichtbarFuerJugendhilfe = model.EditModel.Value.SichtbarFuerJugendhilfe
                KundenportalFaqKategorieId = model.EditModel.Value.KategorieId
            }

            let cmd =
                match model.EditModel.Value.Id with
                | Some id -> Saved |> request Http.postFaq (id, faq)
                | None -> SavedNew |> request Http.putFaq (faq)

            ({
                model with
                    EditModel =
                        Some {
                            model.EditModel.Value with
                                Sending = true
                        }
             },
             cmd)
        | Saved(Ok _) when model.EditModel.IsSome ->
            ({
                model with
                    EditModel =
                        Some {
                            model.EditModel.Value with
                                Sending = false
                                IsDirty = false
                        }
             },
             Cmd.none)
        | Saved(Error e) when model.EditModel.IsSome ->
            ({
                model with
                    EditModel =
                        Some {
                            model.EditModel.Value with
                                Sending = false
                        }
             },
             e |> errorCmd GlobalMsg "Speichern")
        | SavedNew(Ok id) when model.EditModel.IsSome ->
            ({
                model with
                    EditModel =
                        Some {
                            model.EditModel.Value with
                                Sending = false
                                IsDirty = false
                                Id = Some id
                        }
             },
             Cmd.none)
        | SavedNew(Error e) when model.EditModel.IsSome ->
            ({
                model with
                    EditModel =
                        Some {
                            model.EditModel.Value with
                                Sending = false
                        }
             },
             e |> errorCmd GlobalMsg "Speichern")

        // ohne EditModel, dann ist irgendwas komisch und wir machen besser gar nichts
        | Save
        | Saved _
        | SavedNew _ -> (model, Cmd.none)

        | GlobalMsg _ -> (model, Cmd.none) // diese Message wird gar nicht hier behandelt, sondern im Dispatcher!
