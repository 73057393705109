module Admin.Types

open Types

type Page = | VersionInfo

type Msg =
    | GetServerVersion
    | ServerVersionLoaded of Result<string, exn>

type Model = {
    ClientVersion: string
    ServerVersion: Remote<string>
} with
    static member Init = {
        ClientVersion = buildVersion
        ServerVersion = Loading
    }
