[<AutoOpen>]
module Helpers

open Fable.Core
open Fable.React
open Fable.React.Props

open Bfs.Web.Shared.Formating
open Fulma.Color
open Feliz.Recharts

let UrlHashPrefix (s: string) = sprintf "#%s" (s.TrimStart('/'))
let UrlCombine xs = List.fold (sprintf "%s/%s") "" xs

[<Emit("isTouch")>] // die Variable wird in index.html gesetzt
let isTouchDevice: bool = jsNative

[<Emit("window.matchMedia(\"(min-width: 450px)\").matches")>]
let isLargeScreen () : bool = jsNative

[<Emit("__BUILD_VERSION__")>]
let buildVersion: string = jsNative

[<Emit("supportsES2015")>]
let supportsES2015: bool = jsNative

[<Emit("navigator.clipboard.writeText($0)")>]
let copyToClipboard (s: string) = jsNative

[<Emit("URL.createObjectURL($0)")>]
let createObjectUrl (_: Browser.Types.Blob) = jsNative

let tryInt (str: string) =
    let (success, num) = System.Int32.TryParse(str)

    match success with
    | true -> Some num
    | false -> None

let toggle item lst =
    if (List.contains item lst) then
        List.except [ item ] lst
    else
        List.append [ item ] lst

let asMoneySpan (v: decimal) =
    span [
        DangerouslySetInnerHTML { __html = (v |> asHtmlMoney) }
    ] []

module CustomColors =
    let bfsOrange = IsCustomColor "bfsorange"

// Feliz Recharts implementiert diese nicht
module RechartExtensions =
    module bar =
        let radius (radius: int array) = Interop.mkBarAttr "radius" radius
        let background (value: obj) = Interop.mkBarAttr "background" value

    module xAxis =
        let stroke (value: string) = Interop.mkXAxisAttr "stroke" value

    module yAxis =
        let stroke (value: string) = Interop.mkYAxisAttr "stroke" value
        let tick (value: obj) = Interop.mkYAxisAttr "tick" value

    module tooltip =
        let cursor (value: obj) = Interop.mkTooltipAttr "cursor" value
        let labelFormatter (value: string -> string) = Interop.mkTooltipAttr "labelFormatter" value
