module Kundenportal.Types

open Types

type Filter =
    | Alle
    | NurNeue
    | Kategorie of string

module Video =
    let Quickfinder = Vimeo "594551227"
    let BfsVideo =
        Adventr
            "https%3A%2F%2Fd252srr1zuysk4.cloudfront.net%2Fclients%2F4152%2F6026%2Fpublished%2F4152-lebenslauf-einer-rechnung-99524122.data"

module SessionDataKeys =

    [<Literal>]
    let Kundennummer = "Kundennummer"

    [<Literal>]
    let UserId = "UserId"

    [<Literal>]
    let SprinklrChatHash = "SprinklrChatHash"

    [<Literal>]
    let SprinklrAppId = "SprinklrAppId"

    [<Literal>]
    let IsChatVisible = "IsChatVisible"


    [<Literal>]
    let FirstName = "FirstName"

    [<Literal>]
    let LastName = "LastName"

module SessionDataDefaultValue =
    [<Literal>]
    let Default = ""
