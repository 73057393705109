module Quickfinder.View

open System
open Bfs.Web.Data.Service.Contracts.Events
open Bfs.Web.Portale.Collapsable
open Browser
open Fable.React.Props
open Fable.React
open Fable.Core.JsInterop
open Bfs.Web.Kundenportal.WebParts.User.Events.Easter
open Bfs.Web.Data.Service.Contracts.Kundenportal.Quickfinder
open Bfs.Web.Shared.Formating
open Types
open Kundenportal.Types
open ViewParts


let onRechnungsnummerEnter (navigateTo: AnyPage -> unit) =
    let nummer = (document.getElementById "quickfinder-rechnungsnummer")?value

    if not (String.IsNullOrWhiteSpace nummer) then
        navigateTo (RechnungQuickfinder nummer)

let quickfinderBox navigateTo globalMsgdispatch nummer ostergewinnspielActive eggFound =

    let quickfinderImage = "quickfinder/bfs_quickfinder_1.png"

    div [ Class "columns quickfinder-box" ] [
        div [ Class "column is-12 help-center" ] [
            div [ Class "is-relative" ] [
                div [ Class "flat-card is-auto" ] [
                    div [
                        Class "card-body card-body-quickfinder columns"
                    ] [
                        div [
                            Class "column is-8-fullhd is-8-widescreen is-8-desktop is-6-tablet is-12-mobile firstcol"
                        ] [
                            img [
                                Class "quickfinderImg is-hidden-touch"
                                Src quickfinderImage
                            ]
                            img [
                                Class "quickfinderImgSmall is-hidden-desktop"
                                Src quickfinderImage
                            ]
                            div [] [
                                p [] [
                                    b [] [
                                        str "Das Wichtigste auf einen Klick!"
                                    ]
                                ]
                                p [ Class "sub" ] [
                                    str "Hier finden Sie schnell Informationen zu einer beliebigen Rechnung."
                                ]
                                p [
                                    Class "sub"
                                    Style [ MarginTop "8px" ]
                                ] [
                                    a [
                                        OnClick(fun _ -> ShowVideo Video.Quickfinder |> globalMsgdispatch)
                                        Style [ MarginLeft -10 ]
                                    ] [
                                        linkicon "fas fa-play-circle"
                                        str "Video zum Quick Finder anschauen"
                                    ]
                                ]
                            ]
                        ]

                        div [
                            Class "column is-4-fullhd is-4-widescreen is-4-desktop is-6-tablet is-12-mobile"
                        ] [
                            div [ Class "field has-addons " ] [
                                div [ Class "control" ] [
                                    input [
                                        Id "quickfinder-rechnungsnummer"
                                        Class "input"
                                        Type "text"
                                        DefaultValue nummer
                                        OnKeyDown(fun e ->
                                            if (e.key = "Enter") then
                                                onRechnungsnummerEnter navigateTo)
                                        Placeholder "Rechnungsnr."
                                    ]
                                ]
                                div [ Class "control" ] [
                                    a [
                                        Class "bfsbutton "
                                        OnClick(fun _ -> onRechnungsnummerEnter navigateTo)
                                    ] [ str "Anzeigen" ]
                                ]
                            ]
                        ]
                    ]
                ]
                if ostergewinnspielActive then
                    Egg Color.Blue Variant.Lines [ Left "30%" ]
                    Egg Color.Yellow Variant.Dotted [ Top "100%"; Left "75%" ]
                    clickableEgg {
                        OnCLick =
                            (fun _ ->
                                (EasterEgg.Quickfinder, None)
                                |> EasterEggFound
                                |> EventMsg
                                |> globalMsgdispatch)
                        Found = eggFound
                        Css = [ Top "0%"; Left "100%" ]
                    }
            ]
        ]
    ]

let quickfinderBoxDashboard navigateTo globalMsgdispatch nummer =

    let quickfinderImage = "dashboard/quickfinder_suche_96x96.svg"

    div [
        Class "columns quickfinder-box quickfinder-dashboard"
    ] [
        div [ Class "flat-card is-auto" ] [
            div [
                Class "card-body card-body-quickfinder"
            ] [
                div [ Class "quickfinder-content" ] [
                    div [ Class "quickfinder-icon" ] [
                        img [
                            Class "quickfinderImg"
                            Src quickfinderImage
                        ]
                    ]
                    div [ Class "quickfinder-text" ] [
                        div [] [
                            div [ Class "quickfinder-title" ] [
                                str "Das Wichtigste auf einen Klick!"
                            ]
                            p [ Class "sub quickfinder-description" ] [
                                str "Hier finden Sie schnell Informationen zu einer beliebigen Rechnung."
                            ]
                        ]
                        div [ Class "video-link" ] [
                            a [
                                OnClick(fun _ -> Video.Quickfinder |> ShowVideo |> globalMsgdispatch)
                            ] [
                                str "Erklärvideo"
                                img [
                                    Class "play-icon"
                                    Src "dashboard/quickfinder_play_32x32.svg"
                                ]
                            ]
                        ]
                    ]
                ]

                div [ Class "searchbox" ] [
                    div [ Class "field has-addons " ] [
                        div [
                            Class "control quickfinder-rechnungsnummer"
                        ] [
                            input [
                                Id "quickfinder-rechnungsnummer"
                                Class "input"
                                Type "text"
                                DefaultValue nummer
                                OnKeyDown(fun e ->
                                    if (e.key = "Enter") then
                                        onRechnungsnummerEnter navigateTo)
                                Placeholder "Rechnungsnr."
                            ]
                        ]
                        div [ Class "control" ] [
                            a [
                                Class "bfsbutton "
                                OnClick(fun _ -> onRechnungsnummerEnter navigateTo)
                            ] [ str "Anzeigen" ]
                        ]
                    ]
                ]
            ]
        ]
    ]

let box body =
    div [ Class "columns" ] [
        div [ Class "column is-12" ] [
            div [
                Class "flat-card profile-info-card is-auto"
            ] [ div [ Class "card-body" ] body ]
        ]
    ]


let timelineStart text =
    header [ Class "timeline-header" ] [
        span [ Class "tag is-medium is-primary" ] [ str text ]
    ]

let timelineIntermediate text =
    header [ Class "timeline-header" ] [
        span [ Class "tag is-primary" ] [ str text ]
    ]

let ticketText rechnungsnummer (information: Information) =
    sprintf "Rechnung %s, Status '%s' vom %s" rechnungsnummer information.Typ (information.Datum |> asString)

let downloadLink dispatch (downloadType: DownloadType) =
    match downloadType with
    | NoDownload -> nothing
    | WithDownloadDokumentAnfrage(text, id) -> Icons.DownloadLink text (Http.downloadUrlDokumentAnfrage id)
    | WithDownloadSammelrechnung(text, id) -> Icons.DownloadLink text (Http.downloadUrlSammelrechnung id)
    | WithDownloadButFlatrateNeeded text ->
        Icons.DownloadLink2 text (fun _ ->
            GlobalMessageBox.InfoWithTitle
                "Dokumentenabruf"
                "Sie möchten unseren Service für den Abruf Ihrer Dokumente nutzen? Diese Dienstleistung ermöglicht Ihnen den Zugriff und Download Ihrer eingereichten Belege. Buchen Sie diesen Service ganz einfach über die Einstellungen."
            |> GlobalMsg.ShowMessageBox
            |> GlobalMsg
            |> dispatch)

let timelineItem =
    FunctionComponent.Of
        (fun (dispatch: Msg -> unit, navigateTo: AnyPage -> unit, rechnungsnummer: string, information: Information) ->

            let isExpanded = Hooks.useState false
            let chevronClass = if isExpanded.current then "upsidedown" else System.String.Empty
            let expandableContent =
                div [ Class "helptext " ] [
                    if information.Hilfetext.IsSome then
                        p [] [ str information.Hilfetext.Value ]
                    div [] [
                        match information.TicketId with
                        | None ->
                            Icons.TicketErstellenLink(fun _ ->
                                navigateTo (
                                    Tickets.Types.Page.PrefilledTicket(
                                        (ticketText rechnungsnummer information),
                                        information.RechnungId
                                    )
                                ))
                        | Some id ->
                            Icons.TicketLink "Anzeigen" (fun _ -> navigateTo (Tickets.Types.Page.Ticket(Some id)))
                        information.Download |> downloadLink dispatch
                    ]
                ]

            div [ Class "timeline-item" ] [
                div [
                    Class "timeline-marker is-image is-32x32"
                ] [ div [ Class information.Icon ] [] ]
                div [ Class "timeline-content" ] [
                    p [ Class "date" ] [
                        str (sprintf "%s - %s" (information.Datum |> asLongString) information.Typ)
                    ]

                    p [
                        Class "text"
                        OnClick(fun _ -> isExpanded.update (isExpanded.current |> not))
                    ] [
                        str information.Text
                        a [] [
                            linkicon $"fas fa-chevron-down {chevronClass}"
                        ]
                    ]

                    CollapsableElement(expandableContent, isExpanded.current)
                ]
            ])

let timelineItem2 dispatch navigateTo rechnungsnummer (prev: Information, current: Information) = [|
    if
        (prev.Datum.Month <> current.Datum.Month
         || prev.Datum.Year <> current.Datum.Year)
    then
        yield timelineIntermediate (current.Datum |> asMonatJahr)

    yield timelineItem (dispatch, navigateTo, rechnungsnummer, current)
|]



let timelineEnd text =
    div [ Class "timeline-header" ] [
        span [ Class "tag is-medium is-primary" ] [ str text ]
    ]

let infoDisplay dispatch navigateTo (model: Model) (data: Information[]) =
    div [ Class "timeline quickfinder" ] [
        div [ Class "quicksort" ] [
            a [
                OnClick(fun _ -> SortierungAendern |> dispatch)
            ] [
                if model.AufsteigendSortieren then
                    linkicon "fas fa-sort-numeric-down"
                else
                    linkicon "fas fa-sort-numeric-up-alt"
                str "Sortierung ändern"
            ]
        ]

        //yield timelineStart (sprintf "Rechnung %s" model.Rechnungsnummer)

        let data = if model.AufsteigendSortieren then data else data |> Array.rev

        yield!
            Array.append [| Information.NoInformation |] data
            |> Array.pairwise
            |> Array.map (timelineItem2 dispatch navigateTo model.Rechnungsnummer)
            |> Array.concat

    //yield timelineEnd "End"
    ]

let view (model: Model) dispatch (navigateTo: AnyPage -> unit) =
    [
        quickfinderBox
            navigateTo
            (GlobalMsg >> dispatch)
            model.Rechnungsnummer
            model.OstergewinnspielActive
            model.EggFound

        match model.Rechnungsnummer, model.Information with
        | null, _
        | "", _
        | _, Empty -> nothing

        | _, Loading -> box [ spinner ]
        | _, LoadError e -> box [ errorMsg e ]

        | _, Body [||] ->
            box [
                str (
                    sprintf
                        "Die Rechnungsnummer %s konnten wir leider nicht finden. Bitte überprüfen Sie Ihre Eingabe."
                        model.Rechnungsnummer
                )
            ]
        | _, Body infos ->
            box [
                infoDisplay dispatch navigateTo model infos
            ]

    ]
    |> ofList
