module Dashboard.LetzterBelegeingang

open Bfs.Web.Data.Service.Contracts.Kundenportal.Dashboard
open Bfs.Web.Kundenportal.WebParts.User.Shared.Tile

open Fable.React
open Fable.React.Props
open Elmish


open Types
open Http
open ViewParts

open Dashboard.Shared

open Bfs.Web.Shared.Formating

type Model = {
    GlobalDispatch: GlobalMsg -> unit
    NavigateTo: AnyPage -> unit
    Loading: bool
    Data: LetzterBelegeingang
    SavingToggle: bool
}

type Msg =
    | LoadData
    | LoadDataCompleted of Result<LetzterBelegeingang, exn>
    | ToggleBenachrichtigung
    | NotificationToggled of Result<Fetch.Types.Response, exn>
    | NavigateToList

module Http =
    let loadAnstehendeZahlungen () = fetchAs<LetzterBelegeingang> "/api/dashboard/letzter-belegeingang"
    let setNotificationForLetzterBelegeingang enabled =
        let payload = { Enabled = enabled }
        postRecord<ToggleNotificationRequest> "/api/dashboard/set-notification-for-letzter-belegeingang" payload

module State =
    let init (props: DashboardProps) =
        {
            GlobalDispatch = props.GlobalDispatch
            NavigateTo = props.NavigateTo
            Loading = true
            Data = {
                Sendungen = []
                Benachrichtigung = false
            }
            SavingToggle = true
        },
        Cmd.ofMsg LoadData

    let update (msg: Msg) (model: Model) : Model * Cmd<Msg> =
        match msg with
        | LoadData ->
            let cmd = LoadDataCompleted |> request Http.loadAnstehendeZahlungen ()
            {
                model with
                    Loading = true
                    SavingToggle = true
            },
            cmd
        | LoadDataCompleted(Ok data) ->
            {
                model with
                    Loading = false
                    Data = data
                    SavingToggle = false
            },
            Cmd.none
        | LoadDataCompleted(Error _) ->
            let msg =
                GlobalMessageBox.Error
                    "Die Daten zu Ihrem letzten Belegeingang können zur Zeit leider nicht geladen werden."
                |> ShowMessageBox
            model.GlobalDispatch msg
            { model with Loading = false }, Cmd.none
        | ToggleBenachrichtigung ->
            let newValue = not model.Data.Benachrichtigung
            let cmd =
                NotificationToggled
                |> request Http.setNotificationForLetzterBelegeingang newValue
            {
                model with
                    SavingToggle = false
                    Data = {
                        model.Data with
                            Benachrichtigung = newValue
                    }
            },
            cmd
        | NotificationToggled(Ok _) -> { model with SavingToggle = false }, Cmd.none
        | NotificationToggled(Error er) ->
            let msg =
                GlobalMessageBox.Error
                    "Ihre neuen Benachrichtigungseinstellungen können zur Zeit leider nicht gespeichert werden."
                |> ShowMessageBox
            model.GlobalDispatch msg
            {
                model with
                    SavingToggle = false
                    Data = {
                        model.Data with
                            Benachrichtigung = not model.Data.Benachrichtigung
                    }
            },
            Cmd.none
        | NavigateToList ->
            model.NavigateTo LetzterBelegeingang.Types.Page.List
            model, Cmd.none

let View =
    FunctionComponent.Of(fun (props: DashboardProps) ->
        let model, dispatch = React.useElmish ((State.init props), State.update)
        let title = str "Letzter Belegeingang"
        let titleAction =
            Some(
                a [
                    OnClick(fun _ -> NavigateToList |> dispatch)
                ] [ navigationArrow ]
            )
        let footer =
            match model.Loading with
            | true -> None
            | false ->
                Some(
                    notificationBox
                        "Benachrichtigung"
                        model.Data.Benachrichtigung
                        model.SavingToggle
                        (fun _ -> ToggleBenachrichtigung |> dispatch)
                        "Letzter-Belegeingang-Toggle"
                )
        let icon =
            Some(
                img [
                    Class "dashboard-icon"
                    Src(
                        match model.Data.Sendungen.IsEmpty with
                        | false -> "dashboard/Sendung_eingegangen.svg"
                        | true -> "dashboard/belegeingang_waiting_96x96.svg"
                    )
                ]
            )
        let body =
            match model.Loading with
            | true -> spinner
            | false ->
                div
                    [ Class "sendungs-list" ]
                    (match model.Data.Sendungen.IsEmpty with
                     | true -> [
                         div [ Class "sendung" ] [
                             str "Wir freuen uns auf Ihre erste Sendung!"
                         ]
                       ]
                     | false ->
                         model.Data.Sendungen
                         |> List.map (fun sendung ->
                             div [
                                 Class "sendung"
                                 Key(sendung.SendungsNummer.ToString())
                             ] [
                                 div [ Class "large" ] [
                                     str $"Sendung {sendung.SendungsNummer}"
                                 ]
                                 div [ Class "subtle" ] [ str sendung.Status ]
                                 div [
                                     Class "is-size-7 has-text-grey-light"
                                 ] [
                                     str (sendung.Datum |> asStringWithTime)
                                 ]
                             ]))

        bfsTile title titleAction icon body footer)
