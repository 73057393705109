module Dashboard.MeineLetzteSendung

open Bfs.Web.Data.Service.Contracts.Kundenportal.Dashboard
open Bfs.Web.Kundenportal.WebParts.User.Shared.Tile
open Fable
open Fable.React
open Fable.React.Props
open Feliz.Recharts
open Elmish

open Types
open Http
open ViewParts

open Dashboard.Shared

open Bfs.Web.Shared.Formating

type Model = {
    GlobalDispatch: GlobalMsg -> unit
    NavigateTo: AnyPage -> unit
    Loading: bool
    Data: MeineLetzteSendung
    SavingToggle: bool
}

type Msg =
    | LoadData
    | LoadDataCompleted of Result<MeineLetzteSendung, exn>
    | ToggleBenachrichtigung
    | NotificationToggled of Result<Fetch.Types.Response, exn>
    | NavigateToList

module Http =
    let loadSendungseingang () = fetchAs<MeineLetzteSendung> "/api/dashboard/meine-letzte-sendung"
    let setNotificationForBelegpruefungAbgeschlossen enabled =
        let payload = { Enabled = enabled }
        postRecord<ToggleNotificationRequest> "/api/dashboard/set-notification-for-belegpruefung-abgeschlossen" payload

module State =
    let init (props: DashboardProps) =
        {
            GlobalDispatch = props.GlobalDispatch
            NavigateTo = props.NavigateTo
            Loading = true
            Data = {
                LetzteSendung = None
                Benachrichtigung = false
            }
            SavingToggle = true
        },
        Cmd.ofMsg LoadData

    let update (msg: Msg) (model: Model) : Model * Cmd<Msg> =
        match msg with
        | LoadData ->
            let cmd = LoadDataCompleted |> request Http.loadSendungseingang ()
            {
                model with
                    Loading = true
                    SavingToggle = true
            },
            cmd
        | LoadDataCompleted(Ok data) ->
            {
                model with
                    Loading = false
                    Data = data
                    SavingToggle = false
            },
            Cmd.none
        | LoadDataCompleted(Error _) ->
            let msg =
                GlobalMessageBox.Error "Die Daten für Ihre letzte Sendung können zur Zeit leider nicht geladen werden."
                |> ShowMessageBox
            model.GlobalDispatch msg
            { model with Loading = false }, Cmd.none
        | ToggleBenachrichtigung ->
            let newValue = not model.Data.Benachrichtigung
            let cmd =
                NotificationToggled
                |> request Http.setNotificationForBelegpruefungAbgeschlossen newValue
            {
                model with
                    SavingToggle = false
                    Data = {
                        model.Data with
                            Benachrichtigung = newValue
                    }
            },
            cmd
        | NotificationToggled(Ok _) -> { model with SavingToggle = false }, Cmd.none
        | NotificationToggled(Error _) ->
            let msg =
                GlobalMessageBox.Error
                    "Ihre neuen Benachrichtigungseinstellungen können zur Zeit leider nicht gespeichert werden."
                |> ShowMessageBox
            model.GlobalDispatch msg
            {
                model with
                    SavingToggle = false
                    Data = {
                        model.Data with
                            Benachrichtigung = not model.Data.Benachrichtigung
                    }
            },
            Cmd.none
        | NavigateToList ->
            model.NavigateTo LetzteSendung.Types.Page.List
            model, Cmd.none

type PieSlice = {
    color: string
    value: float
    key: string
}

let View =
    FunctionComponent.Of(fun (props: DashboardProps) ->

        let model, dispatch = React.useElmish ((State.init props), State.update)
        let chartSize = 80
        let title = str "Meine letzte Sendung"
        let titleAction =
            Some(
                a [
                    OnClick(fun _ -> NavigateToList |> dispatch)
                ] [ navigationArrow ]
            )
        let footer =
            match model.Loading with
            | true -> None
            | false ->
                Some(
                    notificationBox
                        "Benachrichtigung"
                        model.Data.Benachrichtigung
                        model.SavingToggle
                        (fun _ -> (ToggleBenachrichtigung |> dispatch))
                        "Letzte-Sendung-Toggle"
                )

        let icon =
            match model.Loading, model.Data.LetzteSendung with
            | true, _ -> None
            | _, None ->
                Some(
                    img [
                        Class "dashboard-icon"
                        Src "Dashboard/sendung_waiting_96x96.svg"
                    ]
                )
            | _, Some sendung ->
                let angekauft =
                    match sendung.Angekauft with
                    | Some value -> value
                    | None -> 0M
                let chartData = [
                    {
                        color = "gray"
                        value = float angekauft
                        key = "angekauft"
                    }
                    {
                        color = "lightgray"
                        value = float (sendung.Gesamtvolumen - angekauft)
                        key = "volumen"
                    }
                ]
                Some(
                    div [ Class "chart" ] [
                        Recharts.pieChart [
                            pieChart.margin (0, 0, 0, 0)
                            pieChart.height chartSize
                            pieChart.width chartSize
                            pieChart.children [
                                Recharts.pie [
                                    pie.data chartData
                                    pie.dataKey (fun (data: PieSlice) -> data.value)
                                    pie.cx (chartSize / 2)
                                    pie.cy (chartSize / 2)
                                    pie.innerRadius (chartSize / 3)
                                    pie.outerRadius (chartSize / 2)
                                    pie.children (
                                        chartData
                                        |> List.map (fun data ->
                                            Recharts.cell [
                                                cell.fill data.color
                                                cell.key data.key
                                            ])
                                    )
                                ]
                            ]
                        ]
                    ]
                )

        let body =
            match model.Loading with
            | true -> spinner
            | false ->
                match model.Data.LetzteSendung with
                | None ->
                    div [] [
                        str "Wir freuen uns auf Ihre erste Sendung!"
                    ]
                | Some sendung ->
                    [
                        div [
                            Class "is-size-7 has-text-grey-light"
                        ] [
                            str $"Sendung {sendung.SendungsNummer}:"
                        ]
                        div [] [
                            b [ Class "is-size-4 nowrap" ] [
                                str (sendung.Gesamtvolumen |> asMoney)
                            ]
                        ]
                        div [
                            Class "is-size-7 has-text-grey-light"
                        ] [ str "Sendungswert" ]

                        div [] [
                            table [ Class "mt-4" ] [
                                tbody [] [
                                    tr [ Class "has-text-weight-bold" ] [
                                        td [ Class "pr-2" ] [ str "Angekauft:" ]
                                        td [] [
                                            str ((sendung.Angekauft |> Option.defaultValue 0M) |> asMoney)
                                        ]
                                    ]
                                    tr [ Class "subtle" ] [
                                        td [ Class "pr-2" ] [ str "Nicht angekauft:" ]
                                        td [] [
                                            str (
                                                (sendung.NichtAngekauft |> Option.defaultValue 0M)
                                                |> asMoney
                                            )
                                        ]
                                    ]
                                ]
                            ]
                        ]
                    ]
                    |> ofList

        bfsTile title titleAction icon body footer)
