module Dashboard.Chats

open Bfs.Web.Data.Service.Contracts.Kundenportal.Dashboard
open Bfs.Web.Kundenportal.WebParts.User.Shared.Tile

open Fable.React
open Fable.React.Props
open Elmish


open Feliz.Recharts
open Types
open Http
open ViewParts

open Dashboard.Shared

type Model = {
    GlobalDispatch: GlobalMsg -> unit
    NavigateTo: AnyPage -> unit
    Loading: bool
    Data: Chats
}

type Msg =
    | LoadData
    | LoadDataCompleted of Result<Chats, exn>

module Http =
    let loadChats () = fetchAs<Chats> "/api/dashboard/chats"

module State =
    let init (props: DashboardProps) =
        {
            GlobalDispatch = props.GlobalDispatch
            NavigateTo = props.NavigateTo
            Loading = true
            Data = {
                ChatsAnzahl = 0
                ChatsBewertung = 5M
                ChatsBewertungsMonat = ""
            }
        },
        Cmd.ofMsg LoadData

    let update (msg: Msg) (model: Model) : Model * Cmd<Msg> =
        match msg with
        | LoadData ->
            let cmd = LoadDataCompleted |> request Http.loadChats ()
            { model with Loading = false }, cmd
        | LoadDataCompleted(Ok data) ->
            {
                model with
                    Loading = false
                    Data = data
            },
            Cmd.none
        | LoadDataCompleted(Error _) ->
            let msg =
                GlobalMessageBox.Error "Ihre Chat-Daten können zur Zeit leider nicht geladen werden."
                |> ShowMessageBox
            model.GlobalDispatch msg
            { model with Loading = false }, Cmd.none

let chart (value: int) (max: int) =
    let barHeight = 15
    Recharts.responsiveContainer [
        responsiveContainer.width (Feliz.length.percent 100)
        responsiveContainer.height barHeight
        responsiveContainer.chart (
            Recharts.barChart [
                barChart.margin (0, 0, 0, 0)
                barChart.data [ value ]
                barChart.width 200
                barChart.height barHeight
                barChart.layout.vertical
                barChart.children [
                    Recharts.xAxis [
                        xAxis.number
                        xAxis.domain ((domain.constant 0), (domain.constant max))
                        xAxis.hide true
                    ]
                    Recharts.yAxis [
                        yAxis.category
                        yAxis.dataKey (fun _ -> System.String.Empty)
                        yAxis.hide true
                    ]
                    Recharts.bar [
                        bar.dataKey (fun (point: int) -> point)
                        bar.fill "gray"
                        RechartExtensions.bar.background {| fill = "lightgray"; radius = 10 |}
                        RechartExtensions.bar.radius ([ 10; 10; 10; 10 ] |> List.toArray)
                        bar.barSize barHeight
                    ]
                ]
            ]
        )
    ]

let View =
    FunctionComponent.Of(fun (props: DashboardProps) ->
        let model, _ = React.useElmish ((State.init props), State.update)
        let title = str "Chats"
        let body =
            match model.Loading with
            | true -> spinner
            | false ->
                div [ Class "two-value-display" ] [
                    img [
                        Class "dashboard-icon-small first-icon"
                        Src "dashboard/chats_1_48x48.svg"
                    ]

                    div [
                        Class "is-size-3 has-text-grey has-text-weight-semibold first-value"
                    ] [
                        str (model.Data.ChatsAnzahl.ToString())
                    ]
                    div [ Class "subtle first-description" ] [
                        str $"Meine Chats im {model.Data.ChatsBewertungsMonat}"
                    ]

                    img [
                        Class "dashboard-icon-small second-icon"
                        Src "dashboard/chats_2_48x48.svg"
                    ]
                    div [
                        Class "is-size-3 has-text-grey has-text-weight-semibold second-value"
                    ] [
                        str (model.Data.ChatsBewertung.ToString())
                    ]
                    div [ Class "subtle second-description" ] [
                        str $"Durchschnittliche Bewertung aller Chats im {model.Data.ChatsBewertungsMonat}"
                    ]
                ]
        bfsTile title None None body None)
