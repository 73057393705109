﻿module Bfs.Web.Kundenportal.WebParts.User.Shared.DataList

open Fable.React
open Fable.React.Props

let mobileDataList<'T> (messageEmpty: string) (mapping: (ReactElement * ('T -> ReactElement)) list) (data: 'T list) =
    match data.IsEmpty with
    | true ->
        div [
            Class "has-text-centered is-hidden-desktop"
        ] [ str messageEmpty ]
    | false ->
        div
            [ Class "data-list is-hidden-desktop" ]
            (data
             |> List.map (fun row ->
                 div
                     [ Class "data-item" ]
                     (mapping
                      |> List.map (fun (fieldLabel, fieldValue) ->
                          div [ Class "data-set" ] [
                              label [] [ fieldLabel ]
                              div [] [ fieldValue row ]
                          ]))))

let desktopDataTable<'T> (messageEmpty: string) (mapping: (ReactElement * ('T -> ReactElement)) list) (data: 'T list) =
    table [
        Class "table table-striped responsive-table is-hidden-touch is-bfscolored"
    ] [
        thead [] [
            tr
                []
                (mapping
                 |> List.map (fun (fieldLabel, _) -> th [] [ fieldLabel ]))
        ]
        tbody
            []
            (match data.IsEmpty with
             | true -> [
                 tr [] [
                     td [
                         ColSpan mapping.Length
                         Class "has-text-centered"
                     ] [ str messageEmpty ]
                 ]
               ]
             | false ->
                 data
                 |> List.map (fun row ->
                     tr
                         []
                         (mapping
                          |> List.map (fun (_, fieldValue) -> td [] [ fieldValue row ]))))
    ]
