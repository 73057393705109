module Dta.View

open Bfs.Web.Portale.UploadBox
open Fable.React

open Types
open Bfs.Web.Data.Service.Contracts.Kundenportal.Dta
open Bfs.Web.Kundenportal.WebParts.User.Events.Easter
open Fable.React.Props

open ViewParts
open Browser

[<Literal>]
let timeFormat = "HH:mm"

let dtaListItemView (dta: DtaListItem) =
    tr [] [
        td [] [
            span [] [
                str (dta.Hochgeladen.ToString("dd.MM.yyyy "))
            ]
            span [ Class "pre" ] [
                str $"{(dta.Hochgeladen.ToString(timeFormat))} Uhr"
            ]
        ]
        td [] [ str dta.Dateiname ]
        td [] [ str dta.Statusmeldung ]
    ]
let dtaTable dtaDateien =
    table [
        Class "table table-striped responsive-table is-hidden-mobile"
    ] [
        thead [] [
            tr [] [
                th [] [ str "Hochgeladen am" ] // 12.03.2012 00:00 Uhr
                th [] [ str "Dateiname" ] // DTA-Datei 3
                th [] [ str "Status" ] // Warten auf Belegeingang
            ]
        ]
        tbody [] (dtaDateien |> List.map dtaListItemView)
    ]

let dtaTableMobile (dtaDateien: DtaListItem list) =
    div [ Class "data-list is-hidden-tablet" ] [
        dtaDateien
        |> List.map (fun f ->
            div [ Class "data-item" ] [
                div [] [
                    div [ Class "dta-file" ] [ str f.Dateiname ]
                    div [ Class "dta-date" ] [
                        span [] [
                            str (f.Hochgeladen.ToString("dd.MM.yyyy "))
                        ]
                        span [ Class "pre" ] [
                            str $"{(f.Hochgeladen.ToString(timeFormat))} Uhr"
                        ]
                    ]
                ]
                div [ Class "dta-status" ] [ str f.Statusmeldung ]
            ])
        |> ofList
    ]

let dtaListBox (model: Types.Model) navigateTo =
    div [
        Class "column is-9-desktop is-relative"
    ] [
        if model.OstergewinnspielActive then
            yield (Egg Color.Yellow Variant.Dotted [ Top "60%"; Left "12px" ])
            yield (Egg Color.Blue Variant.Stripped [ Top "12px"; Left "80%" ])
        yield
            div [
                Class "flat-card profile-info-card is-auto"
            ] [
                div [ Class "card-title" ] [
                    h3 [] [ b [] [ str "Aktuelle DTA-Dateien" ] ]
                ]
                div [ Class "card-body" ] [
                    match model.DtaList with
                    | Empty -> yield div [] []
                    | Loading -> yield spinner
                    | LoadError s -> yield errorMsg "Fehler beim Laden der DTA-Dateien!"
                    | Body x when x.Items = [] -> yield div [] [ str "Keine DTA-Dateien vorhanden" ]
                    | Body x ->
                        yield dtaTable x.Items
                        yield dtaTableMobile x.Items
                ]
            ]
        match model.DtaList with
        | Body x -> yield pagination x (fun page -> navigateTo (Page.DtaList page))
        | _ -> ()
    ]

let dtaUploadBox (model: Types.Model) dispatch =

    div [
        Class "column is-3-desktop is-relative is-hidden-touch"
    ] [
        if model.OstergewinnspielActive then
            (Egg Color.Green Variant.Dotted [ Top "20%"; Left "12px" ])
            (Egg Color.Pink Variant.Stripped [ Top "12px"; Left "40%" ])

        UploadBox(
            Accept.All,
            (fun fileList -> fileList |> UploadFiles |> dispatch),
            (fun file -> file |> DeleteUploadedFile |> dispatch),
            model.UploadList,
            model.UploadCount,
            (Some {
                IsSending = model.IsSending()
                CanConfirm = model.CanConfirm()
                ConfirmUpload = (fun _ -> ConfirmUploadedFiles |> dispatch)
            }),
            (fun msg -> msg |> Msg.GlobalMsg |> dispatch)
        )
    ]

let view (model: Types.Model) dispatch (navigateTo: AnyPage -> unit) =
    div [
        Class "columns is-desktop is-account-grid is-multiline datenuebergabe"
    ] [
        dtaUploadBox model dispatch
        dtaListBox model navigateTo
    ]
