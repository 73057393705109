﻿module Dashboard.Shared

open Bfs.Web.Kundenportal.WebParts.User.Shared
open Fable.React
open Fable.React.Props

open Types
open ViewParts

type DashboardProps = {
    GlobalDispatch: GlobalMsg -> unit
    NavigateTo: AnyPage -> unit
    MostRecentCallback: AnyWebPartMsg option
}

let notificationBox text isChecked isDisabled onToggle id =
    div [ Class "notification-box" ] [
        span [
            Class "is-size-7 pr-2 has-text-grey-light"
        ] [ str text ]
        regularSwitch isChecked isDisabled onToggle "" id
    ]

module NotificationTile =
    open Elmish

    type NotificationTileProps = {
        GlobalDispatch: GlobalMsg -> unit
        UniqueSessionKey: string
        Flag: string
        Title: string
        Body: ReactElement
        Session: UserSession option
    }

    type Model = {
        SessionKey: string
        Visible: bool
        GlobalDispatch: GlobalMsg -> unit
    }

    type Msg = | CloseDialog

    module State =

        let init (props: NotificationTileProps) =
            let alreadySeen =
                props.Session
                |> Option.map (fun u -> u.SessionData |> Map.containsKey props.UniqueSessionKey)
                |> Option.defaultValue false
            {
                SessionKey = props.UniqueSessionKey
                Visible = alreadySeen |> not
                GlobalDispatch = props.GlobalDispatch
            },
            Cmd.none

        let update msg model : Model * Cmd<Msg> =
            match msg with
            | CloseDialog ->
                let msg = GlobalMsg.UpdateUserSessionData(model.SessionKey, "")
                model.GlobalDispatch msg
                { model with Visible = false }, Cmd.none

    let view =
        FunctionComponent.Of(fun (props: NotificationTileProps) ->
            let model, dispatch = React.useElmish ((State.init props), State.update, [||])

            match model.Visible with
            | false -> fragment [] []
            | true ->
                let title =
                    div [ Class "notification-title" ] [
                        div [ Class "notification-flag" ] [ str props.Flag ]
                        div [ Class "notification-title-text" ] [ str props.Title ]
                    ]

                let action =
                    button [
                        Class "bfsdelete narrow"
                        Title "schließen"
                        OnClick(fun _ -> CloseDialog |> dispatch)
                    ] []

                div [ Class "notification-tile" ] [
                    Tile.bfsTile title (Some action) None props.Body None
                ])
