﻿module AdminBanner.WebPart

open WebPart
open Types

let Part = {
    Init = State.init
    Update = State.update
    View = View.view

    GetGlobalMsg =
        function
        | GlobalMsg m -> Some m
        | _ -> None

    GetHeader = fun _ -> Header "Administration: Banner"

    BuildUrl =
        function
        | Page.BannerList 1 -> [ Urls.admin; Urls.banner ]
        | Page.BannerList p -> [
            Urls.admin
            Urls.banner
            Urls.pageSeperator
            p.ToString()
          ]
        | Page.BannerEdit id -> [ Urls.admin; Urls.banner; id ]
        | Page.BannerNew -> [ Urls.admin; Urls.banner; Urls.newId ]

    ParseUrl =
        function
        | [ Urls.admin; Urls.banner ] -> Some(Page.BannerList 1)
        | [ Urls.admin; Urls.banner; Urls.pageSeperator; page ] -> Some(Page.BannerList(System.Int32.Parse(page)))
        | [ Urls.admin; Urls.banner; Urls.newId ] -> Some(Page.BannerNew)
        | [ Urls.admin; Urls.banner; id ] -> Some(Page.BannerEdit id)
        | _ -> None
}
