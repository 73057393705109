module DokumentenDepot.View

open Bfs.Web.Data.Service.Contracts.Kundenportal.Dashboard.Lists
open Bfs.Web.Kundenportal.WebParts.User.DashBoardPage
open Bfs.Web.Kundenportal.WebParts.User.Shared.DataList
open Fable.React

open Types
open Fable.React.Props

open ViewParts

open Bfs.Web.Shared.Formating

let columnMappingMobile = [
    (str "SendungsNr", (fun row -> str (row.SendungNr.ToString())))
    (str "Rechnungsnummer", (fun row -> str row.RechnungsNrExtern))
    (str "Klient/Patient", (fun row -> str row.KlientPatient))
    (str "Eingelagert", (fun row -> str (row.EingelagertAm |> asString)))
    (str "Art", (fun row -> str row.DokumentArt))
    (str "Grund", (fun row -> str row.Grund))
    (str "Rücksendung",
     (fun row ->
         str (
             match row.RuecksendungAm with
             | Some date -> date |> asString
             | None -> System.String.Empty
         )))
]

let columnMappingDesktop = [
    (str "SendungsNr", (fun row -> str (row.SendungNr.ToString())))
    (str "Rechnungsnummer", (fun row -> str row.RechnungsNrExtern))
    (str "Klient/Patient", (fun row -> str row.KlientPatient))
    (str "Art", (fun row -> str row.DokumentArt))
    (str "Grund", (fun row -> str row.Grund))
    (div [] [
        div [] [ str "Eingelagert" ]
        div [] [ str "Rücksendung" ]
     ],
     (fun row ->
         div [] [
             div [] [ str (row.EingelagertAm |> asString) ]
             div [] [
                 str (
                     match row.RuecksendungAm with
                     | Some date -> date |> asString
                     | None -> System.String.Empty
                 )
             ]
         ]))
]

let mobileTable (data: DokumentenDepot list) =
    data
    |> List.sortBy (fun x -> x.KlientPatient)
    |> List.sortByDescending (fun x -> x.SendungNr)
    |> mobileDataList "Keine eingegangenen Belege." columnMappingMobile


let desktopTable (data: DokumentenDepot list) =
    data
    |> List.sortBy (fun x -> x.KlientPatient)
    |> List.sortByDescending (fun x -> x.SendungNr)
    |> desktopDataTable "Keine eingegangenen Belege." columnMappingDesktop

let view (model: Model) _ (navigateTo: AnyPage -> unit) =

    match model.Loading with
    | true -> spinner
    | false ->
        div [
            Class "flat-card profile-info-card is-auto overflow-initial"
        ] [
            div [ Class "card-title" ] [
                a [
                    OnClick(fun _ -> navigateTo DashboardPage.Dashboard)
                ] [
                    i [ Class "fas fa-angle-left mr-2" ] []
                    str "Zurück zum Dashboard"
                ]
            ]
            div [ Class "card-body" ] [
                mobileTable model.Data
                desktopTable model.Data
            ]
        ]
