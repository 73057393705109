module Password.View

open Types
open Fable.React.Props
open Fable.React

open ViewParts

let submitOnEnter dispatch =
    OnKeyDown(fun (e) ->
        if (e.key = "Enter") then
            dispatch Submit)

let rightSpinner =
    div [
        Class "column is-4 is-form-padded form-column"
    ] [
        div [ Class "spacer" ] []
        spinnerwhite
    ]

let submitButton isLoading canSubmit dispatch =
    let isLoading = if isLoading then " is-loading" else ""

    [
        p [ Class "is-pulled-right" ] [
            button [
                Type "submit"
                Class("bfsbutton " + isLoading)
                Disabled(not (canSubmit))
                Props.OnClick(fun _ -> dispatch (Submit))
            ] [ str "Absenden" ]
        ]
        div [ Class "spacer" ] []
    ]
    |> ofList

let viewResetPassword model dispatch =
    div [
        Class "is-form-padded paragraphs form-column"
    ] [
        div [ Class "toppart" ] [
            h2 [] [ str "Passwort zurücksetzen" ]
            p [] [
                str "Bitte geben Sie ein neues Passwort ein und wiederholen es."
            ]
        ]

        div [ Class "bottompart" ] [
            p [
                Class "info-block"
                Style [ MaxWidth "400px" ]
            ] [
                validatedPasswordBoxWithoutErrorMsg
                    "is-tooltip-right"
                    []
                    (dispatch << PasswordChanged)
                    "Passwort:"
                    "Passwort"
                    ""
                    model.Password
            ]
            p [
                Class "info-block"
                Style [ MaxWidth "400px" ]
            ] [
                validatedPasswordBoxWithoutErrorMsg
                    "is-tooltip-right"
                    [ submitOnEnter dispatch ]
                    (dispatch << Password2Changed)
                    "Wiederholung:"
                    "Wiederholung"
                    ""
                    model.Password2
            ]

            if (model.Message.IsSome) then
                p [ Class "content" ] [ str model.Message.Value ]

            submitButton model.Sending model.CanSubmit dispatch
        ]
    ]

let viewLostPassword model dispatch =
    div [
        Class "is-form-padded paragraphs form-column"
    ] [
        div [ Class "toppart" ] [
            h2 [] [ str "Passwort zurücksetzen" ]
            p [] [
                str "Bitte geben Sie Ihre E-Mail-Adresse ein, die Sie zur Anmeldung im BFS Kundenportal nutzen."
            ]
        ]

        div [ Class "bottompart" ] [
            p [ Class "info-block" ] [
                validatedTextBoxWithoutErrorMsg2
                    [ submitOnEnter dispatch ]
                    (dispatch << EmailChanged)
                    "E-Mail:"
                    "E-Mail"
                    "abc@example.com"
                    model.Email
            ]

            p [] [
                a [
                    OnClick(fun _ -> Logoff |> GlobalMsg |> dispatch)
                ] [ str "Zurück zur Startseite" ]
            ]

            submitButton model.Sending model.CanSubmit dispatch
        ]
    ]

let viewMessageModel model dispatch =
    div [
        Class "is-form-padded paragraphs form-column"
    ] [
        div [ Class "toppart" ] [ p [] [ str model.Text ] ]

        div [ Class "bottompart" ] [
            p [ Class "is-pulled-right" ] [
                button [
                    Type "submit"
                    Class("bfsbutton")
                    Props.OnClick(fun _ -> (GlobalMsg Logoff) |> dispatch)
                ] [ str "Zurück zur Startseite" ]
            ]
        ]
    ]

let view model dispatch navigateTo =

    div [
        Class "loginform is-auto is-auth-form category-header passwordreset"
    ] [
        div [
            Class "loginform is-auto is-auth-form category-header"
        ] [
            match model with
            | LostPasswordModel model -> viewLostPassword model dispatch
            | ResetPasswordModel model -> viewResetPassword model dispatch
            | MessageModel model -> viewMessageModel model dispatch
        ]
    ]
