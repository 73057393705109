module Files.View

open Bfs.Web.Kundenportal.WebParts.User.Shared.Dropdown
open Bfs.Web.Portale.Collapsable
open Fable.React
open Fable.React.Props

open Types
open Kundenportal.Types
open Bfs.Web.Data.Service.Contracts
open Bfs.Web.Data.Service.Contracts.Kundenportal.File
open Bfs.Web.Kundenportal.WebParts.User.Events.Easter

open ViewParts
open Files.Http

let fileZeile dispatch expandedIds (file: FileListItem) =
    let isExpanded = expandedIds |> List.contains file.Id
    let iconclass = if isExpanded then " upsidedown" else ""

    tr [] [
        td [] [
            if file.IsNew then
                newIndicator
            div [] [
                str file.Titel
                p [ Class "is-hidden-desktop" ] [
                    str (file.Datum.ToString("dd.MM.yyyy"))
                ]
            ]
            CollapsableElement(p [] [ str file.Beschreibung ], isExpanded)
        ]

        td [ Class "is-hidden-touch" ] [
            str (file.Datum.ToString("dd.MM.yyyy"))
        ]
        td [ Class "icon-column" ] [
            a [
                Href(downloadUrl file)
                OnClick(fun _ -> MarkRead file.Id |> dispatch)
            ] [ linkicon ("fas fa-file-download") ]
        ]
        td [ Class "icon-column" ] [
            if file.Beschreibung <> "" then
                a [
                    OnClick(fun _ -> ToggleBeschreibung file.Id |> dispatch)
                ] [
                    linkicon ("fas fa-chevron-down" + iconclass)
                ]
        ]
    ]

let filesTabelle (fileList: FileListItem list) expandedIds dispatch =
    table [
        Class "table table-striped responsive-table "
    ] [ // is-hidden-mobile
        thead [ Class "is-hidden-touch" ] [
            tr [] [
                th [] [ str "Dokument" ]
                th [ ColSpan 3 ] [
                    span [ Class "is-hidden-touch" ] [ str "Bereitgestellt am" ]
                ] // in weiteren spalten : 2 icons
            ]
        ]
        tbody [] (fileList |> List.map (fileZeile dispatch expandedIds))
    ]

let filesBox model dispatch =
    div [
        Class "flat-card profile-info-card is-auto overflow-initial is-relative"
    ] [
        if model.OstergewinnspielActive then
            (Egg Color.Yellow Variant.Lines [ Top "60%" ])
            (Egg Color.Green Variant.Dotted [ Left "75%" ])
            (Egg Color.Orange Variant.Stripped [ Left "35%"; Top "100%" ])
        div [ Class "card-title" ] [
            h3 [] [ b [] [ str "Ihre Dokumente" ] ]
        ]
        div [ Class "card-body" ] [
            match model.Files with
            | Empty -> nothing
            | Loading -> spinner
            | LoadError _ -> errorMsg "Ladefehler"
            | Body f when f.Items.IsEmpty -> errorMsg "Keine Dokumente vorhanden."
            | Body f -> filesTabelle f.Items model.ExpandedBeschreibungen dispatch
        ]
    ]

let kategorie name navigateToFilter currentFilter navigateTo =
    let active = if navigateToFilter = currentFilter then " is-active" else ""
    let isnew = if navigateToFilter = NurNeue then " new-kategorie" else ""

    li [
        Class("wishlist-item " + active + isnew)
        OnClick(fun _ -> navigateTo (Page.Files(1, navigateToFilter)))
    ] [
        div [ Class "item-wrapper" ] [ yield a [] [ str name ] ]
    ]

let kategorien (model: Types.Model) dispatch navigateTo =
    div [ Class "column is-4 is-hidden-touch" ] [
        div [
            Class "flat-card is-auto menu-card is-relative overflow-initial"
        ] [
            if model.OstergewinnspielActive then
                (Egg Color.Blue Variant.Lines [ Top "60%" ])
            ul [ Class "wishlists" ] [
                yield kategorie "Neue Dokumente" NurNeue model.SelectedFilter navigateTo
                yield kategorie "Alle Dokumente" Alle model.SelectedFilter navigateTo

                match model.Kategorien with
                | Empty -> yield div [] []
                | Loading -> yield spinner
                | LoadError _ -> yield errorMsg "Ladefehler"
                | Body k ->
                    yield
                        k
                        |> List.map (fun x -> kategorie x (Kategorie x) model.SelectedFilter navigateTo)
                        |> ofList

            ]
        ]
    ]

let kategorienMobile (model: Types.Model) dispatch navigateTo =
    Fulma.Column.column [
        Fulma.Column.Width(Fulma.Screen.All, Fulma.Column.Is12)
        Fulma.Column.Modifiers [
            Fulma.Modifier.IsHidden(Fulma.Screen.Desktop, true)
        ]
    ] [
        match model.Kategorien with
        | Empty -> div [] []
        | Loading -> spinner
        | LoadError _ -> errorMsg "Ladefehler"
        | Body k ->
            let categories = k |> List.map Kategorie

            bfsDropdown {
                Data = [ NurNeue; Alle ] @ categories
                SelectedElement = model.SelectedFilter |> Some
                ElementToKey =
                    (fun filter ->
                        match filter with
                        | Alle -> "Alle"
                        | NurNeue -> "NurNeue"
                        | Kategorie k -> k)
                ElementToString =
                    (fun filter ->
                        match filter with
                        | Alle -> "Alle Dokumente"
                        | NurNeue -> "Neue Dokumente"
                        | Kategorie k -> k)
                OnSelect = (fun filter -> navigateTo (Page.Files(1, filter)))
            }
    ]

let view (model: Types.Model) dispatch (navigateTo: AnyPage -> unit) =
    div [
        Class "columns is-account-grid is-multiline dokumente"
    ] [
        kategorien model dispatch navigateTo
        kategorienMobile model dispatch navigateTo
        Fulma.Column.column [
            Fulma.Column.Width(Fulma.Screen.Desktop, Fulma.Column.Is8)
            Fulma.Column.Width(Fulma.Screen.Touch, Fulma.Column.Is12)
        ] [
            filesBox model dispatch
            paginationR model.Files (fun p -> navigateTo (Page.Files(p, model.SelectedFilter)))
        ]
    ]
