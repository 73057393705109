module Types

open Bfs.Web.Data.Service.Contracts.Events
open Fable.React

type AnyPage = obj
type AnyWebPartModel = obj
type AnyWebPartMsg = obj

type Remote<'a> =
    | Empty
    | Loading
    | LoadError of string
    | Body of 'a

type GlobalMessageType =
    | SuccessBox
    | InfoBox
    | WarningBox
    | ErrorBox

type GlobalMessageBoxButtonType =
    | YesButton
    | NoButton
    | OkButton
    | CancelButton

type GlobalMessageBoxButton = {
    Type: GlobalMessageBoxButtonType
    Caption: string
    Message: AnyWebPartMsg option
    ClosesDialog: bool
}

let YesButton message = {
    Type = YesButton
    Caption = "Ja"
    Message = message
    ClosesDialog = true
}
let NoButton message = {
    Type = NoButton
    Caption = "Nein"
    Message = message
    ClosesDialog = true
}
let OkButton message = {
    Type = OkButton
    Caption = "OK"
    Message = message
    ClosesDialog = true
}
let CanelButton message = {
    Type = CancelButton
    Caption = "Abbrechen"
    Message = message
    ClosesDialog = true
}

type Video =
    | Vimeo of string
    | Adventr of string

type GlobalMessageBox = {
    Type: GlobalMessageType
    Title: string
    BodyElement: ReactElement
    Buttons: GlobalMessageBoxButton list
} with

    static member private messagesToContentElement messages = messages |> List.map str |> ofList
    static member private messageToContentElement message = str message

    static member Error message =
        let content = GlobalMessageBox.messageToContentElement message
        {
            Type = ErrorBox
            Title = "Es ist ein Fehler aufgetreten"
            BodyElement = content
            Buttons = [ OkButton None ]
        }
    static member ErrorWithTitle title message =
        let content = GlobalMessageBox.messageToContentElement message
        {
            Type = ErrorBox
            Title = title
            BodyElement = content
            Buttons = [ OkButton None ]
        }
    static member Errors messages =
        let content = GlobalMessageBox.messagesToContentElement messages
        {
            Type = ErrorBox
            Title = "Es ist ein Fehler aufgetreten"
            BodyElement = content
            Buttons = [ OkButton None ]
        }
    static member Warning message =
        let content = GlobalMessageBox.messageToContentElement message
        {
            Type = WarningBox
            Title = "Bitte beachten Sie folgende Warnung"
            BodyElement = content
            Buttons = [ OkButton None ]
        }
    static member WarningWithTitle title message =
        let content = GlobalMessageBox.messageToContentElement message
        {
            Type = WarningBox
            Title = title
            BodyElement = content
            Buttons = [ OkButton None ]
        }
    static member Warnings messages =
        let content = GlobalMessageBox.messagesToContentElement messages
        {
            Type = WarningBox
            Title = "Bitte beachten Sie folgenden Warnungen"
            BodyElement = content
            Buttons = [ OkButton None ]
        }
    static member Info message =
        let content = GlobalMessageBox.messageToContentElement message
        {
            Type = InfoBox
            Title = "Information"
            BodyElement = content
            Buttons = [ OkButton None ]
        }
    static member HtmlInfoWithTitle title content = {
        Type = InfoBox
        Title = title
        BodyElement = content
        Buttons = [ OkButton None ]
    }
    static member InfoWithTitle title message =
        let content = GlobalMessageBox.messageToContentElement message
        {
            Type = InfoBox
            Title = title
            BodyElement = content
            Buttons = [ OkButton None ]
        }
    static member Infos messages =
        let content = GlobalMessageBox.messagesToContentElement messages
        {
            Type = InfoBox
            Title = "Information"
            BodyElement = content
            Buttons = [ OkButton None ]
        }
    static member Success message =
        let content = GlobalMessageBox.messageToContentElement message
        {
            Type = SuccessBox
            Title = "Information"
            BodyElement = content
            Buttons = [ OkButton None ]
        }
    static member SuccessWithTitle title message =
        let content = GlobalMessageBox.messageToContentElement message
        {
            Type = SuccessBox
            Title = title
            BodyElement = content
            Buttons = [ OkButton None ]
        }
    static member Successes messages =
        let content = GlobalMessageBox.messagesToContentElement messages
        {
            Type = SuccessBox
            Title = "Information"
            BodyElement = content
            Buttons = [ OkButton None ]
        }
    static member ConfirmationBox title message yesMsg = {
        Type = WarningBox
        Title = title
        BodyElement = GlobalMessageBox.messageToContentElement message
        Buttons = [ YesButton yesMsg; NoButton None ]
    }

type UserSession = {
    Name: string
    Email: string
    Roles: string list
    IsNewCustomer: bool

    SessionData: Map<string, string>
}

type EventMsg =
    | EasterEggFound of EasterEgg * (AnyWebPartMsg option)
    | EasterEggFoundSaved of Result<Fetch.Types.Response, exn> * (AnyWebPartMsg option)

type GlobalMsg =
    | LoginValidated of newUserSession: UserSession * gotoUrl: string option
    | Logoff
    | LogoffValidated of Result<Fetch.Types.Response, exn>
    | ShowMessageBox of GlobalMessageBox
    | ClearMessageBox
    | ShowVideo of Video
    | ClearVideo
    | UpdateUserSessionData of key: string * value: string
    | EventMsg of EventMsg

type Msg =
    | GlobalMsg of GlobalMsg

    | WebPartMsg of AnyWebPartMsg

    | UrlUpdated of AnyPage
    | NavigateTo of AnyPage
    | NavigateHome

    | ToggleBurgerMenu
    | HandleChatWidget of UserSession

type Model = {
    Page: AnyPage
    PageModel: AnyWebPartModel

    User: UserSession option

    VideoOverlayVimeoId: Video option
    MessageBox: GlobalMessageBox option

    IsBurgerMenuOpen: bool
}

type PageHeader =
    | NoHeader
    | Header of string
    | HeaderWithSubheader of string * string
