﻿module Bfs.Web.Kundenportal.WebParts.User.Dashboard.Banner

open Bfs.Web.Data.Service.Contracts.Kundenportal.Banner

open Fable.React
open Elmish

open Fable.React.Props
open Http

open Shared.Banner
open Types

module Http =
    let loadBanner () = fetchAs<Banner list> "api/banner"

type Model = {
    GlobalDispatch: GlobalMsg -> unit
    Banner: Banner list
}

type Msg =
    | LoadBanner
    | LoadBannerCompleted of Result<Banner list, exn>

module State =
    let init globalDispatch =
        {
            GlobalDispatch = globalDispatch
            Banner = []
        },
        LoadBanner |> Cmd.ofMsg

    let update msg model : Model * Cmd<Msg> =
        match msg with
        | LoadBanner -> model, LoadBannerCompleted |> request Http.loadBanner ()
        | LoadBannerCompleted(Error ex) ->
            Browser.Dom.console.error ex
            model, Cmd.none
        | LoadBannerCompleted(Ok data) -> { model with Banner = data }, Cmd.none

let Bannerlist =
    FunctionComponent.Of(fun (globalDispatch, (navigateTo: AnyPage -> unit), (userSession: UserSession option)) ->
        let model, dispatch = React.useElmish ((State.init globalDispatch), State.update)

        model.Banner
        |> List.map (fun banner ->
            div [ Prop.Key(banner.Id.ToString()) ] [
                bfsBanner {
                    GlobalDispatch = globalDispatch
                    NavigateTo = navigateTo
                    UserSession = userSession
                    Id = banner.Id.ToString() |> Some
                    Tag = banner.Tag
                    Title = banner.Titel
                    Text = (Fable.Formatting.Markdown.Markdown.ToHtml banner.Text)
                    HasImageDesktop = banner.HasImageDesktop
                    HasImageMobile = banner.HasImageMobile
                    ImageOnly =
                        banner.ImageOnly
                        && banner.HasImageDesktop
                        && banner.HasImageMobile
                    BackgroundColor = banner.HintergrundFarbe
                    TextColor = banner.TextFarbe
                    UpdateTrigger = 0
                    PreviewMode = PreviewMode.NoPreview
                }
            ])
        |> ofList)
