module Dashboard.EventBannerEaster

open Bfs.Web.Data.Service.Contracts.Kundenportal.Dashboard

open Fable.React
open Fable.React.Props
open Elmish

open System
open Feliz
open Http

open Dashboard.Shared
open Types
open Validation
open ViewParts

type Model = { GlobalDispatch: GlobalMsg -> unit }

type Msg = | DoSmth

module State =
    let SESSION_DATA_KEY_INFO_BOX_ALREADYSEEN = "EasterEvent2024InfoBoxAlreadySeen"

    let init (props: DashboardProps) =
        {
            GlobalDispatch = props.GlobalDispatch
        },
        Cmd.none

    let update msg model : Model * Cmd<Msg> =
        match msg with
        | _ -> model, Cmd.none

let view =
    FunctionComponent.Of(fun (props: DashboardProps, userSession, ostergewinnspielActive) ->
        let model, dispatch = React.useElmish ((State.init props), State.update)

        [
            if ostergewinnspielActive then
                div [ Class "easter-2024" ] [
                    (NotificationTile.view {
                        GlobalDispatch = props.GlobalDispatch
                        UniqueSessionKey = State.SESSION_DATA_KEY_INFO_BOX_ALREADYSEEN
                        Flag = "News"
                        Title = "JETZT MITSPIELEN!"
                        Body =
                            (div [ Class "content" ] [
                                str "Wir haben "
                                (b [] [ str "3 BFS-Ostereier" ])
                                str
                                    " in unserem Kundenportal versteckt: Sammeln Sie die Eier per Mausklick ein und machen Sie mit beim Ostergewinnspiel! Ihre gesammelten Eier erscheinen in Ihrem "
                                (b [] [ str "Osternest" ])
                                str " hier im "
                                (b [] [ str "Dashboard" ])
                                str
                                    ". Wenn Sie alle 3 BFS-Ostereier gefunden haben, können Sie über einen Link in Ihrem Osternest am Gewinnspiel teilnehmen. Viel Glück!"
                            ])
                        Session = userSession
                    })
                ]
        ]
        |> ofList)
