module Kundenportal.Menu

open Bfs.Web.Kundenportal.WebParts.User.DashBoardPage
open Types
open MenuDef
open Bfs.Web.Data.Service.Contracts.Kundenportal

open ViewParts

let private menuHomeForKunden = [
    menuItemWithIcon<Dashboard.Types.Model> "Startseite" Icons.Home Roles.Kunde (DashboardPage.Dashboard) []
]
let private menuNewsForKunden = [
    menuItem<News.Types.Model> "News" Roles.Kunde (News.Types.Page.AllNews 1) []
]
let private menuAdditionalTopForKunden = [
    menuItem<Faq.Types.Model> "FAQ" Roles.Kunde Faq.Types.Page.Faq []
    menuItem<Help.Types.Model> "Kontakt" Roles.Kunde Help.Types.Page.Kontakt []
]
let private menuForKunden user = [
    menuItem<Dta.Types.Model> "Datenübergabe" Roles.KundeDtaVerfahren (Dta.Types.Page.DtaList 1) []
    if user.Roles |> List.contains Roles.KundeMitSendungenAnzeigen then
        menuItem<Sendungen.Types.Model> "Sendungen" Roles.Kunde (Sendungen.Types.Page.Sendungen None) []
    menuItem<Files.Types.Model> "Dokumente" Roles.Kunde (Files.Types.Page.Files(1, Alle)) []
    menuItem<Verrechnungen.Types.Model> "Verrechnungen" Roles.Kunde (Verrechnungen.Types.Page.Verrechnungen None) []
]

let private helpForKunden user forMobile = [
    menuItem<string> "Hilfe" Roles.Kunde null [
        menuItem<Tickets.Types.Model> "Tickets" Roles.Kunde (Tickets.Types.Page.Ticket None) []
        menuItem<Quickfinder.Types.Model> "Quick Finder" Roles.Kunde (Quickfinder.Types.Page.RechnungQuickfinder "") []
        menuItem<Videos.Types.Model> "Video" Roles.Kunde (Videos.Types.Page.Videos) []
        if forMobile then
            yield! menuAdditionalTopForKunden
    ]
]

let private menuSettingsForKunden (user: UserSession) = [
    menuItemWithIcon2<Usermanagement.Types.Model, Settings.Types.Model> "Administration" Icons.Settings "" null [
        menuItem<Usermanagement.Types.Model>
            "Benutzerverwaltung"
            Roles.KundeAdministrator
            Usermanagement.Types.Page.UserList
            []

        menuItem<Settings.Types.Model> "Einstellungen" Roles.Kunde Settings.Types.Page.Settings []

        if user.Roles |> List.contains Roles.KundeMitApiToken then
            menuItem<ApiToken.Types.Model> "API-Token" Roles.KundeAdministrator ApiToken.Types.Page.ApiToken []
    ]
]

let private menuForAdmins = [
    menuItem<AdminNews.Types.Model> "News" Roles.AdminNews (AdminNews.Types.Page.NewsList 1) []
    menuItem<AdminBanner.Types.Model> "Banner" Roles.Admin (AdminBanner.Types.Page.BannerList 1) []
    menuItem<AdminFile.Types.Model> "Dokumente" Roles.AdminFiles (AdminFile.Types.Page.List(1, None, None)) []
    menuItem<AdminFaq.Types.Model> "FAQ" Roles.AdminFaq (AdminFaq.Types.Page.Faq) []

    menuItem2<Admin.Types.Model, AdminText.Types.Model> "Admin" Roles.Admin null [
        menuItem<Admin.Types.Model> "Info" Roles.Admin Admin.Types.Page.VersionInfo []
        menuItem<AdminText.Types.Model> "Texte" Roles.Admin (AdminText.Types.Page.TextList) []
    ]
]

let topMenu = menuNewsForKunden @ menuAdditionalTopForKunden

let mainMenu user =
    menuHomeForKunden
    @ menuForKunden user
    @ helpForKunden user false
    @ menuSettingsForKunden user
    @ menuForAdmins

let mainMenuMobile
    user // fasst alles aus topMenu und mainMenu zusammen
    =
    menuHomeForKunden
    @ menuNewsForKunden
    @ [
        menuItem<string> "Meine Unterlagen" Roles.Kunde null [ yield! menuForKunden user ]
    ]
    @ helpForKunden user true
    @ menuSettingsForKunden user
    @ menuForAdmins

let userMenu dispatch =

    let logout () = Logoff |> GlobalMsg |> dispatch

    [
        // diese Menüpunkte sollen NIE highlighted sein, daher muss der Type in <string>
        // irgendetwas sein, was nie vorkommen wird. String passt da.
        menuItem<string> "Passwort ändern" Roles.Admin (Profile.Types.Page.PasswordChange) []
        menuItem<string> "Passwort ändern" Roles.Kunde (Profile.Types.Page.PasswordChange) []
        menuItem<string>
            "Benachrichtigungen"
            Roles.Kunde
            (Bfs.Web.Kundenportal.WebParts.Notifications.Types.Page.Notifications)
            []
        menuItemClickable "Abmelden" Roles.Admin logout
        menuItemClickable "Abmelden" Roles.Kunde logout
    ]

let startpageByRole = [
    (Roles.AdminNews, (AdminNews.Types.Page.NewsList 1) :> AnyPage)
    (Roles.AdminFiles, (AdminFile.Types.Page.List(1, None, None)) :> AnyPage)
    (Roles.AdminFaq, (AdminFaq.Types.Page.Faq) :> AnyPage)
    (Roles.Admin, (Admin.Types.Page.VersionInfo) :> AnyPage)
    (Roles.Kunde, (DashboardPage.Dashboard) :> AnyPage)
    (Roles.KundeDtaVerfahren, (Home.Types.Page.LandingPage) :> AnyPage)
]
