module Dashboard.DokumentenDepot

open Bfs.Web.Data.Service.Contracts.Kundenportal.Dashboard
open Bfs.Web.Kundenportal.WebParts.User.Shared.Tile

open Fable.React
open Fable.React.Props
open Elmish

open Types
open Http
open ViewParts

open Dashboard.Shared

open Bfs.Web.Shared.Formating

type Model = {
    GlobalDispatch: GlobalMsg -> unit
    NavigateTo: AnyPage -> unit
    Loading: bool
    Data: DokumentenDepot
}

type Msg =
    | LoadData
    | LoadDataCompleted of Result<DokumentenDepot, exn>
    | NavigateToList

module Http =
    let loadFiles () = fetchAs<DokumentenDepot> "/api/dashboard/dokumentendepot"
    let setNotificationForNeueDokumente enabled =
        let payload = { Enabled = enabled }
        postRecord<ToggleNotificationRequest> "/api/dashboard/set-notification-for-neue-dokumente" payload

module State =
    let init (props: DashboardProps) =
        {
            GlobalDispatch = props.GlobalDispatch
            NavigateTo = props.NavigateTo
            Loading = true
            Data = {
                Absetzungen = 0
                NichtAnkaeufe = 0
                Einlagerungen = 0
                NaechsteRuecksendung = None
            }
        },
        Cmd.ofMsg LoadData

    let update (msg: Msg) (model: Model) : Model * Cmd<Msg> =
        match msg with
        | LoadData ->
            let cmd = LoadDataCompleted |> request Http.loadFiles ()
            { model with Loading = false }, cmd
        | LoadDataCompleted(Ok data) ->
            {
                model with
                    Loading = false
                    Data = data
            },
            Cmd.none
        | LoadDataCompleted(Error _) ->
            let msg =
                GlobalMessageBox.Error "Die Daten für das Dokumentendepot können zur Zeit leider nicht geladen werden."
                |> ShowMessageBox
            model.GlobalDispatch msg
            { model with Loading = false }, Cmd.none
        | NavigateToList ->
            model.NavigateTo DokumentenDepot.Types.Page.List
            model, Cmd.none

let renderItem description secondLine value isDark =
    let color =
        match isDark with
        | true -> "is-dark"
        | false -> "is-light"

    div [ Class "item" ] [
        div [
            Class $"tag is-rounded {color} is-size-6 has-text-weight-bold"
        ] [ str value ]
        div [
            Class "is-size-7 has-text-grey-light has-text-centered"
        ] [ str description ]
        div [
            Class "is-size-7 has-text-grey-light has-text-centered"
        ] [ str secondLine ]
    ]

let View =
    FunctionComponent.Of(fun (props: DashboardProps) ->
        let model, dispatch = React.useElmish ((State.init props), State.update)
        let title = str "Dokumenten Depot"
        let titleAction =
            Some(
                a [
                    OnClick(fun _ -> NavigateToList |> dispatch)
                ] [ navigationArrow ]
            )
        let body =
            match model.Loading with
            | true -> spinner
            | false ->
                let ruecksendung =
                    match model.Data.NaechsteRuecksendung with
                    | None -> "-"
                    | Some date -> date |> asString
                div [ Class "dokumentendepot" ] [
                    renderItem "Absetzungen" "" (model.Data.Absetzungen.ToString()) false
                    renderItem "Einlagerungen" "" (model.Data.Einlagerungen.ToString()) false
                    renderItem "Nicht angekaufte" "Rechnungen" (model.Data.NichtAnkaeufe.ToString()) false
                    renderItem "Nächste" "Rückssendung" ruecksendung true
                ]

        bfsTile title titleAction None body None

    )
