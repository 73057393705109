module StaticPages.Types

type Page =
    | Datenschutz
    | Impressum

type Msg = Load of Page

type Model = {
    Page: Page
} with
    member model.Headline =
        match model.Page with
        | Datenschutz -> "Datenschutz"
        | Impressum -> "Impressum"
