module Usermanagement.View

open Fable.React
open Types
open Bfs.Web.Data.Service.Contracts.Kundenportal.UserManagement
open Bfs.Web.Data.Service.Contracts.Kundenportal.Roles
open Fable.React.Props
open ViewParts


let rolesAsTagsDesktop roles =
    roles
    |> Seq.map (fun r ->
        span [
            Class "tag is-light tooltip is-tooltip-multiline"
            Data("tooltip", r.Beschreibung)
        ] [ str r.Bezeichnung ])

let rolesAsTagsMobile roles =
    roles
    |> Seq.map (fun r -> span [ Class "tag is-light" ] [ str r.Bezeichnung ])

let actionHoverMenu dispatch navigateTo (user: UserInfo) =
    let menuName = sprintf "dropdown-%i" user.KundenportalBenutzerId
    div [
        Class "dropdown is-hoverable is-right is-up"
    ] [
        div [ Class "dropdown-trigger" ] [
            div [
                AriaHasPopup true
                AriaControls menuName
            ] [ icon "fas fa-ellipsis-v" ]
        ]
        div [
            Class "dropdown-menu"
            Id menuName
            Role "menu"
        ] [
            div [ Class "dropdown-content" ] [
                p [
                    Class "dropdown-item"
                    Style [
                        FontWeight "bold"
                        TextAlign TextAlignOptions.Center
                    ]
                ] [ str user.Name ]
                hr [ Class "dropdown-divider" ]
                a [
                    Class "dropdown-item"
                    OnClick(fun _ -> (ResetPassword user) |> dispatch)
                ] [ str "Passwort zurücksetzen" ]
                a [
                    Class "dropdown-item"
                    OnClick(fun _ -> (EditUserRoles user) |> dispatch)
                ] [ str "Benutzer bearbeiten" ]

                if not user.IsCurrentUser then
                    if user.Gesperrt then
                        a [
                            Class "dropdown-item"
                            OnClick(fun _ -> (EnableUser user.KundenportalBenutzerId) |> dispatch)
                        ] [ str "Benutzer entsperren" ]
                    else
                        a [
                            Class "dropdown-item"
                            OnClick(fun _ -> (DisableUser user.KundenportalBenutzerId) |> dispatch)
                        ] [ str "Benutzer sperren" ]

                    a [
                        Class "dropdown-item"
                        OnClick(fun _ -> (DeleteUser user) |> dispatch)
                    ] [ str "Benutzer löschen" ]
            ]
        ]
    ]



let tableMobile (users: UserInfo list) dispatch navigateTo =
    div [ Class "data-list is-hidden-desktop" ] [
        users
        |> (List.map (fun (u: UserInfo) ->
            div [ Class "data-item" ] [
                div [] [
                    div [ Class "dta-file" ] [ str u.Name ]
                    if u.Nutzt2Fa then
                        span [ Class "tag is-success" ] [ str "2-Faktor-Login" ]
                    if u.Gesperrt then
                        span [ Class "tag is-danger" ] [ str "Gesperrt" ]
                    div [ Class "dta-date" ] [
                        match u.LetzterLogin with
                        | None -> str "noch kein Login"
                        | Some d -> str (sprintf "Login: %s" (d.Date.ToString("dd.MM.yyyy")))
                    ]
                ]
                div [ Class "dta-status" ] [
                    div [ Class "word-break" ] [
                        str (sprintf "Benutzername: %s" u.Login)
                    ]
                    div [ Style [ MarginTop "5px" ] ] (rolesAsTagsMobile u.BenutzerverwalteteRollen)
                    div [ Class "pull-right" ] [
                        actionHoverMenu dispatch navigateTo u
                    ]
                ]
            ]))
        |> ofList
    ]


let tableRow dispatch navigateTo (user: UserInfo) =
    tr [ Class "" ] [
        td [] [
            div [ Class "word-break" ] [ str user.Login ]
            if user.Nutzt2Fa then
                span [ Class "tag is-success" ] [ str "2-Faktor-Login" ]
            if user.Gesperrt then
                span [ Class "tag is-danger" ] [ str "Gesperrt" ]
        ]
        td [] [ str user.Name ]
        td [] [
            match user.LetzterLogin with
            | None -> str "noch kein Login"
            | Some d -> str (d.Date.ToString("dd.MM.yyyy"))
        ]
        td [] (rolesAsTagsDesktop user.BenutzerverwalteteRollen)
        td [ Class "pull-right" ] [
            actionHoverMenu dispatch navigateTo user
        ]
    ]

let table users dispatch navigateTo =
    table [
        Class "table table-striped responsive-table is-hidden-touch"
    ] [
        thead [] [
            tr [] [
                th [] [ str "Login" ]
                th [] [ str "Name" ]
                th [] [ str "Letzter Login" ]
                th [] [ str "Rechte" ]
                th [] []
            ]
        ]
        tbody [] (users |> List.map (tableRow dispatch navigateTo))
    ]

let listPage users dispatch (navigateTo: AnyPage -> unit) =
    div [ Class "columns is-multiline" ] [
        div [ Class "column is-12" ] [
            div [
                Class "flat-card profile-info-card is-auto overflow-initial"
            ] [
                div [ Class "card-body" ] [
                    p [ Style [ Margin "0.8rem" ] ] [
                        str
                            "Legen Sie hier unterschiedliche Nutzer für das Kundenportal an und ordnen Sie anschließend diesen entsprechende Rechte zu. Benötigt werden hierfür der Name und eine E-Mail-Adresse. Bitte beachten Sie, dass jeder Nutzer eine individuelle Mailadresse benötigt."
                    ]

                    div [ Class "new-button-contailer" ] [
                        button [
                            Class "bfsbutton  is-small"
                            OnClick(fun _ -> CreateNewUser |> dispatch)
                        ] [ str "Neu" ]
                    ]

                    match users with
                    | Empty -> div [] []
                    | Loading -> spinner
                    | LoadError _ -> errorMsg "Fehler beim Laden der Benutzer!"
                    | Body x when x = [] -> div [] [ str "Keine Benutzer vorhanden" ]
                    | Body users ->
                        table users dispatch navigateTo
                        tableMobile users dispatch navigateTo
                ]
            ]
        ]
    ]


// Edit-Modal-Fenster
let rolesBlock (model: Types.UserEditModel) (dispatch: Types.EditUserDialogMsg -> unit) =
    let addableRoles =
        match model.AllUsermanagedRoles with
        | Body roles -> roles |> List.except model.UserRoles
        | _ -> []

    div [ Class "field" ] [
        label [ Class "label" ] [ str "Rechte" ]

        div [ Class "buttons" ] [
            div [ Class "block" ] [
                model.UserRoles
                |> List.map (fun r ->
                    span [
                        Class "tag is-light tooltip is-tooltip-multiline"
                        Data("tooltip", r.Beschreibung)
                    ] [
                        str r.Bezeichnung
                        if
                            not model.IsCurrentUser
                            || r.KundenportalRolleId <> (int) RoleType.KundeAdministrator
                        then
                            button [
                                Class "delete is-small"
                                OnClick(fun _ -> ToggleRole r |> dispatch)
                            ] []
                    ])
                |> ofList

                if (addableRoles.Length > 0) then
                    div [ Class "dropdown is-hoverable" ] [
                        div [ Class "dropdown-trigger" ] [
                            span [
                                Class "tag"
                                AriaHasPopup true
                                AriaControls "dropdown-roles"
                            ] [ icon "fas fa-plus" ]
                        ]
                        div [
                            Class "dropdown-menu"
                            Id "dropdown-roles"
                            Role "menu"
                        ] [
                            div [ Class "dropdown-content" ] [
                                match model.AllUsermanagedRoles with
                                | Empty -> str "Keine verwaltbaren Rollen gefunden"
                                | Loading -> spinner
                                | LoadError err -> str (sprintf "Fehler beim Laden der verwalbaren Rollen: %s" err)
                                | Body _ ->
                                    addableRoles
                                    |> List.map (fun r ->
                                        a [
                                            Class "dropdown-item  tooltip is-tooltip-multiline is-tooltip-right"
                                            Data("tooltip", r.Beschreibung)
                                            OnClick(fun _ -> ToggleRole r |> dispatch)
                                        ] [ str r.Bezeichnung ])
                                    |> ofList
                            ]
                        ]
                    ]
            ]
        ]
    ]


let userDialog (model: Types.UserEditModel) (dispatch: Types.EditUserDialogMsg -> unit) =
    div [ Class "modal is-active" ] [
        div [ Class "modal-background" ] []
        div [
            Class "modal-content overflow-initial"
        ] [
            header [ Class "modal-card-head" ] [
                p [ Class "modal-card-title" ] [
                    match model.Id with
                    | None -> str "Benutzer anlegen"
                    | Some _ -> str "Benutzer bearbeiten"
                ]
            ]
            section [
                Class "modal-card-body overflow-initial"
            ] [
                div [ Class "info-Block" ] [
                    match model.Id with
                    | None ->
                        yield
                            validatedTextBox
                                (dispatch << EmailChanged)
                                "E-Mail-Adresse (zugleich Login)"
                                "email"
                                ""
                                model.Email
                        yield validatedTextBox (dispatch << NameChanged) "Name" "name" "" model.Name
                    | Some _ when model.Login.Value = model.Email.Value ->
                        yield
                            validatedTextBox
                                (dispatch << EmailChanged)
                                "E-Mail-Adresse (zugleich Login)"
                                "email"
                                ""
                                model.Email
                        yield validatedTextBox (dispatch << NameChanged) "Name" "name" "" model.Name
                    | Some _ ->
                        yield
                            validatedTextBoxWithAttributes
                                [ Disabled true ]
                                (dispatch << LoginChanged)
                                "Login"
                                "login"
                                ""
                                model.Login
                        yield validatedTextBox (dispatch << EmailChanged) "E-Mail-Adresse" "email" "" model.Email
                        yield validatedTextBox (dispatch << NameChanged) "Name" "name" "" model.Name

                    yield rolesBlock model dispatch
                ]
            ]
            footer [ Class "modal-card-foot" ] [
                button [
                    Class "bfsbutton "
                    Disabled(not (model.CanSubmit))
                    OnClick(fun _ -> Save |> dispatch)
                ] [ str "Speichern" ]
                button [
                    Class "button"
                    OnClick(fun _ -> Cancel |> dispatch)
                ] [ str "Abbrechen" ]
            ]
        ]
    ]


// Gesamtview - zusammengesetzt aus einzelnen Views für die Liste und  Neuanlage/Editieren
let view (model: Types.Model) dispatch (navigateTo: AnyPage -> unit) =
    div [ Class "usermanagement" ] [
        listPage model.Users dispatch navigateTo

        if model.ActiveUserDialog.IsSome then
            let user = model.ActiveUserDialog.Value
            userDialog user (Types.DialogMsg >> dispatch)
    ]
