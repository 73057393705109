namespace Bfs.Web.Data.Service.Contracts.Kundenportal

open System

// Nicht in Modul, da zu allgemein.
type KundenTyp =
    | Pflege = 1
    | Therapie = 2
    | BfsPlus = 4
    | Jugendhilfe = 5

// Module
module Auth =

    [<Literal>]
    let Scheme = "kundenportalscheme"

#if !FABLE_COMPILER
    type BfsKundenportalAuthorizeAttribute =
        inherit Microsoft.AspNetCore.Authorization.AuthorizeAttribute
        new() =
            {
                inherit Microsoft.AspNetCore.Authorization.AuthorizeAttribute(AuthenticationSchemes = Scheme)
            }
        new(role: string) =
            {
                inherit
                    Microsoft.AspNetCore.Authorization.AuthorizeAttribute(Roles = role, AuthenticationSchemes = Scheme)
            }
        new(roles: string array) =
            let rolesJoined = String.Join(",", roles)
            {
                inherit
                    Microsoft.AspNetCore.Authorization.AuthorizeAttribute(
                        Roles = rolesJoined,
                        AuthenticationSchemes = Scheme
                    )
            }
#endif

module Roles =
    [<Literal>]
    let Chat = "Chat" // mit dieser Rolle wird das Userlike Chat-Widget angezeigt

    [<Literal>]
    let Chatbot = "Chatbot" // der Chatbot bekommt ein Token mit nur dieser Rolle um sich zu Authentifizieren

    [<Literal>]
    let Admin = "Admin"
    [<Literal>]
    let AdminFaq = "AdminFaq"
    [<Literal>]
    let AdminFiles = "AdminFiles"
    [<Literal>]
    let AdminNews = "AdminNews"
    [<Literal>]
    let Kunde = "Kunde"
    [<Literal>]
    let KundeDtaVerfahren = "KundeDtaVerfahren"

    [<Literal>]
    let KundeAdministrator = "KundeAdministrator"
    [<Literal>]
    let KundeKostenpflichtigBuchen = "KundeKostenpflichtigBuchen"

    // Diese Rollen stehen nicht als Rolle in der DB, sondern kommen aus anderen Stellen
    // im Frontend werden sie aber alle als Rolle interpretiert
    [<Literal>]
    let KundeMitApiToken = "KundeMitApiToken"
    [<Literal>]
    let KundeMitDokumentenabruf = "KundeMitDokumentenabruf"
    [<Literal>]
    let KundeMitSendungenAnzeigen = "KundeMitSendungenAnzeigen"
    [<Literal>]
    let KundeMitPreisInfosAnzeigen = "KundeMitPreisInfosAnzeigen"
    [<Literal>]
    let KundeMitPwa = "KundeMitPwa"

    let adminRoles = [
        Admin
        AdminFaq
        AdminFiles
        AdminNews
    ]
    let kundeRoles = [
        Kunde
        KundeAdministrator
        KundeDtaVerfahren
        KundeKostenpflichtigBuchen
        KundeMitApiToken
        KundeMitDokumentenabruf
        KundeMitSendungenAnzeigen
        KundeMitPreisInfosAnzeigen
        KundeMitPwa
    ]

    let IsSomeAdmin (roles: string list) =
        roles
        |> List.exists (fun r -> adminRoles |> List.contains r)

    let IsSomeKunde (roles: string list) =
        roles
        |> List.exists (fun r -> kundeRoles |> List.contains r)

    let shouldBeInKundeMitApiTokenRole kundenTypId = kundenTypId = Some(int KundenTyp.Jugendhilfe)

    // TODO prüfen ob wir nicht auch ohne die Ints auskommen und überall die Literale nutzen können
    //      Doppelte Buchführung ist doch auch doof
    type RoleType =
        | Admin = 1
        | Kunde = 2
        | AdminFaq = 4
        | AdminFiles = 5
        | AdminNews = 6
        | KundeAdministrator = 8
        | KundeDtaVerfahren = 10
        | KundeKostenpflichtigBuchen = 15


module News =
    // Kein Kundentyp, IsActive, statt Text nur Textanfang
    type NewsListItem = {
        Id: Guid
        Datum: DateTime
        Titel: string
        Textanfang: string
        HasImage: bool
        IsNew: bool
    }

    // Die Ansicht, die ein Nutzer bekommt, wenn er eine Nachricht anschaut. Kein Kundentyp, IsActive
    type News = {
        Titel: string
        Html: string
        Datum: DateTime
        HasImage: bool
        IsNew: bool
    }

    // Liste im Dashboard
    type LatestNewsListItem = {
        Id: Guid
        Titel: string
        Textanfang: string
    }

    type AdminNews = {
        Titel: string
        Text: string
        GueltigAb: DateTime
        Aktiv: Boolean
        KundenTyp: KundenTyp option
        Kundennummern: int list
    }

    type AdminNewsListItem = {
        Id: Guid
        Titel: string
        Datum: DateTime
        Aktiv: Boolean
        AnzeigeConstraint: string
    }

module Banner =
    type DesktopImageUploaded = {
        HasImage: bool
        HintergrundFarbe: string
    }

    type AdminBannerListItem = {
        Id: Guid
        Titel: string
        GueltigAb: DateTime
        GueltigBis: DateTime
        Aktiv: Boolean
    }
    type AdminBanner = {
        Titel: string
        Tag: string
        Text: string
        HasImageDesktop: bool
        HasImageMobile: bool
        HintergrundFarbe: string
        TextFarbe: string
        KundenTyp: KundenTyp option
        GueltigAb: DateTime
        GueltigBis: DateTime
        Aktiv: bool
        ImageOnly: bool
    }

    type Banner = {
        Id: Guid
        Titel: string
        Tag: string
        Text: string
        HasImageDesktop: bool
        HasImageMobile: bool
        ImageOnly: bool
        HintergrundFarbe: string
        TextFarbe: string
    }

module Account =
    type LoginData = {
        Username: string
        Password: string
        OneTimePassword: string
        NutzungsbedingungenAkzeptiert: bool option
        Persist: bool
    }

    type UserNotifications = {
        NotificationForBelegeingang: bool
        NotificationForBelegpruefungAbgeschlossen: bool
        NotificationForAuszahlungGetaetigt: bool
        NotificationForPostImPortal: bool
        NotificationForPreisInfos: bool
        NotificationForTicketAntwort: bool
    }

    type UserInfo = {
        Kundennummer: int option
        OtpEnabeld: bool

        Notifications: UserNotifications
    }

    type OtpActivation = { Secret: string; QrDataUrl: string }

    type OtpConfirmation = { Secret: string; Otp: string }

    type ChangePassword = {
        Password: string
        NewPassword1: string
        NewPassword2: string
    }

    type ResetPassword = {
        Token: string
        NewPassword1: string
        NewPassword2: string
    }

    type LoginResult =
        | LoginOk
        | UserTemporaryLocked
        | OneTimePasswordMissing
        | NutzungsbedingungenAkzeptieren of string
        | Wartung of string
        | Unauthorized

    type UserStatus = {
        Id: string
        Name: string
        Email: string
        Kundennummer: int option
        IsNewCustomer: bool
        Roles: string list
        FirstName: string
        LastName: string
        SprinklrChatHash: string
        SprinklrAppId: string
        IsChatVisible: bool
    }


module File =
    type FileType = // enum
        | Csv = 1
        | Pdf = 2
        | File = 3

    type FileListItem = {
        Id: Guid
        Datum: DateTime
        Titel: string
        Beschreibung: string
        Filename: string
        Kategorie: string
        HasMetadata: bool
        Type: FileType
        IsNew: bool
    }

    type AdminFileListItem = {
        Id: Guid
        Datum: DateTime
        Titel: string
        Beschreibung: string
        Filename: string
        Kategorie: string
        HasMetadata: bool
        Type: FileType
        IsHidden: bool
        Kundennummer: int
    }


module Faq =
    type Faq = { Frage: string; AntwortHtml: string }

    type AdminFaqListItem = {
        Id: Guid
        Frage: string
        Position: int
        Aktiv: bool
        SichtbarFuerPflege: bool
        SichtbarFuerPflegeDtaPlus: bool
        SichtbarFuerTherapie: bool
        SichtbarFuerJugendhilfe: bool
    }

    type AdminFaqKategorieListItem = {
        Id: Guid
        Anzeigename: string
        Reihenfolge: int
        AnzahlFragen: int
    }

    type AdminFaq = {
        Frage: string
        Antwort: string
        Position: int
        Aktiv: bool
        SichtbarFuerPflege: bool
        SichtbarFuerPflegeDtaPlus: bool
        SichtbarFuerTherapie: bool
        SichtbarFuerJugendhilfe: bool
        KundenportalFaqKategorieId: Guid
    }


module Dta =
    type DtaListItem = {
        Id: Guid
        Dateiname: string
        Hochgeladen: DateTime
        StatusTyp: int
        Statusmeldung: string
    }


module Help =
    type NeuesTicket = {
        Betreff: string
        Text: string
        Attachments: Guid list
        RechnungId: Guid option
    }

    type UserTicketListItem = {
        Nummer: int option
        Datum: DateTime
        IstErledigt: bool
        Betreff: string
        Text: string
        Antworten: string list
        Attachments: string option
        TicketId: Guid
    }

    type Ansprechpartner = {
        Ueberschrift: string
        Text: string
        Telefon: string
        ImageUrl: string
    }
    let sizeLimitMB = 3

module Admin =
    type AdminInfos<'T> = { Id: Guid; Item: 'T; HasImage: bool }

module Verrechnungen =
    type Verschiebungsstatus =
        | VerschiebungMoeglich = 1
        | ForderungTerminiert = 2
        | ForderungFaellig = 3
        | BereitsVerschoben = 4

    type Filiale = {
        FilialeId: Guid
        Name: string
        IsZentrale: bool
        Limit: decimal
    }

    type Ticket = {
        TicketId: Guid
        Datum: DateTime
        Betreff: string
        IstErledigt: bool
        HatAntworten: bool
    }

    type Verrechnung = {
        ForderungId: Guid
        Status: Verschiebungsstatus
        Faelligkeitsdatum: DateTime
        RechnugnsNr: string
        KlientVorname: string
        KlientNachname: string
        Absetzungsgrund: string
        Details: string
        Rechnungsbetrag: decimal
        Betrag: decimal
        RechnungId: Guid
        Tickets: Ticket[]
    }

    type VerschiebungResult =
        | VerschiebungNichtMoeglichError of string
        | VerschiebungNichtMoeglichLimitErreicht of string
        | VerschiebungOk of string

module UserManagement =
    type UserManagedRole = {
        KundenportalRolleId: int
        Name: string
        Bezeichnung: string
        Beschreibung: string
    }

    type UserCreationData = {
        Email: string
        Name: string
        ManagedRoles: UserManagedRole list
    }

    type UserInfo = {
        KundenportalBenutzerId: int
        Login: string
        Email: string
        Name: string
        BenutzerverwalteteRollen: UserManagedRole list
        Nutzt2Fa: bool
        LetzterLogin: DateTime option
        Gesperrt: bool
        IsCurrentUser: bool
    }

    type UpdateUserData = {
        KundenportalBenutzerId: int
        Login: string
        Name: string
        Email: string
        Roles: UserManagedRole list
    }

    type PasswordResetData = {
        KundenportalBenutzerId: int
        Email: string
    }

module Quickfinder =
    type DownloadType =
        | NoDownload
        | WithDownloadDokumentAnfrage of linkName: string * dokumentAnfrageId: Guid
        | WithDownloadSammelrechnung of linkName: string * sammelrechnungId: Guid
        | WithDownloadButFlatrateNeeded of linkName: string

    type Information = {
        Datum: DateTime
        Reihenfolge: int
        Icon: string
        Typ: string
        Text: string
        Hilfetext: string option
        Download: DownloadType
        RechnungId: Guid option
        TicketId: Guid option
    } with
        static member NoInformation = {
            Datum = DateTime(1, 1, 1)
            Reihenfolge = 0
            Icon = ""
            Typ = ""
            Text = ""
            Hilfetext = None
            Download = NoDownload
            RechnungId = None
            TicketId = None
        }

module Settings =

    type DokumentenabrufStatus =
        | Inaktiv
        | Probezeit
        | Gebuehrenpflichtig

    type DokumentenabrufSettings = {
        FilialeId: Guid
        Name1: string
        Name2: string
        GueltigBis: DateTime option // Bis wann läuft das gesamte Abo - ggf. auch über die aktuelle Phase hinaus.
        DokumentenabrufStatus: DokumentenabrufStatus // In welcher Phase befindet sich das Abo aktuell.
    }

    type Settings = {
        DokumentenabrufSettings: DokumentenabrufSettings[]
        PostInsKundenportalZustellen: bool
    }

    type ISettingsService = {
        GetSettings: unit -> Async<Settings>
        TryDokumentenabruf: Guid * bool -> Async<Result<string, string>>
        SetDokumentenabruf: Guid * bool -> Async<Result<string, string>>
        SetPostInsKundenportalZustellen: bool -> Async<bool>
    }

module ApiToken =

    type Token = {
        Id: int
        Erstellt: DateTime
        LetzteNutzung: DateTime option
    }

    type Filiale = {
        Id: Guid
        Name: string
        IsZentrale: bool
        Tokens: Token list
    }

    type CreateTokenResult = {
        Id: int
        FilialeId: Guid
        Value: string
        Erstellt: DateTime
    }

module Sendungen =

    type Filiale = {
        FilialeId: Guid
        Name: string
        IsZentrale: bool
    }

    type SendungsOption =
        | Beleglos
        | Mischsendung
        | DokumentenDepot

    type Sendung = {
        SendungId: Guid
        SendungsNr: int
        ErwartetesZahldatum: DateTime option
        Belegeingang: DateTime option
        Belegpruefung: DateTime option
        Gesamtbetrag: decimal option
        Angekauft: decimal option
        AllowDokumentenDepot: bool
        AllowUploads: bool
        AllowFlexitarif: bool
        SendungsOption: SendungsOption option
        TarifGewaehlt: bool
    }

    type SendungsOptionData = {
        SendungId: Guid
        SendungsOption: SendungsOption
        DateiIds: Guid list
    }

    type FlexitarifEntgelt = {
        Auszahlungstag: DateTime
        Entgelt: decimal
    }

    type Belegeingang =
        | Stored of DateTime
        | Calculated of DateTime
        member this.Value =
            match this with
            | Stored d -> d
            | Calculated d -> d

    type FlexitarifFuerSendung = {
        SendungId: Guid
        SendungsNr: int
        ForderungsVolumen: decimal
        EntgeltFuerVorziehung: decimal
        ZusatzgebuehrFuerBlitzueberweisung: decimal
        Belegeingang: Belegeingang
        BelegeingangErfasst: bool
        VereinbartesZahlungsziel: int
        KalkuliertesZahlungsdatum: DateTime
        AuszahlungstageMitEntgelt: FlexitarifEntgelt[]
        SicherheitsguthabenProzent: decimal
        SicherheitsguthabenBetrag: decimal
        Agb: string
    }

    type FlexitarifAuswahl = {
        AusfuehrungZahlung: DateTime
        Blitzueberweisung: bool
    }

    type FlexitarifResult = {
        SendungsNr: int
        AusfuehrungZahlung: DateTime
        Blitzueberweisung: bool
        Entgelt: decimal
        GebuehrBlitzueberweisung: decimal
    }

module Dashboard =
    type LetzteSendung = {
        SendungsNummer: int
        SendungsStatus: string
        Gesamtvolumen: decimal
        Angekauft: decimal option
        NichtAngekauft: decimal option
    }

    type MeineLetzteSendung = {
        LetzteSendung: LetzteSendung option
        Benachrichtigung: bool
    }

    type AnstehendeZahlung = {
        Betrag: decimal option
        Datum: DateTime option
        Benachrichtigung: bool
    }

    type LetzterBelegeingangSendung = {
        SendungsNummer: int
        Datum: DateTime
        Status: string
    }

    type LetzterBelegeingang = {
        Sendungen: LetzterBelegeingangSendung list
        Benachrichtigung: bool
    }

    type MeineZahlen = {
        Volumen: decimal
        Zahlungen: decimal
        Monat: string
        IstLetztesJahr: bool
    }

    type DigitaleKundenrechnung = {
        DigitaleKundenrechnungAktiviert: bool
    }

    type Chats = {
        ChatsAnzahl: int
        ChatsBewertung: decimal
        ChatsBewertungsMonat: string
    }

    type Tickets = {
        TicketsAnzahlAllTimesInsgesamt: int
        TicketsAnzahlAllTimesErledigt: int
        Monat: string
    }

    type DokumentenDepot = {
        Absetzungen: int
        NichtAnkaeufe: int
        Einlagerungen: int
        NaechsteRuecksendung: DateTime option
    }

    type NeueDokumente = { Benachrichtigung: bool }

    type ClickLogEntry = { Component: string; Object: string }

    type ToggleNotificationRequest = { Enabled: bool }

    type VertragBestaetigung = {
        VertragsNummer: string
        VertragsBeschreibung: string
        VertragsText: string
        VertragsTeaser: string
    }

    type VertragBestaetigungRequest = { VertragsNummer: string; Name: string }

    module Lists =

        type SendungBelegeingang = {
            SendungNr: int
            AnzahlBelege: int
            Status: string
            Zeitstempel: DateTime option
        }

        type SendungBelegeDetails = {
            KlientPatient: string
            RechnungsNrExtern: string
            LeistungsGrundlage: string
            Rechnungsbetrag: decimal
            Meldung: string
        }

        type SendungBelegeDetailsBlock = {
            SendungNr: int
            Rechnungen: SendungBelegeDetails list
        }

        type AnstehendeZahlungDetails = {
            RechnungsNrExtern: string
            KlientPatient: string
            LeistungsGrundlage: string
            Faelligkeitsdatum: DateTime
            AnstehenderBetrag: decimal
            Rechnungsbetrag: decimal
        }

        type AnstehendeZahlungDetailsBlock = {
            SendungNr: int
            Summe: decimal
            Faelligkeit: DateTime
            Rechnungen: AnstehendeZahlungDetails list
        }

        type DokumentenDepot = {
            SendungNr: int
            RechnungsNrExtern: string
            KlientPatient: string
            DokumentArt: string
            Grund: string
            EingelagertAm: DateTime
            RuecksendungAm: DateTime option
        }

module ChatBot =

    type Gender =
        | Male = 0
        | Female = 1

    type Filter = {
        SendungsNr: int option
        RechnungsNrExtern: string option
        AbrechnungVon: DateTime option
        AbrechnungBis: DateTime option
        Nachname: string option
        Vorname: string option
        Geburtsdatum: DateTime option
        Geschlecht: string option
        Rechnungsempfaenger: string option
    }

    type Field =
        | Vorname
        | Nachname
        | RechnungsNrExtern
        | AbrechnungVon
        | AbrechnungBis
        | Geschlecht
        | SendungsNr
        | Geburtsdatum
        | Rechnungsempfaenger
        | Unknown of string

    type RechnungCandidate = {
        Vorname: string
        Nachname: string
        RechnungsNrExtern: string
        AbrechnungVon: DateTime
        AbrechnungBis: DateTime
        Geschlecht: string
        SendungsNr: int
        Geburtsdatum: DateTime
        Rechnungsempfaenger: string
    }

    type BaseResult(resultType: string) =
        member val ResultType = resultType with get

    type NoResult(resultType: string) =
        inherit BaseResult(resultType)
        new() = NoResult(nameof NoResult)

    type SingleResult(resultType: string, result: obj) =
        inherit BaseResult(resultType)
        member val Result = result with get
        new(result) = SingleResult(nameof SingleResult, result)

    type MultipleResult(resultType: string, nextBestProperties: obj) =
        inherit BaseResult(resultType)
        member val Result = nextBestProperties with get
        new(nextBestProperties) = MultipleResult(nameof MultipleResult, nextBestProperties)

    type TokenResult = { ApiKey: string; Token: string }

module Events =

    type EasterStatus = {
        DashboardFound: bool
        TicketFound: bool
        QuickfinderFound: bool
        TeilgenommenAm: DateTime option
    }

    type EventSettings = { EasterEvent: EasterStatus option }

module Notifications =

    type Subscription = {
        Endpoint: string
        P256DH: string
        Auth: string
    }

    type EndpointIdentifier = { Endpoint: string }

    type MessageIdentifier = { Id: Guid }
