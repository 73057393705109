namespace Verrechnungen

open System
open Bfs.Web.Data.Service.Contracts.Kundenportal.Events
open Elmish
open Types
open Http
open Bfs.Web.Data.Service.Contracts.Kundenportal.Verrechnungen
open Bfs.Web.Data.Service.Contracts.Kundenportal

module Http =
    let getFilialen () = fetchAs<Filiale[]> "/api/verrechnungen/filialen"
    let getVerrechnugnen filialeId = fetchAs<Verrechnung[]> (sprintf "/api/verrechnungen/%O" filialeId)

    let tryMove (filialeId, (forderungIds: Guid list)) =
        postRecord2<Guid list, VerschiebungResult> (sprintf "/api/verrechnungen/%O/tryMove" filialeId) forderungIds
    let move (filialeId, (forderungIds: Guid list)) =
        postRecord2<Guid list, VerschiebungResult> (sprintf "/api/verrechnungen/%O/move" filialeId) forderungIds

    let createDtaReport filialeId = postEmpty (sprintf "/api/verrechnungen/%O/dta" filialeId)

module State =
    let private SESSION_DATA_KEY_INFO_BOX_HIDDEN = "VerrechnungenInfoBoxHidden"

    let init page user lastModel =
        let infoBoxHidden =
            user
            |> Option.map (fun u ->
                u.SessionData
                |> Map.containsKey SESSION_DATA_KEY_INFO_BOX_HIDDEN)
            |> Option.defaultValue false

        let darfVerschieben =
            match user with
            | Some u -> u.Roles |> List.contains Roles.KundeKostenpflichtigBuchen
            | None -> false

        let model = {
            Model.Init with
                ShowInfoBox = not infoBoxHidden
                UserDarfVerschieben = darfVerschieben
        }

        match page with
        | Verrechnungen None ->
            (model,
             [
                 Cmd.ofMsg LoadFilialen
                 (Cmd.ofMsg LoadEventSettings)
             ]
             |> Cmd.batch)

        | Verrechnungen(Some filialeId) ->
            ({
                model with
                    SelectedFilialeId = Some filialeId
             },
             Cmd.batch [
                 (Cmd.ofMsg LoadFilialen)
                 (Cmd.ofMsg (LoadVerrechnungen filialeId))
                 (Cmd.ofMsg LoadEventSettings)
             ])

    let kannFilialeVerschieben filialen filialeId =
        match filialen, filialeId with
        | Body filialen, Some filialeId ->
            (filialen
             |> Array.tryFind (fun f -> f.FilialeId = filialeId)
             |> Option.map (fun f -> f.Limit)
             |> Option.defaultValue 0m) > 0m
        | _ -> false

    let update msg (model: Model) =
        match msg with
        | LoadFilialen ->
            let cmd = FilialenLoaded |> request Http.getFilialen ()
            ({ model with Filialen = Loading }, cmd)
        | FilialenLoaded(Ok filialen) ->
            let zentrale = filialen |> Seq.tryFind (fun f -> f.IsZentrale)

            let filialeId =
                match zentrale, filialen with
                | Some f, _ -> Some f.FilialeId
                | _, [| f |] -> Some f.FilialeId
                | _, [||] -> None
                | _, filialen -> Some filialen.[0].FilialeId
            let cmd =
                if
                    model.SelectedFilialeId.IsSome // wir haben schon eine Filiale ausgewählt
                then
                    Cmd.none
                else
                    filialeId
                    |> Option.map (fun f -> Cmd.ofMsg (LoadVerrechnungen f))
                    |> Option.defaultValue Cmd.none

            ({
                model with
                    Filialen = (Body filialen)
                    FilialeKannVerschieben = (kannFilialeVerschieben (Body filialen) model.SelectedFilialeId)
             },
             cmd)

        | FilialenLoaded(Error ex) ->
            ({
                model with
                    Filialen = (LoadError "Filialen konnten nicht geladen werden.")
             },
             Cmd.none)

        | LoadVerrechnungen filialeId ->
            let cmd =
                VerrechnungenLoaded
                |> request Http.getVerrechnugnen (filialeId)
            ({
                model with
                    Verrechnungen = Loading
                    SelectedFilialeId = Some filialeId
                    VerrechnungAuswaehlenFuerMonat = None
                    SelectedVerrechnugen = []
                    FilialeKannVerschieben = false
             },
             cmd)
        | VerrechnungenLoaded(Ok verrechnungen) ->
            ({
                model with
                    Verrechnungen = (Body verrechnungen)
                    FilialeKannVerschieben = (kannFilialeVerschieben model.Filialen model.SelectedFilialeId)
             },
             Cmd.none)
        | VerrechnungenLoaded(Error ex) ->
            ({
                model with
                    Verrechnungen = (LoadError "Verrechnungen konnten nicht geladen werden.")
             },
             Cmd.none)

        | SwitchFiliale filialeId ->
            ({
                model with
                    DtaReportAngefordert = false
             },
             Cmd.ofMsg (LoadVerrechnungen filialeId))

        | VerschiebenStart monat ->
            ({
                model with
                    VerrechnungAuswaehlenFuerMonat = Some monat
                    SelectedVerrechnugen = []
             },
             Cmd.none)

        | VerschiebenDurchfuehrenTry ->
            let cmd =
                VerschiebenDurchfuehrenTried
                |> request Http.tryMove (model.SelectedFilialeId, model.SelectedVerrechnugen)

            ({ model with IsSending = true }, cmd)

        | VerschiebenDurchfuehrenTried result ->
            let cmd =
                match result with
                | Error ex -> ex |> errorCmd GlobalMsg "Verschieben"
                | Ok(VerschiebungNichtMoeglichError e) ->
                    e
                    |> GlobalMessageBox.ErrorWithTitle "Verschieben nicht möglich"
                    |> ShowMessageBox
                    |> GlobalMsg
                    |> Cmd.ofMsg
                | Ok(VerschiebungNichtMoeglichLimitErreicht s) ->
                    s
                    |> GlobalMessageBox.WarningWithTitle "Limit überschritten"
                    |> ShowMessageBox
                    |> GlobalMsg
                    |> Cmd.ofMsg
                | Ok(VerschiebungOk s) ->
                    {
                        Type = InfoBox
                        Title = "Forderungen verschieben"
                        BodyElement = Fable.React.Helpers.str s
                        Buttons = [
                            {
                                Caption = "Abbrechen"
                                Type = GlobalMessageBoxButtonType.OkButton
                                Message = None
                                ClosesDialog = true
                            }
                            {
                                Caption = "Drucken"
                                Type = GlobalMessageBoxButtonType.YesButton
                                Message = Some(PrintVereinbarung s :> AnyWebPartMsg)
                                ClosesDialog = false
                            }
                            {
                                Caption = "Kostenpflichtig verschieben"
                                Type = GlobalMessageBoxButtonType.CancelButton
                                Message = Some(VerschiebenDurchfuehren :> AnyWebPartMsg)
                                ClosesDialog = true
                            }
                        ]
                    }
                    |> ShowMessageBox
                    |> GlobalMsg
                    |> Cmd.ofMsg

            ({ model with IsSending = false }, cmd)

        | VerschiebenDurchfuehren ->
            let cmd =
                VerschiebenDurchfuehrenDone
                |> request Http.move (model.SelectedFilialeId, model.SelectedVerrechnugen)

            ({ model with IsSending = true }, cmd)

        | VerschiebenDurchfuehrenDone result ->
            let cmd1 =
                match result with
                | Error ex -> ex |> errorCmd GlobalMsg "Verschieben"
                | Ok(VerschiebungNichtMoeglichError e) ->
                    e
                    |> GlobalMessageBox.ErrorWithTitle "Verschieben nicht möglich"
                    |> ShowMessageBox
                    |> GlobalMsg
                    |> Cmd.ofMsg
                | Ok(VerschiebungNichtMoeglichLimitErreicht s) ->
                    s
                    |> GlobalMessageBox.WarningWithTitle "Limit überschritten"
                    |> ShowMessageBox
                    |> GlobalMsg
                    |> Cmd.ofMsg
                | Ok(VerschiebungOk s) ->
                    s
                    |> GlobalMessageBox.SuccessWithTitle "Forderungen erfolgreich verschoben"
                    |> ShowMessageBox
                    |> GlobalMsg
                    |> Cmd.ofMsg

            let cmd2 =
                match result, model.SelectedFilialeId with
                | Ok(VerschiebungOk _), Some id -> (LoadVerrechnungen id) |> Cmd.ofMsg
                | _ -> Cmd.none

            (model, Cmd.batch [ cmd1; cmd2 ])

        | PrintVereinbarung s ->
            Preview.webPrint "Verrechnungsfristverschiebung" s
            (model, Cmd.none)

        | VerschiebenAbbrechen ->
            ({
                model with
                    VerrechnungAuswaehlenFuerMonat = None
                    SelectedVerrechnugen = []
             },
             Cmd.none)
        | SelectVerrechnung(id, value) ->
            let l =
                if value then
                    model.SelectedVerrechnugen |> List.append [ id ]
                else
                    model.SelectedVerrechnugen |> List.filter (fun x -> x <> id)
            ({ model with SelectedVerrechnugen = l }, Cmd.none)

        | HideInfoBox ->
            let cmd =
                Cmd.ofMsg (GlobalMsg(GlobalMsg.UpdateUserSessionData(SESSION_DATA_KEY_INFO_BOX_HIDDEN, "")))
            ({ model with ShowInfoBox = false }, cmd)

        | Toggle id ->
            ({
                model with
                    ExpandedVerrechnungen = model.ExpandedVerrechnungen |> toggle id
             },
             Cmd.none)

        | DtaReportAnfordern ->
            let cmd =
                DtaReportAngefordert
                |> request Http.createDtaReport (model.SelectedFilialeId)
            (model, cmd)

        | DtaReportAngefordert result ->
            let m = {
                model with
                    DtaReportAngefordert = true
            }
            match result with
            | Error(exn) -> (m, exn |> errorCmd GlobalMsg "Dta-Report")
            | _ ->
                (m,
                 GlobalMessageBox.Success
                     "Die Auswertung wird in Kürze für Sie erzeugt und steht Ihnen unter Dokumente zur Verfügung."
                 |> ShowMessageBox
                 |> GlobalMsg
                 |> Cmd.ofMsg)

        | LoadEventSettings ->
            let request () = fetchAs<EventSettings> "/api/events/settings"
            model, (LoadEventSettingsCompleted |> Http.request request ())
        | LoadEventSettingsCompleted(Error _) ->
            Logger.error "Die Einstellungen für das Ostergewinnspiel konnten nicht geladen werden"
            model, Cmd.none
        | LoadEventSettingsCompleted(Ok settings) ->
            {
                model with
                    OstergewinnspielActive = settings.EasterEvent.IsSome
            },
            Cmd.none

        | ShowTicketModalFor verrechnung ->
            {
                model with
                    ShowTicketModalFor = verrechnung
            },
            Cmd.none

        | GlobalMsg(_) -> (model, Cmd.none) // diese Message wird gar nicht hier behandelt, sondern im Dispatcher!
