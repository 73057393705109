namespace Settings

open Elmish
open Types
open Server
open Bfs.Web.Data.Service.Contracts.Kundenportal.Settings

module State =
    let init page user lastModel =
        match (page, user) with
        | (Settings, Some u) ->
            let model = {
                Model.Init() with
                    DokumentenAbrufAnzeigen = dokumentenAbrufAnzeigen u
            }
            (model, Cmd.ofMsg LoadSettings)
        | (Settings, None) -> failwith "Usersession is None"

    let update msg (model: Model) =
        match msg with
        | LoadSettings ->
            let cmd = Cmd.OfAsync.eitherAsResult Service.Settings.GetSettings () SettingsLoaded
            (model, cmd)
        | SettingsLoaded(Ok settings) ->
            let model' = {
                model with
                    Filialen = Some settings.DokumentenabrufSettings
                    PostInsKundenportalZustellen = settings.PostInsKundenportalZustellen
            }
            (model', Cmd.none)
        | SettingsLoaded(Error err) ->
            (model,
             GlobalMessageBox.Error(err.ToString())
             |> ShowMessageBox
             |> GlobalMsg
             |> Cmd.ofMsg)

        | TryChangeDokumentenabruf(filialeId, b) ->
            let cmd =
                Cmd.OfAsync.eitherAsResult Service.Settings.TryDokumentenabruf (filialeId, b) (fun x ->
                    ChangeDokumentenabrufTried(filialeId, b, x))
            (model, cmd)
        | ChangeDokumentenabrufTried(_, _, Error err) ->
            (model,
             GlobalMessageBox.Error(err.ToString())
             |> ShowMessageBox
             |> GlobalMsg
             |> Cmd.ofMsg)
        | ChangeDokumentenabrufTried(_, _, Ok(Error e)) ->
            (model,
             GlobalMessageBox.Error e
             |> ShowMessageBox
             |> GlobalMsg
             |> Cmd.ofMsg)
        | ChangeDokumentenabrufTried(filialeId, b, Ok(Ok s)) ->
            let cmd =
                {
                    Type = InfoBox
                    Title = (if b then "Dokumentenabruf abonnieren" else "Dokumentenabruf beenden")
                    BodyElement = Fable.React.Helpers.str s
                    Buttons = [
                        {
                            Caption = "Abbrechen"
                            Type = GlobalMessageBoxButtonType.OkButton
                            Message = None
                            ClosesDialog = true
                        }
                        {
                            Caption = "Drucken"
                            Type = GlobalMessageBoxButtonType.YesButton
                            Message = Some(PrintVereinbarung s :> AnyWebPartMsg)
                            ClosesDialog = false
                        }
                        {
                            Caption = (if b then "Kostenpflichtig abonnieren" else "Dokumentenabruf beenden")
                            Type = GlobalMessageBoxButtonType.CancelButton
                            Message = Some(ChangeDokumentenabrufSetting(filialeId, b) :> AnyWebPartMsg)
                            ClosesDialog = true
                        }
                    ]
                }
                |> ShowMessageBox
                |> GlobalMsg
                |> Cmd.ofMsg
            (model, cmd)

        | PrintVereinbarung s ->
            Preview.webPrint "Verrechnungsfristverschiebung" s
            (model, Cmd.none)

        | ChangeDokumentenabrufSetting(filialeId, b) ->
            let cmd =
                Cmd.OfAsync.eitherAsResult Service.Settings.SetDokumentenabruf (filialeId, b) (fun x ->
                    DokumentenabrufSettingChanged(filialeId, b, x))
            (model, cmd)
        | DokumentenabrufSettingChanged(_, _, Error err) ->
            (model,
             GlobalMessageBox.Error(err.ToString())
             |> ShowMessageBox
             |> GlobalMsg
             |> Cmd.ofMsg)
        | DokumentenabrufSettingChanged(_, _, Ok(Error e)) ->
            (model,
             GlobalMessageBox.Error e
             |> ShowMessageBox
             |> GlobalMsg
             |> Cmd.ofMsg)
        | DokumentenabrufSettingChanged(_, _, Ok(Ok s)) ->
            let cmd1 =
                s
                |> GlobalMessageBox.SuccessWithTitle "Dokumentenabruf erfolgreich abonniert"
                |> ShowMessageBox
                |> GlobalMsg
                |> Cmd.ofMsg
            let cmd2 = LoadSettings |> Cmd.ofMsg
            (model, Cmd.batch [ cmd1; cmd2 ])

        | SwitchOffPostzustellung ->
            let question =
                "Sind Sie sich sicher, dass Sie die Postzustellung im Kundenportal ausschalten möchten?"
            let box =
                GlobalMessageBox.ConfirmationBox
                    "Postzustellung im Kundenportal ausschalten"
                    question
                    (Some((SwitchOffPostzustellungConfirmed) :> AnyWebPartMsg))
            let cmd = box |> ShowMessageBox |> GlobalMsg |> Cmd.ofMsg
            (model, cmd)
        | SwitchOffPostzustellungConfirmed ->
            let cmd =
                Cmd.OfAsync.eitherAsResult Service.Settings.SetPostInsKundenportalZustellen false PostzustellungToggled
            (model, cmd)
        | SwitchOnPostzustellung ->
            let cmd =
                Cmd.OfAsync.eitherAsResult Service.Settings.SetPostInsKundenportalZustellen true PostzustellungToggled
            (model, cmd)
        | PostzustellungToggled(Ok s) ->
            let model' = {
                model with
                    PostInsKundenportalZustellen = s
            }
            let cmd =
                if s then
                    let box =
                        GlobalMessageBox.InfoWithTitle
                            "Postzustellung im Kundenportal eingeschaltet"
                            "Zukünftig werden alle Briefe an Sie hier ins Kundenportal zugestellt."
                    box |> ShowMessageBox |> GlobalMsg |> Cmd.ofMsg
                else
                    Cmd.none
            (model', cmd)
        | PostzustellungToggled(Error err) ->
            (model,
             GlobalMessageBox.Error(err.ToString())
             |> ShowMessageBox
             |> GlobalMsg
             |> Cmd.ofMsg)

        | _ -> (model, Cmd.none)
