namespace Help

open Elmish
open Types
open Http
open Bfs.Web.Data.Service.Contracts.Kundenportal.Help


module Http =
    let getAnsprechpartner () = fetchAs<Ansprechpartner> "/api/ansprechpartner"

module State =
    let init page user lastModel =
        match page with
        | Kontakt -> (Model.Init(), Cmd.ofMsg LoadAnsprechpartner)

    let update msg (model: Model) =
        match msg with
        | LoadAnsprechpartner ->
            let cmd = AnsprechpartnerLoaded |> request Http.getAnsprechpartner ()
            ({ model with Ansprechpartner = Loading }, cmd)
        | AnsprechpartnerLoaded x ->
            ({
                model with
                    Ansprechpartner = Loaded x
             },
             Cmd.none)

        | Toggle i ->
            ({
                model with
                    Expanded = model.Expanded |> toggle i
                    Touched = model.Touched |> List.append [ i ] |> List.distinct
             },
             Cmd.none)
