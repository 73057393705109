﻿module Bfs.Web.Kundenportal.WebParts.User.Shared.Modal

open Fable.React
open Fable.React.Props

let userDialog (headerElement: ReactElement) (bodyElements: ReactElement list) (footerElements: ReactElement list) =
    div [ Class "modal is-active is-wide" ] [
        div [ Class "modal-background" ] []
        div [
            Class "modal-content overflow-initial"
        ] [
            header [ Class "modal-card-head" ] [
                p [ Class "modal-card-title" ] [ headerElement ]
            ]
            section
                [
                    Class "modal-card-body overflow-initial"
                ]
                bodyElements
            footer [ Class "modal-card-foot" ] footerElements
        ]
    ]
