﻿module Bfs.Web.Kundenportal.PWA.NotificationBindings

open Browser.Types
open Fable.Core
open Bfs.Web.Data.Service.Contracts.Kundenportal.Notifications
open Http
open Bfs.Web.Kundenportal.JsInterop

type ServiceWorkerRegistration = ServiceWorkerRegistration of obj

type SubscriptionProperties = {
    userVisibleOnly: bool
    applicationServerKey: int array
}

[<AllowNullLiteral>]
type PushSubscription =
    abstract foo: string // Das ist nur ein Platzhalter damit wir ein nullable Objekt erzeugen können

[<Literal>]
let NotificationPermissionUnset = "default"
let NotificationPermissionGranted = "granted"
let NotificationPermissionDenied = "denied"

type NotificationBindings =
    abstract notificationPermission: unit -> string
    abstract requestNotificationPermission: unit -> JS.Promise<string>

    abstract serviceWorkerRegistration: JS.Promise<ServiceWorkerRegistration>
    abstract getSubscription: ServiceWorkerRegistration -> JS.Promise<PushSubscription>
    abstract subscribe: ServiceWorkerRegistration -> vapidPublicKey: string -> JS.Promise<PushSubscription>

    abstract mapSubscription: PushSubscription -> Subscription

[<Import("*", "./NotificationScripts.js")>]
let notificationBindings: NotificationBindings = jsNative

module NotificationHelper =

    let private GetSubscription () =
        notificationBindings.serviceWorkerRegistration
        |> Promise.bind (fun registration ->
            registration
            |> notificationBindings.getSubscription
            |> Promise.bind (fun subscription ->
                match subscription with
                | null ->
                    (fetchAsString "/api/notification/vapidPublicKey")
                    |> Promise.bind (notificationBindings.subscribe registration)
                | sub -> Promise.lift sub)
            |> Promise.map notificationBindings.mapSubscription)

    let SubscribeToWebPush () =
        GetSubscription()
        |> Promise.bind (postRecord<Subscription> "/api/notification/saveSubscription")
        |> Promise.map _.Ok

    let UnsubscribeFromWebPush () =
        GetSubscription()
        |> Promise.map (fun sub -> { Endpoint = sub.Endpoint })
        |> Promise.bind (postRecord2<EndpointIdentifier, bool> "/api/notification/disable")
