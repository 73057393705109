namespace Password

open Http
open Elmish
open Types
open Validation
open Bfs.Web.Data.Service.Contracts
open Bfs.Web.Data.Service.Contracts.Kundenportal.Account

module Http =
    let postRequestReset (email) =
        postRecord2<string, Result<string, string>> "/api/account/password/requestreset" email

    let getIsTokenOk (token) =
        fetchAs<BoolResult> (sprintf "/api/account/password/istokenvalid?token=%s" token)

    let postResetPassword (token, password, password2) =
        postRecord2<ResetPassword, Result<string, string>> "/api/account/password/reset" {
            Token = token
            NewPassword1 = password
            NewPassword2 = password2
        }

module State =
    let init page user lastModel =
        match page with
        | LostPassword -> (LostPasswordModel(LostPasswordModel.Init()), Cmd.none)
        | ResetPassword token -> (ResetPasswordModel(ResetPasswordModel.Init(token)), Cmd.ofMsg (CheckToken token))
        | MessagePage text -> (MessageModel(MessageModel.Init(text)), Cmd.none)

    let updateLostPassword msg (model: LostPasswordModel) =
        match msg with
        | EmailChanged value ->
            ({
                model with
                    Email = update model.Email value
             },
             Cmd.none)

        | Submit ->
            if model.CanSubmit then
                let cmd =
                    Submitted
                    |> request Http.postRequestReset (model.Email.Value)
                ({ model with Sending = true }, cmd)
            else
                (model, Cmd.none)

        | Submitted(Ok(Ok text))
        | Submitted(Ok(Error text)) -> ({ model with Sending = false }, Cmd.ofMsg (ShowMsg text))
        | Submitted(Error e) -> // irgendein anderer fehler
            ({ model with Sending = false }, e |> errorCmd GlobalMsg "Senden")

        | GlobalMsg(_) -> (model, Cmd.none) // diese Message wird gar nicht hier behandelt, sondern im Dispatcher!

        | PasswordChanged _
        | Password2Changed _
        | CheckToken _
        | ShowMsg _
        | TokenChecked _ -> (model, Cmd.none) // diese msg werden bei diesem Model ignoriert

    let updateResetPassword msg (model: ResetPasswordModel) =
        match msg with
        | PasswordChanged value ->
            ({
                model with
                    Password = update model.Password value
             },
             Cmd.none)
        | Password2Changed value ->
            ({
                model with
                    Password2 = update model.Password2 value
             },
             Cmd.none)

        | CheckToken token ->
            let cmd = TokenChecked |> request Http.getIsTokenOk (token)
            ({ model with Sending = true }, cmd)
        | TokenChecked(Ok isTokenOk) ->
            let cmd =
                match isTokenOk.Value with
                | false -> Cmd.ofMsg (ShowMsg "Die angegebenen URL ist nicht mehr gültig.")
                | true -> Cmd.none
            ({ model with Sending = false }, cmd)
        | TokenChecked(Error e) -> ({ model with Sending = false }, e |> errorCmd GlobalMsg "Senden")

        | Submit ->
            if model.CanSubmit then
                let cmd =
                    Submitted
                    |> request Http.postResetPassword (model.Token, model.Password.Value, model.Password2.Value)
                ({ model with Sending = true }, cmd)
            else
                (model, Cmd.none)
        | Submitted(Ok(Ok text)) -> ({ model with Sending = false }, Cmd.ofMsg (ShowMsg text))
        | Submitted(Ok(Error text)) ->
            ({
                model with
                    Sending = false
                    Message = Some text
             },
             Cmd.none)
        | Submitted(Error e) -> // irgendein anderer fehler
            ({ model with Sending = false }, e |> errorCmd GlobalMsg "Senden")

        | GlobalMsg(_) -> (model, Cmd.none) // diese Message wird gar nicht hier behandelt, sondern im Dispatcher!

        | EmailChanged _
        | ShowMsg _ -> (model, Cmd.none) // diese msg werden bei diesem Model ignoriert

    let update msg (model: Model) =
        match msg with
        | ShowMsg text -> (MessageModel(MessageModel.Init text), Cmd.none)
        | _ ->
            match model with
            | LostPasswordModel model ->
                let newModel, newMsg = updateLostPassword msg model
                (LostPasswordModel newModel, newMsg)
            | ResetPasswordModel model ->
                let newModel, newMsg = updateResetPassword msg model
                (ResetPasswordModel newModel, newMsg)
            | MessageModel _ -> (model, Cmd.none)
