module Profile.View

open System
open Bfs.Web.Kundenportal.PWA.NotificationBindings
open Bfs.Web.Kundenportal.PWA.PwaBindings
open Fable.React

open Types
open Fable.React.Props

open ViewParts

let optBoxEnabeld state dispatch =
    let buttonStyle = if state then "is-danger" else "is-light"
    div [ Class "paragraphs" ] [
        p [] [
            b [] [
                str "Ihr Account ist durch Zwei-Faktor-Authentifizierung abgesichert! "
                span [] [ i [ Class "fas fa-check" ] [] ]
            ]
        ]
        p [] [
            label [ Class "checkbox" ] [
                input [
                    Type "checkbox"
                    Checked state
                    OnChange(fun ev -> ((DeactivateOtpCheckboxChanged ev.Checked) |> Otp |> dispatch))
                ]
                str " Ich möchte die Zwei-Faktor-Authentifizierung deaktivieren."
            ]
        ]
        p [ Class "is-pulled-right" ] [
            button [
                Class("bfsbutton")
                Disabled(not state)
                OnClick(fun _ ->
                    if state then
                        (DeactivateOtp |> Otp |> dispatch))
            ] [ str "Deaktivieren" ]
        ]
    ]
let optBoxDisabeld dispatch =
    div [ Class "paragraphs" ] [
        p [] [
            b [] [
                str "Sie können ihren Account durch die Verwendung von Zwei-Faktor-Authentifizierung absichern:"
            ]
        ]
        p [] [
            str
                "Sie benötigen eine Authenticator-App auf Ihrem Smartphone (z.B. Google Authenticator, Authy, 1Password, etc.), um für jede Anmeldung ein neues Einmalpasswort zu erzeugen."
        ]
        p [] [ img [ Src "BFS_2fa_prozess2x.jpg" ] ]
        p [ Class "is-pulled-right" ] [
            a [
                Class "bfsbutton "
                OnClick(fun _ -> ActivateOtp |> Otp |> dispatch)
            ] [ str "Jetzt aktivieren!" ]
        ]
    ]
let submitOtpOnEnter dispatch =
    OnKeyDown(fun (e) ->
        if (e.key = "Enter") then
            ConfirmOtp |> Otp |> dispatch)
let optBoxActivating model dispatch =
    div [ Class "paragraphs otp-form" ] [
        yield
            p [ Class "is-hidden-touch" ] [
                str
                    "Bitte scannen Sie den QR-Code mit Ihrer Authenticator-App und verifizieren Sie Ihre App durch die Eingabe des in der App angezeigten Einmalpassworts."
            ]
        yield p [ Class "is-hidden-touch" ] [ img [ Src model.QrCode ] ]

        yield
            p [ Class "is-hidden-desktop" ] [
                str
                    "Bitte geben Sie den folgenden Code in Ihrer Authenticator-App ein und verifizieren Sie Ihre App durch die Eingabe des in der App angezeigten Einmalpassworts."
            ]
        yield
            p [
                Class "is-hidden-desktop is-size-3 has-text-justified otp-code"
            ] [ str model.Secret ]

        yield
            validatedTextBoxWithoutErrorMsg2
                [
                    AutoComplete "off"
                    submitOtpOnEnter dispatch
                ]
                (dispatch << (Otp << OtpChanged))
                "Einmalpasswort"
                "Otp"
                "123456"
                model.OtpField

        if model.ErrorMsg.IsSome then
            yield div [ Class "is-pulled-left error-msg" ] [ str model.ErrorMsg.Value ]

        yield
            p [ Class "is-pulled-right" ] [
                button [
                    Type "submit"
                    Class "bfsbutton"
                    Disabled(not (model.CanSubmit))
                    Props.OnClick(fun _ -> ConfirmOtp |> Otp |> dispatch)
                ] [ str "Bestätigen" ]
            ]
    ]

let otpBox (model: OtpModel) dispatch =
    div [ Class "column is-6" ] [
        div [
            Class "flat-card profile-info-card is-auto"
        ] [
            div [ Class "card-title" ] [
                h3 [] [ b [] [ str "Account absichern" ] ]
            ]
            div [ Class "card-body" ] [
                yield
                    match model.Status with
                    | OtpDeactivated -> optBoxDisabeld dispatch
                    | OtpActivating -> optBoxActivating model dispatch
                    | OtpActivated should -> optBoxEnabeld should dispatch
            ]
        ]
    ]

let submitPasswordOnEnter dispatch =
    OnKeyDown(fun (e) ->
        if (e.key = "Enter") then
            SubmitPassword |> Password |> dispatch)
let passwordChangeForm model dispatch = [
    yield
        div [ Class "info-block" ] [
            validatedPasswordBoxWithoutErrorMsg
                "is-tooltip-right"
                [ submitPasswordOnEnter dispatch ]
                (dispatch << (Password << OldPasswordChanged))
                "Aktuelles Passwort"
                "OldPassword"
                ""
                model.OldPassword
        ]
    yield
        div [ Class "info-block" ] [
            validatedPasswordBoxWithoutErrorMsg
                "is-tooltip-right"
                [ submitPasswordOnEnter dispatch ]
                (dispatch << (Password << NewPassword1Changed))
                "Neues Passwort"
                "NewPassword1"
                ""
                model.NewPassword1
        ]
    yield
        div [ Class "info-block" ] [
            validatedPasswordBoxWithoutErrorMsg
                "is-tooltip-right"
                [ submitPasswordOnEnter dispatch ]
                (dispatch << (Password << NewPassword2Changed))
                "Neues Passwort (Wiederholung)"
                "NewPassword2"
                ""
                model.NewPassword2
        ]

    if model.ErrorMsg.IsSome then
        yield div [ Class "is-pulled-left error-msg" ] [ str model.ErrorMsg.Value ]

    yield
        p [ Class "is-pulled-right" ] [
            button [
                Type "submit"
                Class("bfsbutton")
                Disabled(not (model.CanSubmit))
                Props.OnClick(fun _ -> SubmitPassword |> Password |> dispatch)
            ] [ str "Ändern" ]
        ]
]

let passwordBox model dispatch =
    div [ Class "column is-6" ] [
        div [
            Class "flat-card profile-info-card is-auto with-overflowing-tooltips"
        ] [
            div [ Class "card-title" ] [
                h3 [] [ b [] [ str "Passwort ändern" ] ]
            ]
            div [ Class "card-body paragraphs" ] [
                match model.OkMsg with
                | None -> yield! passwordChangeForm model dispatch
                | Some msg -> yield str msg
            ]
        ]
    ]

let helloBox (model: Model) =
    div [ Class "column is-12" ] [
        div [ Class "flat-card is-auto" ] [
            div [ Class "" ] [
                yield
                    match model.UserInfo with
                    | Empty -> div [] []
                    | Loading -> spinner
                    | LoadError s -> errorMsg "Fehler beim Laden!"
                    | Body b ->
                        section [ Class "hero" ] [
                            div [ Class "hero-body" ] [
                                yield h1 [ Class "title" ] [ str model.Name ]
                                if (b.Kundennummer.IsSome) then
                                    yield
                                        p [ Class "subtitle" ] [
                                            str ("Kundennummer: " + b.Kundennummer.Value.ToString())
                                        ]
                            ]
                        ]
            ]
        ]
    ]

let view model dispatch navigateTo =
    div [
        Class "columns is-account-grid is-multiline"
    ] [
        helloBox model

        if model.Password.IsSome then
            passwordBox model.Password.Value dispatch

        if model.Otp.IsSome then
            otpBox model.Otp.Value dispatch
    ]
