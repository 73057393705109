module AdminBanner.Types

open Bfs.Web.Data.Service.Contracts
open Bfs.Web.Data.Service.Contracts.Kundenportal
open Bfs.Web.Data.Service.Contracts.Kundenportal.Banner
open Bfs.Web.Data.Service.Contracts.Kundenportal.Admin
open Types
open Fetch
open Fulma.Elmish
open System
open Validation

type Page =
    | BannerList of page: int
    | BannerEdit of id: string
    | BannerNew

type Filter =
    | Alle
    | Pflege
    | Therapie
    | BfsPlus
    | Jugendhilfe

[<Literal>]
let Hell = "#FFFFFF"
[<Literal>]
let Dunkel = "#4A4A4A"

type Msg =
    | LoadBannerPage of page: int
    | BannerPageLoaded of Result<Paginated<AdminBannerListItem>, exn>

    | FilterChanged of Filter

    | ActivateBanner of id: string
    | DeactivateBanner of id: string
    | ActiveChanged of Result<Response, exn>

    | NewBanner
    | LoadBanner of id: string
    | BannerLoaded of Result<AdminBanner, exn>

    | TagChanged of string
    | TitelChanged of string
    | TextChanged of string
    | GueltigAbChanged of DatePicker.Types.State * DateTime option
    | GueltigBisChanged of DatePicker.Types.State * DateTime option
    | AktivChanged of bool
    | ImageOnlyChanged of bool
    | KundenTypChanged of KundenTyp option
    | TextFarbeChanged of string

    | DeleteImageDesktop
    | UploadImageDesktop of Browser.Types.Blob
    | DesktopImageUploaded of Result<DesktopImageUploaded, exn>

    | DeleteImageMobile
    | UploadImageMobile of Browser.Types.Blob
    | MobileImageUploaded of Result<bool, exn>

    | Save
    | Saved of Result<Response, exn>
    | SavedNew of Result<Guid, exn>

    | ValidateImageOnly

    | GlobalMsg of GlobalMsg

type Model =
    | ListModel of BannerListModel
    | ItemModel of BannerModel
and BannerListModel = {
    PageNumber: int
    Filter: Filter
    Banner: Remote<Paginated<AdminBannerListItem>>
} with
    static member Init pagenumber = {
        PageNumber = pagenumber
        Banner = Loading
        Filter = Alle
    }
and BannerModel = {
    Id: string option
    BackToPage: int
    Tag: FormField
    HintergrundFarbe: string
    TextFarbe: string
    Titel: FormField
    Text: FormField
    GueltigAb: DateFormField
    GueltigBis: DateFormField
    Aktiv: bool
    KundenTyp: KundenTyp option
    HasImageDesktop: bool
    HasImageMobile: bool
    ImageOnly: bool
    ImageOnlyEnabled: bool

    IsDirty: bool
    Sending: bool

    TextAsHtml: string

    UserSession: UserSession option
    UploadTrigger: int
} with
    static member Init id backToPage session = {
        Id = id
        BackToPage = backToPage
        Titel = FormField.Init(Some(MandatoryWithMaxLength 200))
        Text = FormField.Init(Some Mandatory)
        GueltigAb = DateFormField.Init(Some MandatoryDate)
        GueltigBis = DateFormField.Init(Some MandatoryDate)
        KundenTyp = None
        Aktiv = true
        Sending = false
        HasImageDesktop = false
        HasImageMobile = false
        IsDirty = false
        TextAsHtml = ""
        Tag = FormField.Init(None)
        HintergrundFarbe = Hell
        TextFarbe = ""
        UserSession = session
        UploadTrigger = 0
        ImageOnly = false
        ImageOnlyEnabled = false
    }
    member x.CanSubmit =
        not x.Sending
        && x.Titel.IsValid
        && x.Text.IsValid
        && x.GueltigAb.IsValid
        && x.GueltigBis.IsValid
        && x.Tag.IsValid
