﻿module Bfs.Web.Kundenportal.WebParts.User.Shared.Card

open Bfs.Web.Portale.Collapsable
open Fable.React
open Fable.React.Props

let bfsCard (title: ReactElement list option) (body: ReactElement list option) =
    div [
        Class "flat-card profile-info-card is-auto overflow-initial"
    ] [
        if title.IsSome then
            div [ Class "card-title" ] title.Value
        if body.IsSome then
            div [ Class "card-body" ] body.Value
    ]

let bfsCardCollapsable =
    FunctionComponent.Of(fun (title: string, body: ReactElement list, isOpen: bool) ->

        let isOpen = Hooks.useState isOpen

        div [
            Class "flat-card profile-info-card is-auto overflow-initial"
        ] [
            div [ Class "card-title" ] [
                a [
                    OnClick(fun _ -> isOpen.update (not isOpen.current))
                ] [
                    (match isOpen.current with
                     | true -> i [ Class "fas fa-chevron-down mr-2" ] []
                     | false -> i [ Class "fas fa-chevron-right mr-2" ] [])
                    str title
                ]
            ]
            CollapsableElement(div [ Class "card-body" ] body, isOpen.current)
        ])
