﻿module AdminBanner.View

open AdminBanner.Types
open Bfs.Web.Kundenportal
open Shared.Banner
open Fable.React
open Types
open Bfs.Web.Data.Service.Contracts.Kundenportal.Banner
open Fable.React.Props
open ViewParts
open Fulma

let tableRow dispatch navigateTo (banner: AdminBannerListItem) =
    let aktiv = if banner.Aktiv then "" else "deaktiviert"

    tr [ Class aktiv ] [
        td [] [ str banner.Titel ]
        td [] [
            str (banner.GueltigAb.ToString("dd.MM.yyyy"))
        ]
        td [] [
            str (banner.GueltigBis.ToString("dd.MM.yyyy"))
        ]
        td [] [
            a [
                OnClick(fun _ -> navigateTo (Page.BannerEdit(banner.Id.ToString())))
                Title "bearbeiten"
            ] [ icon "fas fa-edit" ]
            (if banner.Aktiv then
                 a [
                     OnClick(fun _ -> DeactivateBanner(banner.Id.ToString()) |> dispatch)
                     Title "deaktivieren"
                 ] [ icon "fas fa-eye-slash" ]
             else
                 a [
                     OnClick(fun _ -> ActivateBanner(banner.Id.ToString()) |> dispatch)
                     Title "aktivieren"
                 ] [ icon "fas fa-eye" ])
        ]
    ]

let table banner dispatch navigateTo =
    table [
        Class "table table-striped responsive-table"
    ] [
        thead [] [
            tr [] [
                th [] [ str "Titel" ]
                th [] [ str "Gültig ab" ]
                th [] [ str "Gültig bis" ]
                th [] [ str "Aktionen" ]
            ]
        ]
        tbody [] (banner |> List.map (tableRow dispatch navigateTo))
    ]

let listPage (model: BannerListModel) dispatch navigateTo =
    let filterAlle, filterPflege, filterBfsPlus, filterTherapie, filterJugendhilfe =
        match model.Filter with
        | Alle -> "", "is-outlined", "is-outlined", "is-outlined", "is-outlined"
        | Pflege -> "is-outlined", "", "is-outlined", "is-outlined", "is-outlined"
        | BfsPlus -> "is-outlined", "is-outlined", "", "is-outlined", "is-outlined"
        | Therapie -> "is-outlined", "is-outlined", "is-outlined", "", "is-outlined"
        | Jugendhilfe -> "is-outlined", "is-outlined", "is-outlined", "is-outlined", ""

    div [ Class "columns is-multiline" ] [
        div [ Class "column is-12" ] [
            yield
                div [
                    Class "flat-card profile-info-card is-auto"
                ] [
                    div [ Class "card-body" ] [
                        yield
                            button [
                                Class "button is-link is-pulled-right is-small"
                                OnClick(fun _ -> navigateTo Page.BannerNew)
                            ] [ str "Neu" ]

                        yield
                            div [ Class "field has-addons" ] [
                                p [ Class "control" ] [
                                    button [
                                        Class("button is-link is-small is-rounded is-info " + filterAlle)
                                        OnClick(fun _ -> FilterChanged Alle |> dispatch)
                                    ] [ str "alle" ]
                                ]
                                p [ Class "control" ] [
                                    button [
                                        Class("button is-link is-small is-rounded is-info " + filterPflege)
                                        OnClick(fun _ -> FilterChanged Pflege |> dispatch)
                                    ] [ str "nur Pflege" ]
                                ]
                                p [ Class "control" ] [
                                    button [
                                        Class(
                                            "button is-link is-small is-rounded is-info "
                                            + filterBfsPlus
                                        )
                                        OnClick(fun _ -> FilterChanged BfsPlus |> dispatch)
                                    ] [ str "nur BFS Plus" ]
                                ]
                                p [ Class "control" ] [
                                    button [
                                        Class(
                                            "button is-link is-small is-rounded is-info "
                                            + filterTherapie
                                        )
                                        OnClick(fun _ -> FilterChanged Therapie |> dispatch)
                                    ] [ str "nur Therapie" ]
                                ]
                                p [ Class "control" ] [
                                    button [
                                        Class(
                                            "button is-link is-small is-rounded is-info "
                                            + filterJugendhilfe
                                        )
                                        OnClick(fun _ -> FilterChanged Jugendhilfe |> dispatch)
                                    ] [ str "nur Jugendhilfe" ]
                                ]
                            ]

                        yield
                            match model.Banner with
                            | Empty -> div [] []
                            | Loading -> spinner
                            | LoadError _ -> errorMsg "Fehler beim Laden der Banner!"
                            | Body x when x.Items = [] -> div [] [ str "Keine Banner vorhanden" ]
                            | Body x -> table x.Items dispatch navigateTo
                    ]
                ]
            match model.Banner with
            | Body x -> yield pagination x (fun page -> navigateTo (Page.BannerList page))
            | _ -> ()
        ]
    ]

let editBox (model: BannerModel) dispatch navigateTo =
    let isLoading = (if model.Sending then " is-loading" else "")
    Column.column [
        Column.Width(Screen.All, Column.Is12)
    ] [
        div [
            Class "flat-card profile-info-card is-auto card-editor"
        ] [
            div [ Class "card-title" ] [
                h3 [] [ b [] [ str "Banner bearbeiten" ] ]
            ]
            div [ Class "card-body" ] [

                Columns.columns [] [
                    Column.column [ Column.Width(Screen.All, Column.Is3) ] [
                        validatedTextBox (dispatch << TagChanged) "Tag" "Tag" "" model.Tag
                    ]

                    Column.column [ Column.Width(Screen.All, Column.Is9) ] [
                        validatedTextBox (dispatch << TitelChanged) "Titel" "Titel" "" model.Titel
                    ]
                ]

                Columns.columns [] [
                    Column.column [
                        Column.Width(Screen.All, Column.Is12)
                    ] [
                        validatedTextarea (dispatch << TextChanged) "Text" "Text" "" "is-big" model.Text
                    ]
                ]

                Columns.columns [ Columns.CustomClass "image-upload" ] [
                    Column.column [ Column.Width(Screen.All, Column.Is6) ] [
                        div [ Class "field" ] [
                            label [ Class "label" ] [ str "Hintergrundbild Desktop" ]
                            adminImageUpload
                                model.Id
                                model.HasImageDesktop
                                (formatImageUrlDesktop model.UploadTrigger)
                                DeleteImageDesktop
                                UploadImageDesktop
                                dispatch
                                "Bitte speichern Sie das neu Banner bevor Sie ein Bild hochladen."
                                None
                        ]
                    ]

                    Column.column [ Column.Width(Screen.All, Column.Is6) ] [
                        div [ Class "field" ] [
                            label [ Class "label" ] [ str "Hintergrundbild Mobil" ]
                            adminImageUpload
                                model.Id
                                model.HasImageMobile
                                (formatImageUrlMobile model.UploadTrigger)
                                DeleteImageMobile
                                UploadImageMobile
                                dispatch
                                "Bitte speichern Sie das neu Banner bevor Sie ein Bild hochladen."
                                None
                        ]
                    ]
                ]

                Columns.columns [] [
                    Column.column [ Column.Width(Screen.All, Column.Is3) ] [
                        div [ Class "field" ] [
                            label [ Class "label" ] [
                                str "Nur das Hintergrundbild anzeigen"
                            ]
                            (match model.ImageOnlyEnabled with
                             | true -> checkbox model.ImageOnly (fun b -> b |> ImageOnlyChanged |> dispatch) ""
                             | false ->
                                 disabeldCheckboxWithTooltip
                                     "Um diese Option zu aktivieren müssen Hintergrundbilder für Desktop und Mobil hinterlegt sein"
                                     (fun _ -> ()))
                        ]
                    ]
                ]

                Columns.columns [] [
                    Column.column [ Column.Width(Screen.All, Column.Is3) ] [
                        div [ Class "field" ] [
                            label [ Class "label" ] [ str "Textfarbe" ]
                            Select.select [ Select.Option.CustomClass "" ] [
                                select [
                                    Class "select input is-multiline is-multiple"
                                    OnChange(fun ev ->
                                        let farbe =
                                            match ev.Value with
                                            | Hell -> Hell
                                            | _ -> Dunkel
                                        TextFarbeChanged farbe |> dispatch)
                                ] [
                                    option [
                                        Value Hell
                                        Selected(
                                            match model.TextFarbe with
                                            | Hell -> true
                                            | _ -> false
                                        )
                                    ] [ str "Hell" ]
                                    option [
                                        Value Dunkel
                                        Selected(
                                            match model.TextFarbe with
                                            | Dunkel -> true
                                            | _ -> false
                                        )
                                    ] [ str "Dunkel" ]
                                ]
                            ]
                        ]
                    ]
                ]

                Columns.columns [] [
                    Column.column [ Column.Width(Screen.All, Column.Is3) ] [
                        validatedDatePicker<Msg> GueltigAbChanged "Gültig ab" model.GueltigAb dispatch
                    ]
                    Column.column [ Column.Width(Screen.All, Column.Is3) ] [
                        validatedDatePicker<Msg> GueltigBisChanged "Gültig bis" model.GueltigBis dispatch
                    ]
                    Column.column [ Column.Width(Screen.All, Column.Is3) ] [
                        KundenTypSelector.Selector model.KundenTyp (fun kundentyp ->
                            kundentyp |> KundenTypChanged |> dispatch)
                    ]
                    Column.column [ Column.Width(Screen.All, Column.Is3) ] [
                        div [ Class "field" ] [
                            label [ Class "label" ] [ str "Aktiv" ]
                            checkbox model.Aktiv (fun b -> b |> AktivChanged |> dispatch) ""
                        ]
                    ]
                ]

                div [ Class "columns" ] [
                    div [ Class "column is-12" ] [
                        div [ Class "info-block is-pulled-right" ] [
                            button [
                                Class("button is-link" + isLoading)
                                Disabled(not model.CanSubmit)
                                OnClick(fun _ -> dispatch Save)
                            ] [ str "Speichern" ]
                        ]
                        div [ Class "info-block" ] [
                            button [
                                Class("button is-danger is-link")
                                OnClick(fun _ -> navigateTo (Page.BannerList model.BackToPage))
                            ] [
                                str (if model.IsDirty then "Abbrechen" else "Zurück")
                            ]
                        ]
                    ]
                ]
            ]
        ]
    ]

type PreviewBannerSize =
    | Desktop
    | Tablet
    | Mobile

let previewBanner (model: BannerModel) (size: PreviewBannerSize) =
    let fakeDispatch = fun _ -> ()
    let fakeNavigateTo = fun _ -> ()

    Column.column [
        Column.Width(
            Screen.All,
            (match size with
             | Desktop -> Column.Is12
             | Tablet -> Column.Is6
             | Mobile -> Column.Is3)
        )
        Column.CustomClass "preview"
    ] [
        h3 [] [
            b [] [
                str (
                    match size with
                    | Desktop -> "Vorschau Desktop"
                    | Tablet -> "Vorschau Tablet"
                    | Mobile -> "Vorschau Smartphone"
                )
            ]
        ]

        bfsBanner {
            GlobalDispatch = fakeDispatch
            NavigateTo = fakeNavigateTo
            UserSession = model.UserSession
            Id = model.Id
            Tag = model.Tag.Value
            Title = model.Titel.Value
            Text = model.TextAsHtml
            HasImageDesktop = model.HasImageDesktop
            HasImageMobile = model.HasImageMobile
            ImageOnly = model.ImageOnly
            BackgroundColor = model.HintergrundFarbe
            TextColor = model.TextFarbe
            UpdateTrigger = model.UploadTrigger
            PreviewMode =
                match size with
                | Mobile
                | Tablet -> PreviewMode.Mobile
                | _ -> PreviewMode.Desktop
        }
    ]

let editPage (model: BannerModel) dispatch navigateTo =
    div [ Class "banner-editor" ] [
        editBox model dispatch navigateTo
        Columns.columns [] [
            previewBanner model PreviewBannerSize.Desktop
        ]
        Columns.columns [] [
            previewBanner model PreviewBannerSize.Tablet
            Column.column [ Column.Width(Screen.All, Column.Is2) ] []
            previewBanner model PreviewBannerSize.Mobile
        ]
    ]


let view (model: Model) dispatch (navigateTo: AnyPage -> unit) =
    match model with
    | ListModel m -> listPage m dispatch navigateTo
    | ItemModel m -> editPage m dispatch navigateTo
