﻿module Bfs.Web.Portale.UploadBox

open Bfs.Web.Data.Service.Contracts
open Fable.Core.JsInterop
open Fable.React
open Fable.React.Props
open Browser.Dom
open Types

[<RequireQualifiedAccess>]
module UploadFileType =

    type Type = {
        MimeType: string
        Label: string
        IsValid: Browser.Types.File -> bool
    }

    let Pdf: Type = {
        MimeType = "application/pdf"
        Label = "PDF"
        IsValid = (fun file -> file.``type`` = "application/pdf")
    }

    let Image: Type = {
        MimeType = "image/jpeg,image/png"
        Label = "Bild"
        IsValid = (fun file -> file.``type`` = "image/jpeg" || file.``type`` = "image/png")
    }

type Accept =
    | All
    | Only of UploadFileType.Type list

type ConfirmSettings = {
    IsSending: bool
    CanConfirm: bool
    ConfirmUpload: unit -> unit
}

let UploadBox =
    FunctionComponent.Of
        (fun
            (accept: Accept,
             upload: Browser.Types.File list -> unit,
             delete: UploadResult -> unit,
             fileList: UploadResult list,
             uploadCount: int,
             confirmSettings: ConfirmSettings option,
             globalDispatch: GlobalMsg -> unit) ->

            let isSending =
                confirmSettings
                |> Option.map (fun item -> item.IsSending)
                |> Option.defaultValue false
            let isLoading = (if isSending then " is-loading" else "")

            let rec uploadHintRestricted (allowedTypes: UploadFileType.Type list) =
                match allowedTypes with
                | head :: tail when tail.Length > 1 -> $"{head.Label}-, {uploadHintRestricted tail}"
                | head :: tail when tail.Length = 1 -> $"{head.Label}- oder {uploadHintRestricted tail}"
                | head :: tail when tail.Length = 0 -> $"{head.Label} Dateien"
                | _ -> System.String.Empty

            let uploadHint =
                match accept with
                | All -> "Dateien"
                | Only allowedTypes -> uploadHintRestricted allowedTypes

            let removeInvalidFiles (filelist: Browser.Types.File list) =

                let checkFileSize (file: Browser.Types.File) =
                    match file.size > 0 with
                    | true -> None
                    | false -> Some "leere Datei"

                let checkFileType file =
                    match accept with
                    | All -> None
                    | Only acceptedTypes ->
                        let isValid =
                            acceptedTypes
                            |> List.tryFind (fun acceptedType -> acceptedType.IsValid file)
                            |> Option.isSome
                        match isValid with
                        | true -> None
                        | false -> Some "falsches Dateiformat"

                let valid, invalid =
                    filelist
                    |> List.fold
                        (fun (valid, invalid) file ->
                            match (checkFileSize file), (checkFileType file) with
                            | None, None -> (valid @ [ file ], invalid)
                            | _, Some error -> (valid, invalid @ [ (file, error) ])
                            | Some error, _ -> (valid, invalid @ [ (file, error) ]))
                        ([], [])

                if invalid |> List.isEmpty |> not then
                    {
                        Type = GlobalMessageType.ErrorBox
                        Title = "Dateiupload"
                        BodyElement =
                            div [] [
                                str "Folgende Dateien werden können nicht hinzugefügt werden:"
                                ul
                                    [ ClassName "failed-upload-list" ]
                                    (invalid
                                     |> List.map (fun (file, reason) -> li [] [ str $"{file.name} ({reason})" ]))
                            ]
                        Buttons = [
                            {
                                Type = GlobalMessageBoxButtonType.OkButton
                                Caption = "OK"
                                Message = None
                                ClosesDialog = true
                            }
                        ]
                    }
                    |> ShowMessageBox
                    |> globalDispatch

                valid

            div [
                Class "flat-card profile-info-card is-auto upload-box"
            ] [
                div [
                    OnDrop(fun ev ->
                        ev.preventDefault ()
                        (document.getElementById "dropoverlay").classList.remove "dragging"

                        let dataTransferItemList = ev.dataTransfer.items
                        let fileList =
                            [ 0 .. dataTransferItemList.length - 1 ]
                            |> List.map (fun i -> ev.dataTransfer.items[i].getAsFile ())
                            |> removeInvalidFiles
                        if fileList |> List.isEmpty |> not then
                            fileList |> upload)
                    OnDragEnter(fun _ -> (document.getElementById "dropoverlay").classList.add "dragging")
                    OnDragLeave(fun ev ->
                        // da mit DragEnter das overlay angezeigt wird interessiert uns tatsächlich nur,
                        // wann wir dieses overlay wieder verlassen
                        if (ev.target?id = "dropoverlay") then
                            (document.getElementById "dropoverlay").classList.remove "dragging")
                    OnDragOver(fun ev -> ev.preventDefault ())
                ] [
                    div [
                        Id "dropoverlay"
                        Class "dropoverlay"
                    ] [
                        i [ Class "fas fa-upload fa-4x" ] []
                        p [ Style [ Padding "1em" ] ] [ str "Dateien hier ablegen" ]
                    ]

                    div [ Class "card-body" ] [
                        yield
                            div [ Class "file is-boxed" ] [
                                label [ Class "file-label" ] [
                                    input [
                                        Class "file-input"
                                        Type "file"
                                        Multiple true
                                        (match accept with
                                         | Only types ->
                                             let mimeTypes =
                                                 types
                                                 |> List.map (fun acceptedType -> acceptedType.MimeType)

                                             Accept(System.String.Join(",", mimeTypes))
                                         | All -> Accept "*")
                                        // damit die Notation bei OnChange funktioniert (um auf die JS properties zuzugreifen) muss "open Fable.Core.JsInterop" oben stehen
                                        OnChange(fun ev ->
                                            let files = !!(ev.target?files)
                                            let fileList =
                                                // Ryder meckert hier, dass die Klammern unnötig sind - sind sie aber nicht!
                                                [ 0 .. files?length - 1 ]
                                                |> List.map (fun i -> files?(i) :> Browser.Types.File)
                                                |> removeInvalidFiles
                                            if fileList |> List.isEmpty |> not then
                                                fileList |> upload)
                                    ]
                                    span [ Class "file-cta" ] [
                                        span [ Class "file-icon" ] [ i [ Class "fas fa-upload fa-4x" ] [] ]
                                        span [ Class "file-label" ] [

                                            span [ Class "is-hidden-touch" ] [
                                                str $"{uploadHint} auf diese Fläche ziehen oder hier klicken"
                                            ]
                                            span [ Class "is-hidden-desktop" ] [
                                                str $"Hier klicken um {uploadHint} hinzuzufügen"
                                            ]
                                        ]
                                    ]
                                ]
                            ]

                        if (not fileList.IsEmpty || uploadCount > 0) then
                            yield
                                div [ Class "file-list" ] [
                                    yield!
                                        fileList
                                        |> List.map (fun item ->
                                            div [ Class "file-list-item" ] [
                                                div [
                                                    Class "file-name-left ellipsis"
                                                    Title item.Dateiname
                                                ] [ str item.Dateiname ]
                                                a [
                                                    Class "fas fa-trash-alt"
                                                    Title "löschen"
                                                    OnClick(fun _ -> item |> delete)
                                                ] []
                                            ])
                                    if (uploadCount > 0) then
                                        for _ in [ 0 .. (uploadCount - 1) ] do
                                            yield p [] [ str "wird hochgeladen..." ]
                                ]

                        match confirmSettings, fileList with
                        | Some settings, list when not list.IsEmpty ->
                            yield
                                button [
                                    Class("bfsbutton wide" + isLoading)
                                    Disabled(not settings.CanConfirm)
                                    OnClick(fun _ -> settings.ConfirmUpload())
                                ] [ str "Versenden" ]
                        | _ -> yield fragment [] []
                    ]
                ]
            ])
