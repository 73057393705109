namespace LetzterBelegeingang


open Bfs.Web.Data.Service.Contracts.Kundenportal.Dashboard.Lists
open Elmish
open LetzterBelegeingang.Types
open Http
open Types

module Http =
    let getData _ = fetchAs<SendungBelegeingang list> "/api/dashboard/letzter-belegeingang-list"

module State =
    let init _ _ _ = { Loading = true; Data = List.Empty }, Cmd.ofMsg LoadData

    let update (msg: Msg) (model: Model) : Model * Cmd<Msg> =
        match msg with
        | LoadData -> { model with Loading = true; Data = [] }, (DataLoaded |> request Http.getData ())
        | DataLoaded(Ok data) -> { Loading = false; Data = data }, Cmd.none
        | DataLoaded(Error ex) ->
            { Loading = false; Data = [] },
            Cmd.ofMsg (
                GlobalMsg(
                    GlobalMsg.ShowMessageBox(
                        GlobalMessageBox.Error "Die Daten können zur Zeit leider nicht geladen werden."
                    )
                )
            )
        | GlobalMsg(_) -> model, Cmd.none
