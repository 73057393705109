module Sendungen.View

open System
open Bfs.Web.Kundenportal.WebParts.User.Shared.Dropdown
open Bfs.Web.Portale.UploadBox
open Fable.React
open Fulma

open Browser

open Types
open Bfs.Web.Data.Service.Contracts.Kundenportal.Sendungen
open Bfs.Web.Kundenportal.WebParts.User.Events.Easter
open Bfs.Web.Shared.Formating
open Fable.React.Props
open ViewParts

let renderDate date = str (date |> Option.map asString |> Option.defaultValue "-")

let filialenRow selectedFilialeId dispatch (filialen: Filiale[]) =
    let selectedFilialeId = selectedFilialeId |> Option.defaultValue Guid.Empty

    Tabs.tabs [
        Tabs.CustomClass "is-hidden-touch account-tabs main-menu with-width-constraint"
    ] [
        filialen
        |> Array.sortBy (fun f -> (not f.IsZentrale, f.Name))
        |> Array.map (fun f ->
            Tabs.tab [
                Tabs.Tab.CustomClass "submenu-item"
                Tabs.Tab.IsActive(f.FilialeId = selectedFilialeId)
            ] [
                a [
                    OnClick(fun _ -> (SwitchFiliale f.FilialeId) |> dispatch)
                ] [ str f.Name ]
            ])
        |> ofArray
    ]

type FilialeMobileProps = {
    SelectedFilialeId: Guid option
    Dispatch: Msg -> unit
    Filialen: Filiale[]
}

let filialenMobile (selectedFilialeId: Guid option) dispatch (filialen: Filiale[]) =
    div [ Class "is-hidden-desktop mb-2" ] [
        bfsDropdown<Filiale> {
            Data =
                filialen
                |> Array.sortBy (fun f -> (not f.IsZentrale, f.Name))
                |> Array.toList
            OnSelect = (fun filiale -> filiale.FilialeId |> SwitchFiliale |> dispatch)
            SelectedElement =
                filialen
                |> Array.tryFind (fun filiale ->
                    selectedFilialeId.IsSome
                    && filiale.FilialeId = selectedFilialeId.Value)
            ElementToKey = (_.FilialeId.ToString())
            ElementToString = (_.Name)
        }
    ]

let ticketText (sendung: Sendung) = sprintf "Sendung %d" sendung.SendungsNr

let actionHoverMenu dispatch navigateTo (sendung: Sendung) =
    let menuName = sprintf "dropdown-%O" sendung.SendungId
    div [
        Class "dropdown is-hoverable is-right"
    ] [
        div [ Class "dropdown-trigger" ] [
            div [
                AriaHasPopup true
                AriaControls menuName
            ] [ icon "fas fa-ellipsis-v" ]
        ]
        div [
            Class "dropdown-menu"
            Id menuName
            Role "menu"
        ] [
            div [ Class "dropdown-content" ] [
                p [
                    Class "dropdown-item"
                    Style [
                        FontWeight "bold"
                        TextAlign TextAlignOptions.Center
                    ]
                ] [ str $"Sendung {sendung.SendungsNr}" ]
                hr [ Class "dropdown-divider" ]
                if sendung.AllowUploads then
                    a [
                        Class "dropdown-item"
                        OnClick(fun _ -> ShowUpload sendung |> dispatch)
                    ] [
                        icon "fas fa-upload icon"
                        str "Dokumente hochladen"
                    ]
                if sendung.AllowDokumentenDepot then
                    a [
                        Class "dropdown-item"
                        OnClick(fun _ -> UseDokumentenDepot sendung |> dispatch)
                    ] [
                        icon "fas fa-archive icon"
                        str "Dokumenten-Depot nutzen"
                    ]
                if sendung.AllowFlexitarif then
                    a [
                        Class "dropdown-item"
                        OnClick(fun _ -> GetFlexitarif sendung |> dispatch)
                    ] [
                        icon "fas fa-shipping-fast"
                        str "Flexitarif auswählen"
                    ]
                a [
                    Class "dropdown-item"
                    OnClick(fun _ -> navigateTo (Tickets.Types.Page.PrefilledTicket((ticketText sendung), None)))
                ] [
                    icon "fas fa-ticket icon"
                    str "Ticket erstellen"
                ]
            ]
        ]
    ]

let iconsForSendung (sendung: Sendung) =
    match sendung.SendungsOption with
    | Some Beleglos ->
        span [
            Class "tooltip"
            Data("tooltip", "Ohne Originalbelege")
        ] [ linkicon "fas fa-print-slash" ]
    | Some Mischsendung ->
        span [
            Class "tooltip"
            Data("tooltip", "Mischsendung")
        ] [ linkicon "fas fa-print" ]
    | Some DokumentenDepot ->
        span [
            Class "tooltip"
            Data("tooltip", "Dokumentendepot")
        ] [ linkicon "fas fa-archive" ]
    | None -> Helpers.nothing
    |> fun e ->
        if sendung.TarifGewaehlt then
            [
                e
                span [
                    Class "tooltip"
                    Data("tooltip", "Flexitarif")
                ] [ linkicon "fas fa-shipping-fast" ]
            ]
            |> ofList
        else
            e

let sendungenMobile (sendungen: Sendung[]) model dispatch navigateTo =
    div [ Class "data-list is-hidden-desktop" ] [
        sendungen
        |> (Array.map (fun s ->
            div [ Class "data-item" ] [
                div [] [
                    div [ Class "dta-file" ] [
                        str (sprintf "Sendung %d " s.SendungsNr)
                        if s.Gesamtbetrag.IsSome then
                            span [] [
                                str (sprintf " (%s)" (s.Gesamtbetrag.Value |> asMoney))
                            ]

                        span [] [ iconsForSendung s ]
                    ]
                    div [ Class "dta-date" ] [
                        actionHoverMenu dispatch navigateTo s
                    ]
                ]
                div [ Class "dta-status" ] [
                    if s.Angekauft.IsSome then
                        div [] [
                            str "Davon angekauft: "
                            str (s.Angekauft.Value |> asMoney)
                        ]
                    if s.Belegeingang.IsSome then
                        div [] [
                            str "Belegeingang: "
                            str (s.Belegeingang.Value |> asString)
                        ]
                    if s.Belegpruefung.IsSome then
                        div [] [
                            str "Belegprüfung: "
                            str (s.Belegpruefung.Value |> asString)
                        ]
                    if s.ErwartetesZahldatum.IsSome then
                        div [] [
                            str "Erwartetes Zahldatum: "
                            str (s.ErwartetesZahldatum.Value |> asString)
                        ]
                ]
            ]))
        |> ofArray
    ]

let sendungZeile dispatch navigateTo model (sendung: Sendung) =
    tr [] [
        td [] [
            p [] [ str $"Sendung {sendung.SendungsNr}" ]
        ]
        td [ Class "text-left" ] [ iconsForSendung sendung ]
        td [ Class "text-right" ] [ renderDate sendung.Belegeingang ]
        td [ Class "text-right" ] [ renderDate sendung.Belegpruefung ]
        td [ Class "text-right" ] [
            renderDate sendung.ErwartetesZahldatum
        ]
        td [ Class "text-right" ] [
            Option.map asMoneySpan sendung.Gesamtbetrag
            |> Option.defaultValue (str "-")
        ]
        td [ Class "text-right" ] [
            Option.map asMoneySpan sendung.Angekauft
            |> Option.defaultValue (str "-")
        ]
        td [ Class "pull-right" ] [
            actionHoverMenu dispatch navigateTo sendung
        ]
    ]

let sendungenTabelle (sendungen: Sendung[]) model dispatch navigateTo =
    let rows =
        sendungen
        |> Array.map (sendungZeile dispatch navigateTo model)
    table [
        Class "table table-striped responsive-table is-hidden-touch is-bfscolored"
    ] [
        thead [] [
            tr [] [
                th [] [ str "SendungsNr" ]
                th [ Class "text-left" ] [ str "Besonderheit" ]
                th [ Class "text-right" ] [ str "Belegeingang" ]
                th [ Class "text-right" ] [ str "Belegprüfung" ]
                th [ Class "text-right" ] [ str "Erwartetes Zahldatum" ]
                th [ Class "text-right" ] [ str "Gesamtbetrag" ]
                th [ Class "text-right" ] [ str "Davon angekauft" ]
                th [ Class "text-right" ] []
            ]
        ]
        tbody [] rows
    ]

let emptyContent =
    div [ Class "columns" ] [
        div [ Class "column is-12" ] [
            div [
                Class "flat-card profile-info-card is-auto overflow-initial"
            ] [
                div [ Class "card-title" ] [
                    h3 [] [ b [] [ str "Keine Sendungen" ] ]
                ]
                div [ Class "card-body" ] [
                    p [] [
                        str "Innerhalb des letzten halben Jahres liegen uns keine Sendungen vor."
                    ]
                ]
            ]
        ]
    ]

let content dispatch navigateTo (sendungen: Sendung[]) model =
    div [ Class "sendungen columns" ] [
        div [ Class "column is-12" ] [
            div [
                Class "flat-card profile-info-card is-auto overflow-initial is-relative"
            ] [
                if model.OstergewinnspielActive then
                    (Egg Color.Yellow Variant.Lines [ Top "60%" ])
                    (Egg Color.Green Variant.Dotted [ Left "40%" ])
                    (Egg Color.Orange Variant.Lines [ Left "100%"; Top "20%" ])
                div [ Class "card-body" ] [
                    sendungenTabelle sendungen model dispatch navigateTo
                    sendungenMobile sendungen model dispatch navigateTo
                ]
            ]
        ]
    ]

let sendungenUploadBox (sendung: Sendung) (model: Types.Model) dispatch =
    UploadBox(
        (Accept.Only [ UploadFileType.Pdf ]),
        (fun filelist -> UploadFiles(sendung.SendungId, filelist) |> dispatch),
        (fun file -> file |> DeleteUploadedFile |> dispatch),
        model.CurrentUploadList,
        model.CurrentUploadCount,
        None,
        (fun msg -> msg |> Msg.GlobalMsg |> dispatch)
    )

let uploadModal model (sendung: Sendung) dispatch =
    let classesForSelected = "bfsbutton is-selected"
    let beleglosExplain =
        "Ich habe keine Original-Belege und möchte alle Unterlagen, die zu dieser Sendung gehören, hochladen."
    let mischSendungExplain =
        "Manche Rechnungen erfordern Originalbelege. Diese habe ich bereits mit dem Sendungsbegleitzettel an die BFS verschickt. Ich möchte nur für einzelne Rechnungen Unterlagen hochladen."
    let uploadsDone = model.CurrentUploadList.Length > 0
    let b1Classes, b2Classes, explain, canProceed =
        match model.SelectedSendungsOption with
        | Some Beleglos -> (classesForSelected, String.Empty, beleglosExplain, uploadsDone)
        | Some Mischsendung -> (String.Empty, classesForSelected, mischSendungExplain, uploadsDone)
        | _ -> failwith "bad model state"

    let button1 =
        button [
            Class $"button {b1Classes} is-small"
            OnClick(fun _ -> SelectSendungsOption Beleglos |> dispatch)
        ] [ str "Ohne Originalbelege" ]
    let button2 =
        button [
            Class $"button {b2Classes} is-small"
            OnClick(fun _ -> SelectSendungsOption Mischsendung |> dispatch)
        ] [ str "Mischsendung" ]
    let buttons = div [ Class "buttons has-addons" ] [ button1; button2 ]

    div [
        Class "modal is-active datenuebergabe"
    ] [
        div [ Class "modal-background" ] []
        div [
            Class "modal-content overflow-initial"
        ] [
            header [ Class("modal-card-head") ] [
                div [ Class "modal-card-title" ] [
                    str $"Dokumente für Sendung {sendung.SendungsNr} hochladen"
                ]
            ]
            section [
                Class "modal-card-body overflow-initial"
            ] [
                div [ Class "info-Block" ] [
                    yield
                        div [ Class "columns" ] [
                            div [ Class "column is-half" ] [
                                h5 [ Class "title is-5" ] [ str "Sendungsoption" ]
                                buttons
                                p [] [ str explain ]
                            ]
                            div [ Class "column is-half" ] [
                                sendungenUploadBox sendung model dispatch
                            ]
                        ]
                ]
            ]
            footer [ Class "modal-card-foot" ] [
                button [
                    Class "bfsbutton"
                    Disabled(not canProceed)
                    OnClick(fun _ -> Msg.GetVereinbarungForUploads |> dispatch)
                ] [ str "Weiter" ]
                button [
                    Class "button is-bfscolored"
                    OnClick(fun _ -> CancelUpload |> dispatch)
                ] [ str "Abbrechen" ]
            ]
        ]
    ]

let calcEntgelt (flexitarif: FlexitarifFuerSendung) (flexitarifAuswahl: FlexitarifAuswahl) =
    let selectedTag =
        flexitarif.AuszahlungstageMitEntgelt
        |> Array.find (fun t -> t.Auszahlungstag.Date = flexitarifAuswahl.AusfuehrungZahlung.Date)
    if flexitarifAuswahl.Blitzueberweisung then
        selectedTag.Entgelt
        + flexitarif.ZusatzgebuehrFuerBlitzueberweisung
    else
        selectedTag.Entgelt

let getBelegeingangHeader =
    function
    | Stored _ -> "Belegeingang"
    | Calculated _ -> "Erwarteter Belegeingang"

let flexitarifTableMobile (flexitarif: FlexitarifFuerSendung) (flexitarifAuswahl: FlexitarifAuswahl) =
    let entgelt = calcEntgelt flexitarif flexitarifAuswahl
    let belegeingangHeader = getBelegeingangHeader flexitarif.Belegeingang
    table [
        Class "is-hidden-desktop table table-striped responsive-table"
    ] [
        thead [] []
        tbody [] [
            tr [] [
                td [] [ str "Sendung Nr.:" ]
                td [ Class "text-right" ] [ str (string flexitarif.SendungsNr) ]
            ]
            tr [] [
                td [] [ str $"{belegeingangHeader}:" ]
                td [ Class "text-right" ] [
                    str (asString flexitarif.Belegeingang.Value)
                ]
            ]
            tr [] [
                td [] [ str "Forderungsvolumen:" ]
                td [ Class "text-right" ] [
                    asMoneySpan flexitarif.ForderungsVolumen
                ]
            ]
            tr [] [
                td [] [ str "Vereinbartes Zahlungsziel:" ]
                td [ Class "text-right" ] [
                    str $"{flexitarif.VereinbartesZahlungsziel} Tage"
                ]
            ]
            tr [] [
                td [] [ str "Entgelt:" ]
                td [ Class "text-right" ] [ asMoneySpan entgelt ]
            ]
            tr [] [
                td [] [ str "Kalkulierte Zahlung:" ]
                td [ Class "text-right" ] [
                    str (asString flexitarif.KalkuliertesZahlungsdatum)
                ]
            ]
        ]
    ]

let flexitarifTable (flexitarif: FlexitarifFuerSendung) (flexitarifAuswahl: FlexitarifAuswahl) =
    let entgelt = calcEntgelt flexitarif flexitarifAuswahl
    let belegeingangHeader = getBelegeingangHeader flexitarif.Belegeingang
    table [
        Class "is-hidden-touch table table-striped responsive-table"
    ] [
        thead [] []
        tbody [] [
            tr [] [
                td [] [ str "Sendung Nr.:" ]
                td [ Class "text-right" ] [ str (string flexitarif.SendungsNr) ]
                td [] [ str $"{belegeingangHeader}:" ]
                td [ Class "text-right" ] [
                    str (asString flexitarif.Belegeingang.Value)
                ]
            ]
            tr [] [
                td [] [ str "Forderungsvolumen:" ]
                td [ Class "text-right" ] [
                    asMoneySpan flexitarif.ForderungsVolumen
                ]
                td [] [ str "Vereinbartes Zahlungsziel:" ]
                td [ Class "text-right" ] [
                    str $"{flexitarif.VereinbartesZahlungsziel} Tage"
                ]
            ]
            tr [] [
                td [] [ str "Entgelt:" ]
                td [ Class "text-right" ] [ asMoneySpan entgelt ]
                td [] [ str "Kalkulierte Zahlung:" ]
                td [ Class "text-right" ] [
                    str (asString flexitarif.KalkuliertesZahlungsdatum)
                ]
            ]
        ]
    ]

let orangeCheckboxWithElem ischecked elem onclick =
    Level.left [] [
        elem
        div [
            Class "flexitarif orangeCheckboxWithText"
        ] [ orangeCheckbox ischecked onclick ]
    ]

let dropDown dispatch (selectedAuszahlungstag: DateTime) (entgelte: FlexitarifEntgelt[]) =
    let options =
        entgelte
        |> Array.map (fun e ->
            option [
                Value e.Auszahlungstag
                Selected(e.Auszahlungstag.Date = selectedAuszahlungstag.Date)
            ] [ str (asString e.Auszahlungstag) ])
        |> List.ofArray

    div [ Class "field" ] [
        div [ Class "label" ] [
            str "Ausführungszeitpunkt der Zahlung"
        ]
        div [ Class "control" ] [
            Select.select [] [
                select
                    [
                        Class "select input"
                        OnChange(fun e ->
                            let d = DateTime.Parse(e.Value)
                            SelectAuszahlungstag d |> dispatch)
                    ]
                    options
            ]
        ]
    ]

let flexitarifModal model flexitarif (flexitarifAuswahl: FlexitarifAuswahl) dispatch =
    let blitzueberweisungCheckbox =
        orangeCheckboxWithElem
            flexitarifAuswahl.Blitzueberweisung
            (str $"Zahlung als Blitzüberweisung ({asMoney flexitarif.ZusatzgebuehrFuerBlitzueberweisung} Zusatzentgelt)")
            (fun _ -> ToggleBlitzueberweisung |> dispatch)
    let agbCheckbox =
        orangeCheckboxWithElem
            model.AgbAkzeptiert
            (p [] [
                str "Ich akzeptiere die "
                a [
                    OnClick(fun _ -> ShowAgb flexitarif.Agb |> dispatch)
                ] [ str "Zusatzvereinbarung" ]
            ])
            (fun _ -> ToggleAgb |> dispatch)
    let modalitaeten =
        let entgelt =
            $"Das zusätzliche Entgelt beträgt {asMoney flexitarif.EntgeltFuerVorziehung} je 1.000,00 € und Kalendertag."
        let einbehalt =
            $" Es wird ein Sicherheitsguthaben von {flexitarif.SicherheitsguthabenProzent} %% der Forderungssumme einbehalten: {asMoney flexitarif.SicherheitsguthabenBetrag}"
        if flexitarif.SicherheitsguthabenProzent <> 0.0m then
            entgelt + einbehalt
        else
            entgelt
    let zahlungDropDown =
        dropDown dispatch flexitarifAuswahl.AusfuehrungZahlung flexitarif.AuszahlungstageMitEntgelt
    let flexitarifControls =
        [
            br []
            zahlungDropDown
            br []
            blitzueberweisungCheckbox
            br []
            agbCheckbox
            br []
            p [ Class "modalitaeten" ] [ str modalitaeten ]
        ]
        |> ofList

    div [ Class "modal is-active flexitarif" ] [
        div [ Class "modal-background" ] []
        div [ Class "modal-content overflow-auto" ] [
            header [ Class("modal-card-head") ] [
                div [ Class "modal-card-title" ] [
                    str "Flexitarif für "
                    br [ Class "is-hidden-tablet" ]
                    str $"Sendung {flexitarif.SendungsNr} wählen"
                ]
            ]
            section [
                Class "modal-card-body overflow-auto"
            ] [
                div [ Class "info-Block" ] [
                    yield flexitarifTable flexitarif flexitarifAuswahl
                    yield flexitarifTableMobile flexitarif flexitarifAuswahl
                    yield flexitarifControls
                ]
            ]
            footer [ Class "modal-card-foot" ] [
                button [
                    Class "bfsbutton is-bfscolored"
                    Disabled(not model.AgbAkzeptiert)
                    OnClick(fun _ -> RequestFlexitarif |> dispatch)
                ] [ str "Kostenpflichtig buchen" ]
                button [
                    Class "button is-bfscolored"
                    OnClick(fun _ -> CancelFlexitarif |> dispatch)
                ] [ str "Abbrechen" ]
            ]
        ]
    ]

let infoBox dispatch navigateTo =
    div [ Class "sendungen box" ] [
        div [ Class "container" ] [
            div [ Class "columns" ] [
                div [ Class "column is-4" ] [
                    i [ Class "fas fa-archive fa-2x" ] []
                    div [] [
                        p [ Class "header" ] [ str "Dokumenten-Depot" ]
                        p [] [
                            str
                                "Die Belege für Ihre Sendung befinden sich in unserem Dokumenten-Depot? Dann geben Sie uns einfach Bescheid, damit wir die Belege der Sendung zu sortieren können."
                        ]
                    ]
                ]
                div [ Class "column is-4" ] [
                    i [ Class "fas fa-upload fa-2x" ] []
                    div [] [
                        p [ Class "header" ] [ str "Dokumenten-Upload" ]
                        p [] [
                            str
                                "Für Ihre Sendung möchten Sie Belege hochladen und oder Belege hinzufügen? Dann laden Sie die Belege direkt hier hoch."
                        ]
                    ]
                ]
                div [ Class "column is-4" ] [
                    i [ Class "fas fa-shipping-fast fa-2x" ] []
                    div [] [
                        p [ Class "header" ] [ str "Flexitarif nacherfassen" ]
                        p [] [
                            str
                                "Ihre Auszahlung möchten Sie noch schnell vorziehen? Bis zum Beginn der Belegprüfung können Sie Ihren Zahlwunsch festlegen."
                        ]
                    ]
                ]
            ]
        ]

        button [
            OnClick(fun _ -> HideInfoBox |> dispatch)
            Class "bfsdelete narrow"
            Title "schließen"
        ] []
    ]

let view (model: Types.Model) dispatch (navigateTo: AnyPage -> unit) =
    [
        if model.ShowInfoBox then
            yield infoBox dispatch navigateTo

        match model.Filialen with
        | Empty
        | Loading -> yield spinner
        | LoadError e -> yield errorMsg e
        | Body [||] -> ()
        | Body [| _ |] -> () // wenn es nur eine Fililae gibt, dann brauchen wir keinen umschalter
        | Body f ->
            yield f |> filialenRow model.SelectedFilialeId dispatch
            yield f |> filialenMobile model.SelectedFilialeId dispatch

        match model.Sendungen with
        | Empty
        | Body [||] -> yield emptyContent
        | Loading -> yield spinner
        | LoadError e -> yield errorMsg e
        | Body s -> yield content dispatch navigateTo s model

        match model.SelectedSendung, model.SelectedSendungsOption with
        | Some sendung, None
        | Some sendung, Some SendungsOption.Beleglos
        | Some sendung, Some SendungsOption.Mischsendung -> yield uploadModal model sendung dispatch
        | _ -> ()

        match model.Flexitarif, model.FlexitarifAuswahl with
        | Some flexitarif, Some flexitarifAuswahl -> yield flexitarifModal model flexitarif flexitarifAuswahl dispatch
        | _ -> ()

    ]
    |> ofList
