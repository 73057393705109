module Kundenportal.View

open Types
open Fable.React
open Fable.React.Props
open Fulma
open Bfs.Web.Data.Service.Contracts.Kundenportal


let private bigCoverPicture model dispatch =
    let showBigCoverPicture =
        model.User.IsSome
        && (model.Page = (Home.Types.LandingPage :> AnyPage)
            || model.Page = ((Quickfinder.Types.RechnungQuickfinder "") :> AnyPage))

    if showBigCoverPicture then
        Some(
            div [ Class "bigcover" ] [
                div [ Class "is-hidden-tablet" ] [
                    img [
                        Src "CoverHomepageSmall.webp"
                        Style [ Width "100%" ]
                    ]
                ]
                div [ Class "is-hidden-mobile" ] [
                    img [
                        Src "CoverHomepage.webp"
                        Style [ Width "100%" ]
                    ]
                ]
            ]
        )
    else
        None

let private renderPage model dispatch =
    let navigateTo = (fun page -> dispatch (NavigateTo page))

    WebPartRegistry.AllParts.TryView model dispatch navigateTo
    |> Option.defaultWith (fun () -> Render.pageNotFound)

let view (model: Model) (dispatch: Msg -> unit) =
    let mainMenu, mobileMenu, usericon =
        match model.User with
        | Some user ->
            Kundenportal.Menu.mainMenu user,
            Kundenportal.Menu.mainMenuMobile user,
            if (user.Roles |> Roles.IsSomeAdmin) then
                "fas fa-user-secret"
            else
                "fas fa-user-circle"
        | _ -> [], [], ""

    let backgroundClass =
        if
            model.PageModel :? Login.Types.Model
            || model.PageModel :? Password.Types.Model
        then
            "loginbg app-wrapper"
        else
            "app-wrapper"

    let header =
        model
        |> WebPartRegistry.AllParts.TryGetHeader
        |> Option.defaultValue (Header "BFS-Kundenportal")

    fragment [] [
        (Bfs.Web.Kundenportal.Pwa.Banner(model))
        Template.main
            dispatch
            model
            header
            (bigCoverPicture model dispatch)
            usericon
            Kundenportal.Menu.topMenu
            (Kundenportal.Menu.userMenu dispatch)
            mainMenu
            mobileMenu
            StaticPages.Types.Page.Impressum
            StaticPages.Types.Page.Datenschutz
            backgroundClass
            (renderPage model dispatch)
    ]
