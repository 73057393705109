module Tickets.WebPart

open WebPart
open Types

let Part = {
    Init = State.init
    Update = State.update
    View =
        (fun model dispatch navigateTo ->
            View.view {|
                model = model
                dispatch = dispatch
                navigateTo = navigateTo
            |})

    GetGlobalMsg =
        function
        | GlobalMsg m -> Some m
        | _ -> None

    GetHeader = fun _ -> Header "BFS Ticket-System"

    BuildUrl = fun _ -> [ Urls.tickets ]

    ParseUrl =
        function
        | [ Urls.tickets ] -> Some(Page.Ticket None)
        | _ -> None
}
