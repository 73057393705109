﻿module Bfs.Web.Kundenportal.WebParts.Notifications.Types

open Bfs.Web.Data.Service.Contracts.Kundenportal.Account
open Bfs.Web.Kundenportal.PWA.NotificationBindings
open Types

type Page = | Notifications

type Msg =
    | PostImPortalChanged of bool
    | PreisInfosChanged of bool
    | BelegeingangChanged of bool
    | BelegpruefungAbgeschlossenChanged of bool
    | AuszahlungGetaetigtChanged of bool
    | TicketAntwortChanged of bool
    | SubmitNotifications
    | NotificationsRequestDone of Result<string, exn>

    | LoadUserInfo
    | UserInfoLoaded of Result<UserInfo, exn>

    | LoadPushSubscription
    | LoadPushSubscriptionCompleted of Result<PushSubscription, exn>
    | ValidatePushEndpointCompleted of Result<bool, exn>

    | ChangePushSubscription of bool
    | ChangePushSubscriptionCompleted of Result<bool, exn>

    | GlobalMsg of GlobalMsg

type PushState =
    | AppUndecided // Es wurde noch nicht nach den Permissions gefragt
    | AppDenied // In den App Permissions gesperrt
    | Disabled // Im Server deaktiviert oder unbekannt
    | Enabled

type Model = {
    UserInfo: Remote<UserInfo>
    Email: string
    Name: string

    PostImPortal: bool
    PreisInfos: bool
    Belegeingang: bool
    BelegpruefungAbgeschlossen: bool
    AuszahlungGetaetigt: bool
    PreisInfosAnzeigen: bool
    TicketAntwort: bool

    Sending: bool

    PushState: Remote<PushState>
    SendingPushState: bool
} with
    static member Init email name preisInfosAnzeigen = {
        PostImPortal = false
        PreisInfos = false
        Belegeingang = false
        BelegpruefungAbgeschlossen = false
        AuszahlungGetaetigt = false
        PreisInfosAnzeigen = preisInfosAnzeigen
        TicketAntwort = false

        UserInfo = Loading
        Email = email
        Name = name
        Sending = true
        PushState = Loading
        SendingPushState = false
    }
