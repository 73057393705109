module Faq.View

open System
open Bfs.Web.Kundenportal.WebParts.User.Shared.Dropdown
open Browser.Types
open Fable.React
open Faq.Types
open Fulma
open Browser
open Fable.Core.JsInterop

open Types
open Bfs.Web.Data.Service.Contracts.Kundenportal.Faq
open Bfs.Web.Portale.Collapsable
open Fable.React.Props

open ViewParts

let viewNone =
    div [ Class "card" ] [
        header [ Class "card-header" ] [
            p [ Class "card-header-title" ] [
                str "Keine Fragen oder Antworten gefunden."
            ]
        ]
    ]


let viewFaqEntry =
    FunctionComponent.Of(fun (currentFaq: Faq) ->
        let isExpanded = Hooks.useState false
        let iconclass = if isExpanded.current then " upsidedown" else System.String.Empty
        let content =
            div [
                Class("card-content faq-card-content")
            ] [
                div [
                    Class "content"
                    DangerouslySetInnerHTML { __html = currentFaq.AntwortHtml }
                ] []
            ]

        div [ Class "card" ] [
            header [
                Class "card-header"
                OnClick(fun _ -> isExpanded.update (not isExpanded.current))
            ] [
                p [ Class "card-header-title" ] [ str currentFaq.Frage ]
                a [ Class "card-header-icon" ] [
                    linkicon ("fas fa-chevron-down" + iconclass)
                ]
            ]
            CollapsableElement(content, isExpanded.current)
        ])

let faqList model dispatch =
    Column.column [
        Column.Width(Screen.Desktop, Column.Is8)
        Column.Width(Screen.Touch, Column.Is12)
    ] [
        match model.Faqs with
        | Empty -> div [] []
        | Loading -> spinner
        | LoadError s -> errorMsg "Fehler beim Laden der FAQs!"
        | Body faq when faq = [] -> viewNone
        | Body faq ->
            ul [] [
                faq |> List.map viewFaqEntry |> ofList
            ]
    ]

let kategorie name currentFilter dispatch =
    let active = if name = currentFilter then " is-active" else ""

    li [
        Class("wishlist-item " + active)
        OnClick(fun _ -> (LoadFaq name) |> dispatch)
    ] [
        div [ Class "item-wrapper" ] [ yield a [] [ str name ] ]
    ]

let searchResultkategorie query =
    li [ Class("wishlist-item is-active") ] [
        div [ Class "item-wrapper" ] [
            yield
                a [] [
                    str (sprintf "Suchergebnis '%s'" query)
                ]
        ]
    ]

let onSearchEnter dispatch =
    let q = (document.getElementById "faq-search")?value

    if not (String.IsNullOrWhiteSpace q) then
        q |> SearchForFaq |> dispatch

let searchBox dispatch =
    div [ Class "field has-addons " ] [
        div [ Class "control" ] [
            input [
                Id "faq-search"
                Class "input"
                Type "text"
                DefaultValue ""
                OnKeyDown(fun e ->
                    if (e.key = "Enter") then
                        onSearchEnter dispatch)
                Placeholder "Suche"
            ]
        ]
        div [ Class "control" ] [
            a [
                Class "bfsbutton "
                OnClick(fun _ -> onSearchEnter dispatch)
            ] [ str "suchen" ]
        ]
    ]

let headertext (model: Model) =
    match model.SelectedKategorie with
    | Unknown -> "Häufige Fragen und Antworten"
    | Kategorie kategorie -> sprintf "Häufige Fragen und Antworten - %s" kategorie
    | Search query -> sprintf "Häufige Fragen und Antworten - '%s'" query

type MobileHeaderProps = { Model: Model; Dispatch: Msg -> unit }

let mobileHeader =
    FunctionComponent.Of(fun (props: MobileHeaderProps) ->

        let isOpen = Hooks.useState false
        let searchText = Hooks.useState ""

        let formatSuchergebnis query = $"Suchergebnis '{query}'"

        let selectedKategorieText =
            match props.Model.SelectedKategorie with
            | Kategorie k -> k
            | Search query -> formatSuchergebnis query
            | _ -> " - "

        let performSearch _ =
            if searchText.current <> "" then
                searchText.current |> SearchForFaq |> props.Dispatch

        match props.Model.Kategorien with
        | Empty -> nothing
        | Loading -> spinner
        | LoadError _ -> errorMsg "Ladefehler"
        | Body categoryList ->
            let icon =
                match isOpen.current with
                | true -> "fa-chevron-up"
                | false -> "fa-chevron-down"
            let categoryList =
                match props.Model.SelectedKategorie with
                | Search query -> [ formatSuchergebnis query ] @ categoryList
                | _ -> categoryList

            Fulma.Columns.columns [
                Fulma.Columns.CustomClass
                    "is-hidden-desktop category-header-faq is-flex-direction-column is-align-items-start mobile-header-faq"
            ] [
                Fulma.Column.column [
                    Column.Width(Screen.All, Column.IsFull)
                ] [
                    h2 [] [ str (headertext props.Model) ]
                    form [ OnSubmit performSearch; Class "mb-2" ] [
                        Fulma.Field.div [ Field.HasAddons ] [
                            Fulma.Control.div [ Fulma.Control.IsExpanded ] [
                                input [
                                    Id "faq-search"
                                    Class "input"
                                    Type "text"
                                    Placeholder "Suche"
                                    Value searchText.current
                                    OnChange(fun e -> e.Value |> searchText.update)
                                ]
                            ]
                            div [ Class "control" ] [
                                a [
                                    Class "bfsbutton "
                                    OnClick performSearch
                                ] [ str "Suchen" ]
                            ]
                        ]
                    ]
                    Fulma.Field.div [] [
                        Fulma.Control.div [ Fulma.Control.IsExpanded ] [
                            bfsDropdown<string> {
                                Data = categoryList
                                OnSelect = (fun category -> category |> LoadFaq |> props.Dispatch)
                                SelectedElement = selectedKategorieText |> Some
                                ElementToKey = id
                                ElementToString = id
                            }
                        ]
                    ]
                ]
            ])

let kategorienList kategorien selectedKategorie dispatch =
    let selectedKategorieText =
        match selectedKategorie with
        | Kategorie k -> k
        | _ -> "-"

    div [ Class "column is-4 is-hidden-touch" ] [
        div [ Class "flat-card is-auto menu-card" ] [
            ul [ Class "wishlists" ] [
                match kategorien with
                | Empty -> nothing
                | Loading -> spinner
                | LoadError _ -> errorMsg "Ladefehler"
                | Body k ->
                    let first =
                        match selectedKategorie with
                        | Search q -> [ searchResultkategorie q ]
                        | _ -> []

                    let kategorien =
                        k
                        |> List.map (fun x -> kategorie x selectedKategorieText dispatch)

                    List.concat [ first; kategorien ] |> ofList
            ]
        ]
    ]

let header (model: Model) dispatch =
    div [
        Class "category-header-faq is-hidden-touch"
    ] [
        h2 [] [ str (headertext model) ]
        searchBox dispatch
    ]

let view (model: Types.Model) dispatch (navigateTo: AnyPage -> unit) =
    div [] [
        header model dispatch
        mobileHeader { Model = model; Dispatch = dispatch }
        div [
            Class "columns is-product-list is-multiline faq"
        ] [
            kategorienList model.Kategorien model.SelectedKategorie dispatch
            faqList model dispatch
        ]
    ]
