﻿module Bfs.Web.Kundenportal.KundenTypSelector

open Fable.React
open Bfs.Web.Data.Service.Contracts.Kundenportal
open Fable.React.Props
open Fulma

let Selector (selected: KundenTyp option) (onChange: KundenTyp option -> unit) =
    div [ Class "field" ] [
        label [ Class "label" ] [ str "Kundentyp" ]
        Select.select [ Select.Option.CustomClass "" ] [
            select [
                Class "select input is-multiline is-multiple"
                OnChange(fun ev ->
                    let typ =
                        match ev.Value with
                        | "0" -> None
                        | "1" -> Some KundenTyp.Pflege
                        | "2" -> Some KundenTyp.Therapie
                        | "4" -> Some KundenTyp.BfsPlus
                        | "5" -> Some KundenTyp.Jugendhilfe
                        | _ -> None
                    onChange typ)
            ] [
                option [
                    Value "0"
                    Selected(
                        match selected with
                        | None -> true
                        | _ -> false
                    )
                ] [ str "alle" ]
                option [
                    Value "1"
                    Selected(
                        match selected with
                        | Some KundenTyp.Pflege -> true
                        | _ -> false
                    )
                ] [ str "Pflege" ]
                option [
                    Value "2"
                    Selected(
                        match selected with
                        | Some KundenTyp.Therapie -> true
                        | _ -> false
                    )
                ] [ str "Therapie" ]
                option [
                    Value "4"
                    Selected(
                        match selected with
                        | Some KundenTyp.BfsPlus -> true
                        | _ -> false
                    )
                ] [ str "BFS Plus" ]
                option [
                    Value "5"
                    Selected(
                        match selected with
                        | Some KundenTyp.Jugendhilfe -> true
                        | _ -> false
                    )
                ] [ str "Jugendhilfe" ]
            ]
        ]
    ]
