module AdminFaq.Types

open Bfs.Web.Data.Service.Contracts.Kundenportal.Faq
open Types
open Fetch
open System
open Validation
open Preview

type Page = | Faq

type Msg =
    | LoadKategorien
    | KategorienLoaded of Result<AdminFaqKategorieListItem list, exn>

    | LoadFaqList of kategorieId: Guid
    | FaqListLoaded of Result<AdminFaqListItem list, exn>

    | ActivateFaq of id: string
    | DeactivateFaq of id: string
    | ActiveChanged of Result<Response, exn>

    | NewFaq of kategorieId: Guid
    | LoadFaq of id: Guid
    | FaqLoaded of Result<AdminFaq, exn>

    | FrageChanged of string
    | AntwortChanged of string
    | PositionChanged of string
    | AktivChanged of bool
    | KategorieChanged of Guid
    | SichtbarFuerPflegeChanged of bool
    | SichtbarFuerPflegeDtaPlusChanged of bool
    | SichtbarFuerTherapieChanged of bool
    | SichtbarFuerJugendhilfeChanged of bool

    | Save
    | Saved of Result<Response, exn>
    | SavedNew of Result<Guid, exn>

    | GlobalMsg of GlobalMsg

type EditModel = {
    Id: Guid option

    Frage: FormField
    Antwort: FormField
    Position: FormField
    Aktiv: bool

    SichtbarFuerPflege: bool
    SichtbarFuerPflegeDtaPlus: bool
    SichtbarFuerTherapie: bool
    SichtbarFuerJugendhilfe: bool

    KategorieId: Guid

    Sending: bool
    IsDirty: bool

    TextAsHtml: string
} with
    static member Init id kategorieId = {
        Id = id
        Frage = FormField.Init(Some(MandatoryWithMaxLength 200))
        Antwort = FormField.Init(Some Mandatory)
        Position = FormField.Init(Some MandatoryNumber)
        Aktiv = true
        Sending = false
        IsDirty = false

        SichtbarFuerPflege = false
        SichtbarFuerPflegeDtaPlus = false
        SichtbarFuerTherapie = false
        SichtbarFuerJugendhilfe = false

        KategorieId = kategorieId

        TextAsHtml = ""
    }
    member x.CanSubmit =
        not x.Sending
        && x.Frage.IsValid
        && x.Antwort.IsValid
        && x.Position.IsValid

type Model = {
    Kategorien: AdminFaqKategorieListItem list
    SelectedKategorie: Guid option

    Faqs: Remote<AdminFaqListItem list>

    EditModel: EditModel option
}
