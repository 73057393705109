﻿namespace AdminBanner

open System
open Elmish
open Types
open Http
open Bfs.Web.Data.Service.Contracts
open Bfs.Web.Data.Service.Contracts.Kundenportal.Banner
open Bfs.Web.Data.Service.Contracts.Kundenportal.Admin
open Validation

module Http =
    let getBannerList (page, filter) =
        let url =
            match filter with
            | Alle -> $"/api/admin/banner?page=%i{page}"
            | Pflege -> $"/api/admin/banner/pflege?page=%i{page}"
            | Therapie -> $"/api/admin/banner/therapie?page=%i{page}"
            | BfsPlus -> $"/api/admin/banner/bfsplus?page=%i{page}"
            | Jugendhilfe -> $"/api/admin/banner/jugendhilfe?page=%i{page}"

        fetchAs<Paginated<AdminBannerListItem>> url
    let getBanner id = fetchAs<AdminBanner> $"/api/admin/banner/%s{id}"

    let postBanner (id, banner) = postRecord<AdminBanner> $"/api/admin/banner/%s{id}" banner
    let inline putBanner banner = promise {
        let! response = putRecord "/api/admin/banner" banner
        let! id = response.json<Guid> ()
        return id
    }

    let postActivate id = postEmpty $"/api/admin/banner/%s{id}/active"
    let postDeactivate id = postEmpty $"/api/admin/banner/%s{id}/inactive"

    let postImageDesktop (id, file) =
        file
        |> uploadFile<DesktopImageUploaded> $"/api/admin/banner/%s{id}/image/desktop"

    let postImageMobile (id, file) =
        file
        |> uploadFile<bool> $"/api/admin/banner/%s{id}/image/mobile"

module State =
    let init page session (lastModel: Model option) =
        match page with
        | BannerList pagenumber ->

            let initialModel = ListModel(BannerListModel.Init pagenumber)
            (initialModel, Cmd.ofMsg (LoadBannerPage pagenumber))

        | BannerEdit id ->
            let backToPage =
                match lastModel with
                | Some(ListModel m) -> m.PageNumber
                | _ -> 1

            let initialModel = ItemModel(BannerModel.Init (Some id) backToPage session)
            (initialModel, Cmd.ofMsg (LoadBanner id))

        | BannerNew ->
            let backToPage =
                match lastModel with
                | Some(ListModel m) -> m.PageNumber
                | _ -> 1

            let initialModel = ItemModel(BannerModel.Init None backToPage session)
            (initialModel, Cmd.ofMsg NewBanner)

    let update msg model =
        match msg, model with
        | LoadBannerPage pagenumber, ListModel model ->
            let cmd =
                BannerPageLoaded
                |> request Http.getBannerList (pagenumber, model.Filter)
            ({ model with Banner = Loading } |> ListModel, cmd)
        | BannerPageLoaded x, ListModel model -> ({ model with Banner = Loaded x } |> ListModel, Cmd.none)

        | FilterChanged filter, ListModel model ->
            ({ model with Filter = filter } |> ListModel, Cmd.ofMsg (LoadBannerPage 1))
        | ActivateBanner id, _ -> (model, ActiveChanged |> request Http.postActivate id)
        | DeactivateBanner id, _ -> (model, ActiveChanged |> request Http.postDeactivate id)
        | ActiveChanged _, ListModel model -> (model |> ListModel, Cmd.ofMsg (LoadBannerPage model.PageNumber))

        | LoadBanner id, ItemModel jobmodel ->
            let model = { jobmodel with Id = Some id }
            (ItemModel model, BannerLoaded |> request Http.getBanner id)

        | BannerLoaded(Ok banner), ItemModel model ->
            ({
                model with
                    Titel = update model.Titel banner.Titel
                    Tag = update model.Tag banner.Tag
                    Text = update model.Text banner.Text
                    TextAsHtml = Fable.Formatting.Markdown.Markdown.ToHtml banner.Text
                    TextFarbe = banner.TextFarbe
                    HintergrundFarbe = banner.HintergrundFarbe
                    GueltigAb =
                        dateUpdate
                            model.GueltigAb
                            model.GueltigAb.DatePickerState
                            (Some banner.GueltigAb)
                            (Some banner.GueltigAb)
                    GueltigBis =
                        dateUpdate
                            model.GueltigBis
                            model.GueltigBis.DatePickerState
                            (Some banner.GueltigBis)
                            (Some banner.GueltigBis)
                    Aktiv = banner.Aktiv
                    KundenTyp = banner.KundenTyp
                    HasImageDesktop = banner.HasImageDesktop
                    HasImageMobile = banner.HasImageMobile
                    IsDirty = false
                    ImageOnly = banner.ImageOnly
             }
             |> ItemModel,
             ValidateImageOnly |> Cmd.ofMsg)
        | BannerLoaded(Error exn), _ -> (model, exn |> errorCmd GlobalMsg "Laden")
        | NewBanner, ItemModel model ->
            ({
                model with
                    Titel = update model.Titel "Neuer Banner"
                    GueltigAb =
                        dateUpdate
                            model.GueltigAb
                            model.GueltigAb.DatePickerState
                            (Some DateTime.Today)
                            (Some DateTime.Today)
                    GueltigBis =
                        dateUpdate
                            model.GueltigBis
                            model.GueltigBis.DatePickerState
                            (Some DateTime.Today)
                            (Some DateTime.Today)
                    TextFarbe = "#4A4A4A"
                    Aktiv = true
                    IsDirty = true
             }
             |> ItemModel,
             Cmd.none)
        | TitelChanged value, ItemModel model ->
            ({
                model with
                    Titel = update model.Titel value
                    IsDirty = true
             }
             |> ItemModel,
             Cmd.none)
        | TagChanged value, ItemModel model ->
            ({
                model with
                    Tag = update model.Tag value
                    IsDirty = true
             }
             |> ItemModel,
             Cmd.none)
        | TextChanged value, ItemModel model ->
            ({
                model with
                    Text = update model.Text value
                    TextAsHtml = Fable.Formatting.Markdown.Markdown.ToHtml value
                    IsDirty = true
             }
             |> ItemModel,
             Cmd.none)
        | GueltigAbChanged(state, date), ItemModel model ->
            ({
                model with
                    GueltigAb = dateUpdate model.GueltigAb state date None
                    IsDirty = true
             }
             |> ItemModel,
             Cmd.none)
        | GueltigBisChanged(state, date), ItemModel model ->
            ({
                model with
                    GueltigBis = dateUpdate model.GueltigBis state date None
                    IsDirty = true
             }
             |> ItemModel,
             Cmd.none)
        | AktivChanged value, ItemModel model ->
            ({
                model with
                    Aktiv = value
                    IsDirty = true
             }
             |> ItemModel,
             Cmd.none)
        | ImageOnlyChanged value, ItemModel model ->
            ({
                model with
                    ImageOnly = value
                    IsDirty = true
             }
             |> ItemModel,
             Cmd.none)
        | KundenTypChanged value, ItemModel model ->
            ({
                model with
                    KundenTyp = value
                    IsDirty = true
             }
             |> ItemModel,
             Cmd.none)
        | TextFarbeChanged value, ItemModel model ->
            ({
                model with
                    TextFarbe = value
                    IsDirty = true
             }
             |> ItemModel,
             Cmd.none)
        | DeleteImageDesktop, ItemModel model ->
            (model |> ItemModel,
             DesktopImageUploaded
             |> request Http.postImageDesktop (model.Id.Value, null))
        | UploadImageDesktop file, ItemModel model ->
            (model |> ItemModel,
             DesktopImageUploaded
             |> request Http.postImageDesktop (model.Id.Value, file))
        | DesktopImageUploaded(Ok result), ItemModel model ->
            ({
                model with
                    HasImageDesktop = result.HasImage
                    HintergrundFarbe = result.HintergrundFarbe
                    UploadTrigger = model.UploadTrigger + 1
             }
             |> ItemModel,
             ValidateImageOnly |> Cmd.ofMsg)
        | DesktopImageUploaded(Error exn), model -> (model, exn |> errorCmd GlobalMsg "Speichern des Bilds")
        | DeleteImageMobile, ItemModel model ->
            (model |> ItemModel,
             MobileImageUploaded
             |> request Http.postImageMobile (model.Id.Value, null))
        | UploadImageMobile file, ItemModel model ->
            (model |> ItemModel,
             MobileImageUploaded
             |> request Http.postImageMobile (model.Id.Value, file))
        | MobileImageUploaded(Ok hasImage), ItemModel model ->
            ({
                model with
                    HasImageMobile = hasImage
                    UploadTrigger = model.UploadTrigger + 1
             }
             |> ItemModel,
             ValidateImageOnly |> Cmd.ofMsg)
        | MobileImageUploaded(Error exn), model -> (model, exn |> errorCmd GlobalMsg "Speichern des Bilds")
        | Save, ItemModel model ->
            let banner = {
                Tag = model.Tag.Value
                Titel = model.Titel.Value
                Text = model.Text.Value
                HasImageDesktop = model.HasImageDesktop
                HasImageMobile = model.HasImageMobile
                HintergrundFarbe = model.HintergrundFarbe
                TextFarbe = model.TextFarbe
                KundenTyp = model.KundenTyp
                GueltigAb = model.GueltigAb.Value.Value
                GueltigBis = model.GueltigBis.Value.Value
                Aktiv = model.Aktiv
                ImageOnly = model.ImageOnly
            }

            let cmd =
                match model.Id with
                | Some id -> Saved |> request Http.postBanner (id, banner)
                | None -> SavedNew |> request Http.putBanner banner

            ({ model with Sending = true } |> ItemModel, cmd)
        | Saved(Ok _), ItemModel model ->
            ({
                model with
                    Sending = false
                    IsDirty = false
             }
             |> ItemModel,
             Cmd.none)
        | Saved(Error e), ItemModel model ->
            ({ model with Sending = false } |> ItemModel, e |> errorCmd GlobalMsg "Speichern")
        | SavedNew(Ok id), ItemModel model ->
            ({
                model with
                    Sending = false
                    IsDirty = false
                    Id = Some(id.ToString())
             }
             |> ItemModel,
             Cmd.none)
        | SavedNew(Error e), ItemModel model ->
            ({ model with Sending = false } |> ItemModel, e |> errorCmd GlobalMsg "Speichern")

        | ValidateImageOnly, ItemModel model ->
            let newModel =
                match model.HasImageDesktop && model.HasImageMobile with
                | true -> { model with ImageOnlyEnabled = true }
                | false -> {
                    model with
                        ImageOnlyEnabled = false
                        ImageOnly = false
                  }

            newModel |> ItemModel, Cmd.none

        | GlobalMsg _, _ -> (model, Cmd.none) // diese Message wird gar nicht hier behandelt, sondern im Dispatcher!

        | _, _ ->
            Logger.log "AdminBannerMessage konnte nicht dispatched werden"
            Logger.log (model, msg)
            (model, Cmd.none)
