namespace ApiToken

open Fable.Core
open Fable.React
open Browser
open Elmish
open Types
open Fable.React.Props
open Http
open Bfs.Web.Data.Service.Contracts.Kundenportal
open System

module Http =
    let createToken (filialeId: Guid) = fetchAs<ApiToken.CreateTokenResult> $"/api/apitoken/create/{filialeId}"
    let getFilialen () = fetchAs<ApiToken.Filiale list> "api/apitoken"
    let deleteToken (id: int) = delete<int> $"/api/apitoken/{id}"

module State =
    let private msgBoxBodyElement tokenValue =
        div [ Class "paragraphs" ] [
            p [] [
                str "Es wurde ein neues API-Token erstellt."
            ]
            p [] [
                str
                    "Dieses API-Token müssen Sie nun der Anwendung, die auf Ihr BFS-Konto zugreifen soll, zur Verfügung stellen. "
                str "Kopieren Sie das API-Token bitte jetzt. Ein späteres Auslesen ist nicht mehr möglich."
            ]

            div [ Class "field has-addons" ] [
                div [ Class "control" ] [
                    input [
                        Class "input"
                        ReadOnly true
                        Value tokenValue
                        Style [ MinWidth "500px" ]
                    ]
                ]
                div [ Class "control" ] [
                    a [
                        Class "button is-info"
                        OnClick(fun _ -> copyToClipboard tokenValue)
                    ] [ str "Kopieren" ]
                ]
            ]
        ]

    let init page (user: UserSession option) lastModel = Model.Init, Cmd.ofMsg LoadFilialen

    let update msg (model: Model) =
        match msg with
        | LoadFilialen ->
            let model' = { model with Filialen = Loading }
            let cmd = FilialenLoaded |> request Http.getFilialen ()
            model', cmd
        | FilialenLoaded filialen ->
            let selectedFiliale =
                match filialen with
                | Ok(f :: _) -> f.Id |> Some
                | _ -> None
            let model' = {
                model with
                    Filialen = Loaded filialen
                    SelectedFiliale = selectedFiliale
            }
            model', Cmd.none

        | SwitchFiliale id ->
            let model' = { model with SelectedFiliale = Some id }
            model', Cmd.none

        | DeleteToken id ->
            let delMsg =
                sprintf
                    "Soll das API-Token wirklich gelöscht werden?\n\nDie Anwendung, die dieses API-Token derzeit verwendet, kann dann nicht mehr auf Ihr BFS-Konto zugreifen."
            let box =
                GlobalMessageBox.ConfirmationBox
                    "API-Token löschen"
                    delMsg
                    (Some((DeleteTokenConfirmed id) :> AnyWebPartMsg))
            let msg = box |> ShowMessageBox |> GlobalMsg
            (model, Cmd.ofMsg msg)
        | DeleteTokenConfirmed id ->
            let cmd = TokenDeleted |> request Http.deleteToken id
            model, cmd
        | TokenDeleted(Ok id) ->
            let filialen' =
                match model.Filialen with
                | Body filialen ->
                    filialen
                    |> List.map (fun f -> {
                        f with
                            Tokens = f.Tokens |> List.filter (fun t -> t.Id <> id)
                    })
                    |> Body
                | _ -> model.Filialen
            let model' = { model with Filialen = filialen' }
            model', Cmd.none
        | TokenDeleted(Error exn) -> model, exn |> errorCmd GlobalMsg "Löschen"

        | CreateToken filialeId ->
            let cmd = TokenCreated |> request Http.createToken filialeId
            model, cmd
        | TokenCreated(Ok createTokenResult) ->
            let tokenToAdd: ApiToken.Token = {
                Id = createTokenResult.Id
                Erstellt = createTokenResult.Erstellt
                LetzteNutzung = None
            }
            let filialen' =
                match model.Filialen with
                | Body filialen ->
                    filialen
                    |> List.map (fun f -> {
                        f with
                            Tokens =
                                if f.Id = createTokenResult.FilialeId then
                                    [ tokenToAdd ] @ f.Tokens
                                else
                                    f.Tokens
                    })
                    |> Body
                | _ -> model.Filialen
            let model' = { model with Filialen = filialen' }
            let messageBox: GlobalMessageBox = {
                Type = InfoBox
                Title = "Neues Token erstellt"
                BodyElement = msgBoxBodyElement createTokenResult.Value
                Buttons = [ OkButton None ]
            }
            model', Cmd.ofMsg (GlobalMsg(ShowMessageBox messageBox))
        | TokenCreated(Error exn) -> model, exn |> errorCmd GlobalMsg "Tokenerstellen"

        | GlobalMsg(_) -> failwith "Not Implemented"
