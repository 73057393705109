﻿module Bfs.Web.Portale.Collapsable

open System
open Browser
open Browser.Types
open Fable.React
open Fable.React.Props

// Muss der gleiche Wert wie in der _collapsable.scss sein!
[<Literal>]
let private AnimationDuration = 500

let private attachWindowListener (f: Event -> unit) (node: Window) (eventType: string) =
    node.addEventListener (eventType, f)
    { new IDisposable with
        member __.Dispose() = node.removeEventListener (eventType, f)
    }

let CollapsableElement =
    FunctionComponent.Of(fun (childElement: ReactElement, expanded: bool) ->

        let isExpanded = Hooks.useState (expanded)
        let height = Hooks.useState<double> 0
        let childRef = Hooks.useRef<Element option> (None)

        let containerRef = Hooks.useRef<Element option> (None)
        let containerExpandedClass = Hooks.useState ""
        let containerExpandedTimout = Hooks.useState<float option> None

        let computeHeight () =
            height.update (if isExpanded.current then childRef.current.Value.scrollHeight else 0)

        Hooks.useEffect ((fun _ -> isExpanded.update expanded), [| box expanded |])
        Hooks.useEffect (
            (fun _ ->
                if isExpanded.current then
                    computeHeight ()),
            [| box isExpanded; box childElement |]
        )

        // Bei einer Änderung der Fenstergröße müssen wir die Höhe neu berechnen, da sich der Inhalt an die neue Breite
        // anpassen kann.
        Hooks.useEffectDisposable (
            (fun () ->
                (window, "resize")
                ||> attachWindowListener (fun _ -> computeHeight ())),
            [||]
        )

        // Nach der Animation setzten wir die Höhe auf auto, damit die Collapsable verschachtelt werden können
        Hooks.useEffect (
            (fun _ ->
                if containerExpandedTimout.current.IsSome then
                    window.clearTimeout containerExpandedTimout.current.Value

                match isExpanded.current with
                | false ->
                    containerExpandedClass.update ""
                    window.setTimeout ((fun _ -> computeHeight ()), 10, [||])
                    |> ignore
                | true ->
                    let timeoutId =
                        (window.setTimeout (
                            (fun _ -> containerExpandedClass.update "fully-expanded"),
                            AnimationDuration,
                            [||]
                        ))
                    containerExpandedTimout.update (timeoutId |> Some)

            ),
            [| box isExpanded.current |]
        )

        div [
            Style [ CSSProp.Height $"{height.current}px" ]
            Class $"collapsable {containerExpandedClass.current}"
            RefValue containerRef
        ] [
            div [ RefValue childRef ] [ childElement ]
        ])
