module Login.Types

open Types
open Validation
open Bfs.Web.Data.Service.Contracts.Kundenportal
open Bfs.Web.Data.Service.Contracts.Kundenportal.Account

type Page = Login of string option

type Msg =
    | CheckBrowser
    | CheckLogin
    | LoginChecked of Result<UserStatus, exn>
    | NameChanged of string
    | PasswordChanged of string
    | OtpChanged of string
    | NutzungsbedingungenAkzeptiert
    | PrintNutzungsbedingungen of string
    | Submit of bool option
    | Submitted of Result<Account.LoginResult, exn>
    | GlobalMsg of GlobalMsg

type Model = {
    Name: FormField
    Password: FormField
    Otp: FormField
    OtpRequired: bool
    Loading: bool
    Sending: bool
    ErrorMsg: string option
    WartungMsg: string option
    GotoAfterLogin: string option
} with
    static member Init(goto) = {
        Name = FormField.Init(Some Mandatory)
        Password = FormField.Init(Some Mandatory)
        Otp = FormField.Init(Some Mandatory)
        Loading = true
        Sending = false
        OtpRequired = false
        ErrorMsg = None
        WartungMsg = None
        GotoAfterLogin = goto
    }
    member x.CanSubmit =
        not x.Loading
        && not x.Sending
        && x.Name.IsValid
        && x.Password.IsValid
        && (not x.OtpRequired || x.Otp.IsValid)
        && x.WartungMsg.IsNone
