module.exports = {
    isRunningAsPwa: function() {
        return (
            'serviceWorker' in navigator
            && 'PushManager' in window
            && window.matchMedia('(display-mode: standalone)').matches
        );
    },

    isOnIos: function() {
        return (
            [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod'
            ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
        );
    },

    promptInstall: function(event) {
        return event.prompt()
    }
}
