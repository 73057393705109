namespace Sendungen

open System
open Bfs.Web.Data.Service.Contracts
open Bfs.Web.Data.Service.Contracts.Kundenportal.Events
open Elmish
open Types
open Http
open Bfs.Web.Data.Service.Contracts.Kundenportal.Sendungen
open Bfs.Web.Shared.Formating

module Http =
    let getFilialen () = fetchAs<Filiale[]> "/api/sendungen/filialen"
    let getSendungen (filialeId: Guid) = fetchAs<Sendung[]> $"/api/sendungen/filialen/{filialeId}"
    let postSendungDateiUpload (sendungsId, file) =
        file
        |> uploadFile<UploadResult> $"/api/sendungen/{sendungsId}/dateien/"
    let deleteSendungDateiUpload (sendungId: Guid, fileId: Guid) =
        delete<Guid> $"/api/sendungen/{sendungId}/dateien/{fileId}"
    let getVereinbarung prepareAgreementData =
        postRecord3<SendungsOptionData> "/api/sendungen/option" prepareAgreementData
    let postAuftragErteilen (prepareAgreementData: SendungsOptionData) =
        postRecord "/api/sendungen/auftrag" prepareAgreementData
    let getFlexitarif sendungId = fetchAs<FlexitarifFuerSendung> $"/api/sendungen/{sendungId}/flexitarif"
    let postFlexitarif (sendungId: Guid, flexitarifAuswahl: FlexitarifAuswahl) =
        postRecord2<FlexitarifAuswahl, FlexitarifResult> $"/api/sendungen/{sendungId}/flexitarif" flexitarifAuswahl

module State =

    let private SESSION_DATA_KEY_INFO_BOX_HIDDEN = "SendungenInfoBoxHidden"

    let private getSendungsOptionData model =
        match model.SelectedSendung, model.SelectedSendungsOption with
        | Some sendung, Some SendungsOption.Beleglos -> {
            SendungId = sendung.SendungId
            SendungsOption = Beleglos
            DateiIds = model.CurrentUploadList |> List.map (fun u -> u.Id)
          }
        | Some sendung, Some SendungsOption.Mischsendung -> {
            SendungId = sendung.SendungId
            SendungsOption = Mischsendung
            DateiIds = model.CurrentUploadList |> List.map (fun u -> u.Id)
          }
        | Some sendung, Some SendungsOption.DokumentenDepot -> {
            SendungId = sendung.SendungId
            SendungsOption = DokumentenDepot
            DateiIds = List.empty
          }
        | _ -> failwith "bad model state"

    let private createPrintLegalCmd title content okCaption okMsg =
        {
            Type = InfoBox
            Title = title
            BodyElement = Fable.React.Helpers.str content
            Buttons = [
                {
                    Caption = okCaption
                    Type = GlobalMessageBoxButtonType.CancelButton
                    Message = Some(okMsg :> AnyWebPartMsg)
                    ClosesDialog = true
                }
                {
                    Caption = "Drucken"
                    Type = GlobalMessageBoxButtonType.YesButton
                    Message = Some(PrintLegal(title, content) :> AnyWebPartMsg)
                    ClosesDialog = false
                }
                {
                    Caption = "Abbrechen"
                    Type = GlobalMessageBoxButtonType.OkButton
                    Message = None
                    ClosesDialog = true
                }
            ]
        }
        |> ShowMessageBox
        |> GlobalMsg
        |> Cmd.ofMsg

    let init page user lastModel =
        let infoBoxHidden =
            user
            |> Option.map (fun u ->
                u.SessionData
                |> Map.containsKey SESSION_DATA_KEY_INFO_BOX_HIDDEN)
            |> Option.defaultValue false

        let model = {
            Model.Init with
                ShowInfoBox = not infoBoxHidden
        }

        match page with
        | Sendungen None ->
            model,
            [
                (Cmd.ofMsg LoadEventSettings)
                (Cmd.ofMsg LoadFilialen)
            ]
            |> Cmd.batch

        | Sendungen(Some filialeId) ->
            ({
                model with
                    SelectedFilialeId = Some filialeId
             },
             Cmd.batch [
                 (Cmd.ofMsg LoadFilialen)
                 (Cmd.ofMsg (LoadSendungen filialeId))
                 (Cmd.ofMsg LoadEventSettings)
             ])

    let update msg (model: Model) =
        match msg with
        | LoadFilialen ->
            let cmd = FilialenLoaded |> request Http.getFilialen ()
            ({ model with Filialen = Loading }, cmd)
        | FilialenLoaded(Ok filialen) ->
            let zentrale = filialen |> Seq.tryFind (fun f -> f.IsZentrale)

            let filialeId =
                match zentrale, filialen with
                | Some f, _ -> Some f.FilialeId
                | _, [| f |] -> Some f.FilialeId
                | _, [||] -> None
                | _, filialen -> Some filialen.[0].FilialeId
            let cmd =
                if
                    model.SelectedFilialeId.IsSome // wir haben schon eine Filiale ausgewählt
                then
                    Cmd.none
                else
                    filialeId
                    |> Option.map (fun f -> Cmd.ofMsg (LoadSendungen f))
                    |> Option.defaultValue Cmd.none

            ({
                model with
                    Filialen = (Body filialen)
             },
             cmd)
        | FilialenLoaded(Error ex) ->
            ({
                model with
                    Filialen = (LoadError "Filialen konnten nicht geladen werden.")
             },
             Cmd.none)

        | LoadSendungen filialeId ->
            let cmd = SendungenLoaded |> request Http.getSendungen (filialeId)
            ({
                model with
                    Sendungen = Loading
                    SelectedFilialeId = Some filialeId
             },
             cmd)
        | SendungenLoaded(Ok sendungen) ->
            ({
                model with
                    Sendungen = (Body sendungen)
             },
             Cmd.none)
        | SendungenLoaded(Error ex) ->
            ({
                model with
                    Sendungen = (LoadError "Sendungen konnten nicht geladen werden.")
             },
             Cmd.none)

        | SwitchFiliale filialeId -> (model, Cmd.ofMsg (LoadSendungen filialeId))

        | ShowUpload sendung ->
            ({
                model with
                    SelectedSendung = Some sendung
                    SelectedSendungsOption = Some Beleglos
             },
             Cmd.none)
        | CancelUpload ->
            let model' = {
                model with
                    SelectedSendung = None
                    SelectedSendungsOption = None
                    CurrentUploadCount = 0
                    CurrentUploadList = List.empty
            }
            (model', Cmd.none)

        | UploadFiles(sendungsId, files) ->
            let biggestFile = files |> List.maxBy (fun f -> int f.size)

            if ((biggestFile.size) > 50 * 1024 * 1024) then
                let cmd =
                    Cmd.ofMsg (
                        GlobalMsg(
                            ShowMessageBox(
                                GlobalMessageBox.Error "Die Sendungs-Dateien dürfen maximal 50 MB groß sein."
                            )
                        )
                    )
                (model, cmd)
            else
                let cmd =
                    files
                    |> List.map (fun f ->
                        FileUploaded
                        |> request Http.postSendungDateiUpload (sendungsId, f))
                    |> Cmd.batch
                ({
                    model with
                        CurrentUploadCount = model.CurrentUploadCount + files.Length
                 },
                 cmd)
        | FileUploaded(Ok body) ->
            let model' = {
                model with
                    CurrentUploadCount = model.CurrentUploadCount - 1
                    CurrentUploadList = (List.append model.CurrentUploadList [ body ])
            }
            (model', Cmd.none)
        | FileUploaded(Error exn) ->
            ({
                model with
                    CurrentUploadCount = model.CurrentUploadCount - 1
             },
             exn |> errorCmd GlobalMsg "Upload")

        | DeleteUploadedFile file ->
            let sendungId = model.SelectedSendung.Value.SendungId
            let cmd =
                UploadedFileDeleted
                |> request Http.deleteSendungDateiUpload (sendungId, file.Id)
            ({ model with Sending = true }, cmd)
        | UploadedFileDeleted(Ok id) ->
            let model' = {
                model with
                    Sending = false
                    CurrentUploadList = (List.filter (fun x -> not (x.Id = id)) model.CurrentUploadList)
            }
            (model', Cmd.none)
        | UploadedFileDeleted(Error exn) -> ({ model with Sending = false }, exn |> errorCmd GlobalMsg "Löschen")

        | SelectSendungsOption sendungsOption ->
            ({
                model with
                    SelectedSendungsOption = Some sendungsOption
             },
             Cmd.none)

        | UseDokumentenDepot sendung ->
            let model' = {
                model with
                    SelectedSendung = Some sendung
                    SelectedSendungsOption = Some SendungsOption.DokumentenDepot
            }
            let message =
                "Alle Dokumente zu dieser Sendung befinden sich bereits im BFS-Dokumenten-Depot und die Sendung kann ohne weitere Originalbelege bearbeitet werden."
            let cmd =
                {
                    Type = InfoBox
                    Title = $"Sendung {sendung.SendungsNr}"
                    BodyElement = Fable.React.Helpers.str message
                    Buttons = [
                        {
                            Caption = "Bestätigen"
                            Type = GlobalMessageBoxButtonType.CancelButton
                            Message = Some(AuftragErteilen :> AnyWebPartMsg)
                            ClosesDialog = true
                        }
                        {
                            Caption = "Abbrechen"
                            Type = GlobalMessageBoxButtonType.OkButton
                            Message = Some(CancelUseDokumentenDepot :> AnyWebPartMsg)
                            ClosesDialog = true
                        }
                    ]
                }
                |> ShowMessageBox
                |> GlobalMsg
                |> Cmd.ofMsg
            (model', cmd)
        | CancelUseDokumentenDepot ->
            let model' = {
                model with
                    SelectedSendung = None
                    SelectedSendungsOption = None
            }
            (model', Cmd.none)

        | GetVereinbarungForUploads ->
            let data = getSendungsOptionData model
            let cmd =
                GotVereinbarungForUploads
                |> request Http.getVereinbarung data
            (model, cmd)
        | GotVereinbarungForUploads(Ok s) ->
            let cmd =
                createPrintLegalCmd "Druckauftrag" s "Kostenpflichtig übertragen" AuftragErteilen
            (model, cmd)
        | GotVereinbarungForUploads(Error exn) ->
            (model,
             exn
             |> errorCmd GlobalMsg "Erstellen der Einverständniserklärung")

        | PrintLegal(title, content) ->
            Preview.webPrint title content
            (model, Cmd.none)

        | AuftragErteilen ->
            let data = getSendungsOptionData model
            let cmd = AuftragErteilt |> request Http.postAuftragErteilen data
            (model, cmd)

        | AuftragErteilt(Ok _) ->
            let model' = {
                model with
                    SelectedSendung = None
                    SelectedSendungsOption = None
                    CurrentUploadCount = 0
                    Sending = false
                    CurrentUploadList = List.empty
            }
            let dateien =
                match model.CurrentUploadList.Length with
                | 0 -> "die Sendung"
                | 1 -> "eine Datei"
                | n -> $"{n} Dateien"
            let message = $"Der Auftrag für {dateien} wurde erfolgreich erteilt."
            let cmd1 =
                GlobalMessageBox.SuccessWithTitle "Auftrag erfolgreich erteilt" message
                |> ShowMessageBox
                |> GlobalMsg
                |> Cmd.ofMsg
            let cmd2 = (LoadSendungen model.SelectedFilialeId.Value) |> Cmd.ofMsg
            (model', Cmd.batch [ cmd1; cmd2 ])

        | AuftragErteilt(Error exn) -> (model, exn |> errorCmd GlobalMsg "Erteilen des Auftrags")

        | GetFlexitarif sendung ->
            let cmd =
                GotFlexitarif
                |> request Http.getFlexitarif sendung.SendungId
            (model, cmd)
        | GotFlexitarif(Ok r) ->
            match r.AuszahlungstageMitEntgelt with
            | [||] ->
                let cmd =
                    GlobalMessageBox.ErrorWithTitle
                        "Kein Flexitarif möglich"
                        "Da keine Auszahlungstage zur Auswahl stehen, ist die Wahl des Flexitarifs nicht möglich."
                    |> ShowMessageBox
                    |> GlobalMsg
                    |> Cmd.ofMsg
                (model, cmd)
            | _ ->
                let zahltag = (Array.last r.AuszahlungstageMitEntgelt).Auszahlungstag
                let flexitarifAuswahl = {
                    AusfuehrungZahlung = zahltag
                    Blitzueberweisung = false
                }
                let model' = {
                    model with
                        Flexitarif = Some r
                        FlexitarifAuswahl = Some flexitarifAuswahl
                }
                let cmd =
                    if r.BelegeingangErfasst then
                        GlobalMessageBox.WarningWithTitle
                            "Belegeingang bereits erfasst"
                            "Belegeingang bereits erfasst! Wir können eine Vorziehung der Auszahlung nicht zusichern. Bitte beachten Sie in diesem Fall den Hinweis in der Zusatzvereinbarung."
                        |> ShowMessageBox
                        |> GlobalMsg
                        |> Cmd.ofMsg
                    else
                        Cmd.none
                (model', cmd)
        | GotFlexitarif(Error exn) -> (model, exn |> errorCmd GlobalMsg "Erstellen des Flexitarifs")
        | CancelFlexitarif ->
            let model' = {
                model with
                    Flexitarif = None
                    FlexitarifAuswahl = None
                    AgbAkzeptiert = false
            }
            (model', Cmd.none)
        | SelectAuszahlungstag tag ->
            let flexitarifAuswahl = {
                model.FlexitarifAuswahl.Value with
                    AusfuehrungZahlung = tag
            }
            ({
                model with
                    FlexitarifAuswahl = Some flexitarifAuswahl
             },
             Cmd.none)
        | ToggleBlitzueberweisung ->
            let flexAuswahl =
                match model.FlexitarifAuswahl with
                | Some flexitarifAuswahl ->
                    Some {
                        flexitarifAuswahl with
                            Blitzueberweisung = not flexitarifAuswahl.Blitzueberweisung
                    }
                | None -> None
            ({
                model with
                    FlexitarifAuswahl = flexAuswahl
             },
             Cmd.none)
        | ToggleAgb ->
            ({
                model with
                    AgbAkzeptiert = not model.AgbAkzeptiert
             },
             Cmd.none)
        | AcceptAgb -> ({ model with AgbAkzeptiert = true }, Cmd.none)
        | ShowAgb agb ->
            let cmd =
                createPrintLegalCmd "Zusatzvereinbarung" agb "Zusatzvereinbarung akzeptieren" AcceptAgb
            (model, cmd)
        | RequestFlexitarif ->
            match model.Flexitarif, model.FlexitarifAuswahl with
            | Some t, Some a ->
                let cmd =
                    RequestedFlexitarif
                    |> request Http.postFlexitarif (t.SendungId, a)
                (model, cmd)
            | _ -> failwith "bad model state"
        | RequestedFlexitarif(Ok r) ->
            let model' = {
                model with
                    Flexitarif = None
                    FlexitarifAuswahl = None
                    AgbAkzeptiert = false
            }
            let entgelt =
                if r.Blitzueberweisung then
                    r.GebuehrBlitzueberweisung + r.Entgelt
                else
                    r.Entgelt
            let message =
                $"Der Flexitarif für Sendung {r.SendungsNr} wurde erfolgreich gewählt. Das Entgelt beläuft sich auf {asMoney entgelt}."
            let cmd1 =
                GlobalMessageBox.SuccessWithTitle "Flexitarif erfolgreich gewählt" message
                |> ShowMessageBox
                |> GlobalMsg
                |> Cmd.ofMsg
            let cmd2 = (LoadSendungen model.SelectedFilialeId.Value) |> Cmd.ofMsg
            (model', Cmd.batch [ cmd1; cmd2 ])
        | RequestedFlexitarif(Error exn) -> (model, exn |> errorCmd GlobalMsg "Wählen des Flexitarifs")

        | HideInfoBox ->
            let cmd =
                Cmd.ofMsg (GlobalMsg(GlobalMsg.UpdateUserSessionData(SESSION_DATA_KEY_INFO_BOX_HIDDEN, "")))
            ({ model with ShowInfoBox = false }, cmd)

        | LoadEventSettings ->
            let request () = fetchAs<EventSettings> "/api/events/settings"
            model, (LoadEventSettingsCompleted |> Http.request request ())
        | LoadEventSettingsCompleted(Error _) ->
            Logger.error "Die Einstellungen für das Ostergewinnspiel konnten nicht geladen werden"
            model, Cmd.none
        | LoadEventSettingsCompleted(Ok settings) ->
            {
                model with
                    OstergewinnspielActive = settings.EasterEvent.IsSome
            },
            Cmd.none

        | GlobalMsg _ -> failwith "Not Implemented"
