module AdminNews.Types

open Bfs.Web.Data.Service.Contracts
open Bfs.Web.Data.Service.Contracts.Kundenportal
open Bfs.Web.Data.Service.Contracts.Kundenportal.News
open Bfs.Web.Data.Service.Contracts.Kundenportal.Admin
open Types
open Fetch
open Fulma.Elmish
open System
open Validation
open Preview

type Page =
    | NewsList of page: int
    | NewsEdit of id: string
    | NewsNew

type Filter =
    | Alle
    | Pflege
    | Therapie
    | BfsPlus
    | Jugendhilfe
    | Kundennummer of int

type Msg =
    | LoadNewsPage of page: int
    | NewsPageLoaded of Result<Paginated<AdminNewsListItem>, exn>

    | FilterChanged of Filter
    | KundennummernFilterTextChanged of string
    | FilterForKundennummer

    | ActivateNews of id: string
    | DeactivateNews of id: string
    | ActiveChanged of Result<Response, exn>

    | NewNews
    | LoadNews of id: string
    | NewsLoaded of Result<AdminInfos<AdminNews>, exn>

    | LoadAllKundennummern
    | AllKundennummernLoaded of Result<int list, exn>

    | TitelChanged of string
    | TextChanged of string
    | GueltigAbChanged of DatePicker.Types.State * (DateTime option)
    | AktivChanged of bool
    | KundenTypChanged of KundenTyp option
    | KundennummernAddTextChanged of string
    | AddKundennummer
    | RemoveKundennummer of int

    | DeleteImage
    | UploadImage of Browser.Types.Blob
    | ImageUploaded of Result<bool, exn>

    | Save
    | Saved of Result<Response, exn>
    | SavedNew of Result<Guid, exn>

    | GlobalMsg of GlobalMsg

type Model =
    | ListModel of NewsListModel
    | ItemModel of NewsModel
and NewsListModel = {
    PageNumber: int
    Filter: Filter
    News: Remote<Paginated<AdminNewsListItem>>
    AllKundennummern: int list
    KundennummernFilterText: string
    KundennummernFilterEnabeld: bool
} with
    static member Init pagenumber allKundennummern = {
        PageNumber = pagenumber
        News = Loading
        Filter = Alle
        AllKundennummern = allKundennummern
        KundennummernFilterText = ""
        KundennummernFilterEnabeld = false
    }
and NewsModel = {
    Id: string option
    BackToPage: int

    Titel: FormField
    Text: FormField
    GueltigAb: DateFormField
    Aktiv: bool
    KundenTyp: KundenTyp option
    Kundennummern: int list
    HasImage: bool
    ImageUpdateTrigger: int

    AllKundennummern: int list
    KundennummernAddText: string
    KundennummernAddEnabeld: bool

    IsDirty: bool
    Sending: bool

    TextAsHtml: string
} with
    static member Init id backToPage allKundennummern = {
        Id = id
        BackToPage = backToPage
        Titel = FormField.Init(Some(MandatoryWithMaxLength 200))
        Text = FormField.Init(Some Mandatory)
        GueltigAb = DateFormField.Init(Some MandatoryDate)
        KundenTyp = None
        Kundennummern = []
        AllKundennummern = allKundennummern
        Aktiv = true
        Sending = false
        HasImage = false
        ImageUpdateTrigger = 0
        IsDirty = false
        TextAsHtml = ""
        KundennummernAddText = ""
        KundennummernAddEnabeld = false
    }
    member x.CanSubmit =
        not x.Sending
        && x.Titel.IsValid
        && x.Text.IsValid
        && x.GueltigAb.IsValid
