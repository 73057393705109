namespace Bfs.Web.Data.Service.Contracts

type Paginated<'a> = {
    Page: int
    PageSize: int
    PageCount: int
    Items: 'a list
}

type BoolResult = { Value: bool }

type AnsprechpartnerkontaktData = {
    AnsprechpartnerName: string
    Telefon: string option
    HatFoto: bool
}

type UploadResult = {
    Id: System.Guid
    Dateiname: string
    Size: int
}

module Headers =
    let kundenPortalAuth = "BfsBackendKundenPortalAuth"
    let patientenPortalAuth = "BfsBackendPatientenPortalAuth"
    let apiToken = "ApiToken"

module TextTableKeys =

    [<Literal>]
    let WartungsMeldung = "Wartungsmeldung"

    [<Literal>]
    let Nutzungsbedingungen = "Nutzungsbedingungen"

module Events =
    type EasterEgg =
        | Dashboard
        | Tickets
        | Quickfinder
