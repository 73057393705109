module Dashboard.NeusteDokumente

open Bfs.Web.Data.Service.Contracts
open Bfs.Web.Data.Service.Contracts.Kundenportal.Dashboard
open Bfs.Web.Kundenportal.WebParts.User.Shared.Tile

open Bfs.Web.Data.Service.Contracts.Kundenportal.File
open Fable.React
open Fable.React.Props
open Elmish

open Types
open Http
open ViewParts

open Dashboard.Shared

open Bfs.Web.Shared.Formating

type Model = {
    GlobalDispatch: GlobalMsg -> unit
    NavigateTo: AnyPage -> unit
    Loading: bool
    Data: FileListItem list
    Benachrichtigung: bool
    SavingToggle: bool
}

type Msg =
    | LoadData
    | LoadDataCompleted of Result<Paginated<FileListItem>, exn>
    | NavigateToList
    | ToggleBenachrichtigung
    | NotificationToggled of Result<Fetch.Types.Response, exn>

module Http =
    let loadFiles () = fetchAs<Paginated<FileListItem>> "/api/file?page=1&onlyNew=false"
    let setNotificationForNeueDokumente enabled =
        let payload = { Enabled = enabled }
        postRecord<ToggleNotificationRequest> "/api/dashboard/set-notification-for-neue-dokumente" payload

module State =
    let init (props: DashboardProps) =
        {
            GlobalDispatch = props.GlobalDispatch
            NavigateTo = props.NavigateTo
            Loading = true
            Data = []
            Benachrichtigung = false
            SavingToggle = true
        },
        Cmd.ofMsg LoadData

    let update (msg: Msg) (model: Model) : Model * Cmd<Msg> =
        match msg with
        | LoadData ->
            let cmd = LoadDataCompleted |> request Http.loadFiles ()
            {
                model with
                    Loading = false
                    SavingToggle = true
            },
            cmd
        | LoadDataCompleted(Ok data) ->
            {
                model with
                    Loading = false
                    Data = data.Items
                    SavingToggle = false
            },
            Cmd.none
        | LoadDataCompleted(Error _) ->
            let msg =
                GlobalMessageBox.Error "Ihre neusten Dokumente können zur Zeit leider nicht geladen werden."
                |> ShowMessageBox
            model.GlobalDispatch msg
            { model with Loading = false }, Cmd.none
        | NavigateToList ->
            model.NavigateTo(Files.Types.Page.Files(1, Kundenportal.Types.Filter.NurNeue))
            model, Cmd.none
        | ToggleBenachrichtigung ->
            let newValue = not model.Benachrichtigung
            let cmd =
                NotificationToggled
                |> request Http.setNotificationForNeueDokumente newValue
            {
                model with
                    SavingToggle = false
                    Benachrichtigung = newValue
            },
            cmd
        | NotificationToggled(Ok _) -> { model with SavingToggle = false }, Cmd.none
        | NotificationToggled(Error _) ->
            let msg =
                GlobalMessageBox.Error
                    "Ihre neuen Benachrichtigungseinstellungen können zur Zeit leider nicht gespeichert werden."
                |> ShowMessageBox
            model.GlobalDispatch msg
            {
                model with
                    SavingToggle = false
                    Benachrichtigung = (not model.Benachrichtigung)
            },
            Cmd.none

let View =
    FunctionComponent.Of(fun (props: DashboardProps) ->
        let model, dispatch = React.useElmish ((State.init props), State.update)
        let title = str "Neueste Dokumente"
        let titleActions =
            Some(
                a [
                    OnClick(fun _ -> NavigateToList |> dispatch)
                    Class ""
                ] [ navigationArrow ]
            )
        let footer =
            match model.Loading with
            | true -> None
            | false ->
                Some(
                    notificationBox
                        "Benachrichtigung"
                        model.Benachrichtigung
                        model.SavingToggle
                        (fun _ -> (ToggleBenachrichtigung |> dispatch))
                        "Neuste-Dokumente-Toggle"
                )
        let body =
            match model.Loading, model.Data.IsEmpty with
            | true, _ -> spinner
            | false, true -> div [ Class "has-text-centered" ] [ str "Keine Dokumente vorhanden" ]
            | false, false ->
                div [ Class "dokumente" ] [
                    ul
                        []
                        (model.Data
                         |> List.truncate 3
                         |> List.mapi (fun index doc ->
                             li [ Key(doc.Id.ToString()) ] [
                                 div [ Class "dokument-nummer" ] [ str ((index + 1).ToString()) ]
                                 div [ Class "dokument-data" ] [
                                     div [
                                         Class "dokument-name"
                                         Title doc.Titel
                                     ] [ str doc.Titel ]
                                     div [
                                         Class "is-size-7 has-text-grey-light"
                                     ] [ str (doc.Datum |> asString) ]
                                 ]
                             ]))
                ]

        bfsTile title titleActions None body footer)
