module Profile.Types

open Bfs.Web.Data.Service.Contracts
open Bfs.Web.Data.Service.Contracts.Kundenportal.Account
open Types
open Validation

type Page = | PasswordChange

type OtpMsg =
    | DeactivateOtpCheckboxChanged of bool
    | OtpChanged of string

    | ActivateOtp
    | ActivationLoaded of Result<OtpActivation, exn>
    | ConfirmOtp
    | ConfirmationLoaded of Result<Fetch.Types.Response, exn>
    | DeactivateOtp
    | DeactivationLoaded of Result<Fetch.Types.Response, exn>

type PasswordMsg =
    | OldPasswordChanged of string
    | NewPassword1Changed of string
    | NewPassword2Changed of string
    | SubmitPassword
    | PasswordReqeustDone of Result<string, exn>

type Msg =
    | LoadUserInfo
    | UserInfoLoaded of Result<UserInfo, exn>

    | Otp of OtpMsg
    | Password of PasswordMsg

    | GlobalMsg of GlobalMsg

type OtpStatus =
    | OtpDeactivated
    | OtpActivating
    | OtpActivated of ShouldDeactivate: bool

type OtpModel = {
    Status: OtpStatus
    OtpField: FormField
    ErrorMsg: string option
    Secret: string
    QrCode: string
} with
    member x.CanSubmit = x.OtpField.IsValid

type PasswordModel = {
    OldPassword: FormField
    NewPassword1: FormField
    NewPassword2: FormField
    ErrorMsg: string option
    OkMsg: string option
} with
    member x.CanSubmit =
        x.OldPassword.IsValid
        && x.NewPassword1.IsValid
        && x.NewPassword2.IsValid
    static member Init okMsg = {
        OldPassword = FormField.Init(Some Mandatory)
        NewPassword1 = FormField.Init(Some Mandatory)
        NewPassword2 = FormField.Init(Some Mandatory)
        ErrorMsg = None
        OkMsg = okMsg
    }

type Model = {
    Name: string
    UserInfo: Remote<UserInfo>
    Otp: OtpModel option
    Password: PasswordModel option
} with
    static member InitPassword name = {
        UserInfo = Loading
        Name = name
        Otp =
            Some(
                {
                    Status = OtpDeactivated
                    OtpField = FormField.Init(Some Mandatory)
                    ErrorMsg = None
                    Secret = ""
                    QrCode = ""
                }
            )
        Password = Some(PasswordModel.Init None)
    }
