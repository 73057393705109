module Bfs.Web.Shared.Formating

let monatName =
    function
    | 1 -> "Januar"
    | 2 -> "Februar"
    | 3 -> "März"
    | 4 -> "April"
    | 5 -> "Mai"
    | 6 -> "Juni"
    | 7 -> "Juli"
    | 8 -> "August"
    | 9 -> "September"
    | 10 -> "Oktober"
    | 11 -> "November"
    | 12 -> "Dezember"
    | _ -> ""

let getMonthStartAndEnd (date: System.DateTime) =
    let first = new System.DateTime(date.Year, date.Month, 1, 0, 0, 0)
    let last = first.AddMonths(1).AddSeconds(-1)
    (first, last)

let asString (date: System.DateTime) = sprintf "%02i.%02i.%04i" date.Day date.Month date.Year

let asStringWithTime (date: System.DateTime) =
    sprintf "%02i.%02i.%04i %02i:%02i Uhr" date.Day date.Month date.Year date.Hour date.Minute

let asLongString (date: System.DateTime) = sprintf "%i. %s %04i" date.Day (date.Month |> monatName) date.Year

let asMonatJahr (date: System.DateTime) = sprintf "%s %04i" (date.Month |> monatName) date.Year

let private asMoney2 einheit (v: decimal) =
    let (vInt, vorzeichen) =
        if v < 0m then (int (-v * 100m), "-") else (int (v * 100m), "")
    let nachkomma = (vInt % 100).ToString().PadLeft(2, '0')
    let rec split v =
        match v with
        | last when v < 1000 -> last.ToString()
        | v -> sprintf "%s.%s" (split (v / 1000)) ((v % 1000).ToString().PadLeft(3, '0'))
    let vorkomma = split (vInt / 100)
    sprintf "%s%s,%s%s" vorzeichen vorkomma nachkomma einheit

let asMoney = asMoney2 " €"
let asMoneyEur = asMoney2 " EUR"
let asHtmlMoney = asMoney2 "&nbsp;€"

let private asMoney2float einheit (v: float) =
    let (vInt, vorzeichen) =
        if v < 0 then (int (-v * float 100), "-") else (int (v * float 100), "")
    let nachkomma = (vInt % 100).ToString().PadLeft(2, '0')
    let rec split v =
        match v with
        | last when v < 1000 -> last.ToString()
        | v -> sprintf "%s.%s" (split (v / 1000)) ((v % 1000).ToString().PadLeft(3, '0'))
    let vorkomma = split (vInt / 100)
    sprintf "%s%s,%s%s" vorzeichen vorkomma nachkomma einheit

let asMoneyFloat = asMoney2float " €"
